import React from "react";
function Archive(props) {
  return (
    <div style={{textAlign: 'center'}}>
        <div className="archive_img">
            <img alt="" src={require("assets/img/"+props.imgSrc)} />
        </div>
        <p className="archive_year">{props.date}</p>
        <div className="archive_desc" style={{textAlign: 'center'}}>
            {props.desc1}
        </div>
    </div>
  );
}

export default Archive;
