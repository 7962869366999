
import React from "react";

function RecruitingStep(props) {
    
  return (
      <div className={props.noBorder ? "rec_section" : "rec_section rec_border" }>
            <p className="rec_stepno">{props.stepNo}</p>
            <p className="rec_step_title">{props.stepTitle}</p>

          <div>
             <p className="rec_step_desc">{props.stepDesc}
             <br></br>
             {props.stepDesc2}
             <a className="rec_step_desc_atag" href={props.stepResume}> {props.stepResume}</a>
             </p>

          </div>

            {
                props.stepDetail && props.stepDetail.length > 0 ?
                props.stepDetail.map((text, index) => 
                        <p key={index} className="rec_step_desc bottom-0">{text}</p>
                )
                :
                null
            }


      </div>
  );
}

export default RecruitingStep;
