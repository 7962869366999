import React from "react";
import OtherNavbar from "components/Navbars/OtherNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import { useTranslation } from 'react-i18next'
import moment from 'moment';
import { Link } from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';
function AnnounceDetail(props) {
  const { t } = useTranslation()
  const [title, setTitle] = React.useState('');
  const [date, setDate] = React.useState('');
  const [content, setContent] = React.useState('');
  const [data, setData] =React.useState([]);
  const [curInfo, setCurInfo] = React.useState(null)
  const [isPrev, setIsPrev] = React.useState(true)
  const [isNext, setIsNext] = React.useState(true)
  React.useEffect(() => {
    let detail = null
    if(!curInfo) {
      detail = props.location.state[0];
      setCurInfo(detail)
    }
    
    if(props.location.state[1]) {
      props.location.state[1].map((advts, index) => {
        if(detail && advts.id == detail.id) {
          if(index == 0)
            setIsPrev(false)
          else
            setIsPrev(true)
          if(index == props.location.state[0].length  - 1)
            setIsNext(false)
          else
            setIsNext(true)
        }
      })
      setData(props.location.state[1])
    }
      
    if(detail) {
      setTitle(detail.title)
      setDate(moment(detail.date_create.slice(0, -3)).format("YYYY.MM.DD"))
      setContent(detail.contents)  
    }
    
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <OtherNavbar />
      <div className="wrapper">
        <div className="main">
          <div className="announce_img" style={{position: 'relative'}}>
            <img alt="" src={require("assets/img/announcement_bg.jpg")} />  
            <div className="about-healthy announce_title">
              <div className="about-healthy-part">
                <p className="about-healthy-title" style={{color: 'white', marginBottom: 0}}>{t('공시정보')}</p>
              </div>
            </div>
          </div>
          <div className="culture_section announce_detail_section">
            <p className="announce_detail_title">{title}</p>
            <p className="announce_detail_date">{date}</p>
            <div className="announce_detail_part announce_border">
                {ReactHtmlParser(content)}
            </div>
          </div>
          <div className="culture_section announce_detail_pagination">
              <div className="prev_next">
                  <div className={isPrev ? "prev" : "prev disable" } onClick={() => {
                    if(isPrev && data.length > 0 ) {
                      let cur_detail = null
                      if(curInfo)
                        cur_detail = curInfo;
                      else
                        cur_detail = props.location.state[0];
                      let prev_detail = null
                      
                      data.map((detail, index) => {
                        if(index > 0)
                          prev_detail = data[index - 1]
                        else
                          prev_detail = data[0]
                        if(detail.id == cur_detail.id && prev_detail) {
                          setCurInfo(prev_detail)
                          if(index == 1) {
                            setIsPrev(false)
                          }
                          else {
                            setIsPrev(true)
                          }
                          setIsNext(true)
                          setTitle(prev_detail.title)
                          setDate(moment(prev_detail.date_create.slice(0, -3)).format("YYYY.MM.DD"))
                          setContent(prev_detail.contents)
                        }
                      })
                    }
                  }}>
                    <i className="now-ui-icons arrows-1_minimal-left"></i>
                    <p className="bottom-0">{t('이전글')}</p>
                  </div>
                  <div className={isNext ? "next" : "next disable" } onClick={() => {
                    if(isNext && data.length > 0 ) {
                      let cur_detail = null
                      if(curInfo)
                        cur_detail = curInfo;
                      else
                        cur_detail = props.location.state[0];
                      let next_detail = null
                      data.map((detail, index) => {
                        if(index < data.length - 1)
                          next_detail = data[index + 1]
                        else
                          next_detail = data[data.length]
                        if(detail.id == cur_detail.id && next_detail) {
                          if(index == data.length - 2)
                            setIsNext(false)
                          else
                            setIsNext(true)
                          setIsPrev(true)
                          setCurInfo(next_detail)
                          setTitle(next_detail.title)
                          setDate(moment(next_detail.date_create.slice(0, -3)).format("YYYY.MM.DD"))
                          setContent(next_detail.contents)
                        }
                      })
                    }
                  }}>
                    <p className="bottom-0">{t('다음글')}</p>
                    <i className="now-ui-icons arrows-1_minimal-right"></i>
                  </div>
              </div>
              <Link className="annouce_list_color none_underline" to={"/annoucement"}>
                <div className="all">
                  <i className="now-ui-icons design_bullet-list-67"></i>
                  <span style={{paddingLeft: 5}}>{t('전체목록')}</span>
                </div>
              </Link>
          </div>
        </div>
        <DarkFooter />
      </div>
    </>
  );
}

export default AnnounceDetail;