import React, { useEffect } from "react";
import OtherNavbar from "components/Navbars/OtherNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import Path from "components/Path.js";
import { useTranslation } from 'react-i18next'
import { Table, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { announce_get } from "../api";
import { useHistory  } from "react-router-dom";
import moment from "moment";
import { Link } from "react-router-dom";
function renderTr(data,history, curPage) {
  
  return data.map((detail, index) => {
    if(index >= (curPage - 1) * 15 && index < curPage * 15)
    return <tr key={index} to="/annouce_detail"  onClick={() => history.push("/annouce_detail", [detail, data])}>
      <td>{moment(detail.date_create.slice(0, -3)).format("YYYY.MM.DD")}</td>
      <td>{detail.title}</td>
    </tr>  
  })
}
function Annoucement() {
  const history = useHistory();
  const { t } = useTranslation()
  const [announceList, setAnnounceList] = React.useState([]);
  const [curPage, setCurPage] = React.useState(1);
  const [totalPage, setTotalPage] = React.useState(1);
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  useEffect(() => {
    fetchData();
  },[])
  const fetchData = async () => {
    const response = await announce_get();
    if(response.result === "200" && response.data.length > 0) {
      setTotalPage(Math.ceil(response.data.length /  15))
      setAnnounceList(response.data)
    }
  }
  return (
    <>
      <OtherNavbar />
      <div className="wrapper">
        <div className="main">
          <Path firstPathTitle={t('홈')} firstPath="/" secondPathTitle={t('투자정보')} secondPath="" thirdPathTitle={t('공시정보')}/>
          <div style={{position: 'relative'}}>
            <img alt="" src={require("assets/img/announcement_bg.jpg")} />  
            <div className="about-healthy announce_section">
              <div className="about-healthy-part">
                <p className="about-healthy-title announce_title">{t('공시정보')}</p>
              </div>
            </div>
          </div>
            <div className="culture_section annouce_table_section">
                <Table className="annoucement_table">
                    <thead>
                        <tr>
                            <th>{t('등록일자')}</th>
                            <th>{t('제목')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                          announceList.length > 0 ?
                          renderTr(announceList, history, curPage)
                          :
                          null
                        }
                        
                    </tbody>
                </Table>
                <div style={{textAlign: 'center'}}>
                  <Pagination>
                      <PaginationItem>
                          <PaginationLink
                              aria-label="First"
                              href="#pablo"
                              onClick={(e) =>  { e.preventDefault(); setCurPage(1) }}
                          >
                              <span aria-hidden={true}>
                              <i
                                  aria-hidden={true}
                                  className="fa fa-angle-double-left"
                              ></i>
                              </span>
                          </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                          <PaginationLink
                              aria-label="Previous"
                              href="#pablo"
                              onClick={(e) => { e.preventDefault(); setCurPage(curPage > 1 ? curPage - 1 : 1) }}
                          >
                              <span aria-hidden={true}>
                              <i
                                  aria-hidden={true}
                                  className="fa fa-angle-left"
                              ></i>
                              </span>
                          </PaginationLink>
                      </PaginationItem>
                      <span className="curPage">{curPage}</span>
                      <span>/</span>
                      <span className="lastPage">{totalPage}</span>
                      <PaginationItem>
                          <PaginationLink
                              aria-label="Next"
                              href="#pablo"
                              onClick={(e) => { e.preventDefault(); setCurPage(curPage < totalPage ? curPage + 1 : totalPage) }}
                          >
                              <span aria-hidden={true}>
                              <i
                                  aria-hidden={true}
                                  className="fa fa-angle-right"
                              ></i>
                              </span>
                          </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                          <PaginationLink
                              aria-label="Last"
                              href="#pablo"
                              onClick={(e) => { e.preventDefault(); setCurPage(totalPage) }}
                          >
                              <span aria-hidden={true}>
                              <i
                                  aria-hidden={true}
                                  className="fa fa-angle-double-right"
                              ></i>
                              </span>
                          </PaginationLink>
                      </PaginationItem>
                  </Pagination>
                </div>
            </div>
            
          
        </div>
        <DarkFooter />
      </div>
    </>
  );
}

export default Annoucement;