import React from "react";
import OtherNavbar from "components/Navbars/OtherNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import Path from "components/Path.js";
import { useTranslation } from 'react-i18next'

function Privacy() {
  const { t } = useTranslation()
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <OtherNavbar />
      <div className="wrapper">
        <div className="main">
          <Path firstPathTitle={t('홈')} firstPath="/" secondPathTitle={t('개인정보처리방침')}/>
          <div className="culture_section privacy_secion">
            <p className="privacy_title">{t('개인정보처리방침')}</p>
            <div className="privacy_detail announce_detail_part">
                <p style={{fontWeight: 'bold', fontSize: 16}}>{t('주식회사 유라이크코리아 웹사이트 개인정보처리방침')}</p>
                <p>{t('VER 1.0.0')}</p>
                <p>주식회사 유라이크코리아(이하"회사"라 함)는 이용자의 ‘동의를 기반으로 개인정보를 수집.이용 및 제공'하고 있으며, ‘이용자의 권리(개인정보 자기결정권)를 적극적으로 보장'합니다. 회사는 개인정보보호법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 통신비밀보호법, 전기통신사업법 등 정보통신서비스제공자가 준수하여야 할 관련 법규상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보처리방침을 정하여 이용자 권익 보호에 최선을 다하고 있습니다. </p>
                <p>{t('본 개인정보처리방침은 회사의 인재채용, 서비스문의, 제휴문의 등록 이용을 하고자 하는 이용자(이하"이용자”)에게 적용됩니다.')}</p>
                <p>{t('회사는 개인정보처리방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다.')}</p>
                <p>{t('본 방침은부터 2020년 12월 10일부터 시행됩니다.')}</p><br/><br/>
                <p>{t('회사의 개인정보처리방침은 다음과 같은 내용을 담고 있습니다.')}</p>
                <p>{t('1. 처리하는 개인정보 항목')}</p>
                <p>{t('2. 개인정보의 처리 목적')}</p>
                <p>{t('3. 개인정보의 처리 및 보유기간')}</p>
                <p>{t('4. 개인정보 파기 절차와 방법')}</p>
                <p>{t('5. 이용자의 권리/의무 및 그 행사방법')}</p>
                <p>{t('6. 개인정보의 안전성 확보조치')}</p>
                <p>{t('7. 개인정보보호 책임자 및 연락처')}</p>
                <p>{t('8. 개인정보처리방침의 변경')}</p><br/>
                <p style={{fontWeight: 'bold', fontSize: 16}}>{t('1. 처리하는 개인정보 항목')}</p>
                <p>가. 회사는 입사지원자 관리를 위하여 아래와 같은 개인정보를 수집하고 있습니다.<br/>
- 필수정보 : 이름, 생년월일, 주소, 이메일 주소, 연락처, 학력사항(입학일, 졸업일, 졸업유무, 교육기관 이름, 전공, 학점), 경력사항(입사일, 퇴사일, 회사명, 직급, 담당업무, 지원유형), 자기소개<br/>
- 선택정보 : 외국어 능력(언어, 시험명, 점수, 급, 주관, 취득일), 자격증 및 특기사항(발급일, 자격증명칭, 발급기관, 급수)</p>

<p>나. 회사는 서비스 문의 접수를 위하여 아래와 같은 개인정보를 수집하고 있습니다.<br/>
- 필수정보 : 성명, 이메일 주소, 전화번호, 거주국가, 상세주소</p>

<p>다. 회사는 제휴 문의 접수를 위하여 아래와 같은 개인정보를 수집하고 있습니다.<br/>
- 필수정보 : 성명, 이메일 주소, 전화번호, 거주국가, 상세주소<br/>
- 선택정보 : 법인명
</p>
                <p style={{fontWeight: 'bold', fontSize: 16}}>{t('2. 개인정보의 처리 목적')}</p>
                <p>가. 지원자 개인 식별 및 지원의사 확인, 입사전형 진행, 고지사항 전달, 입사지원자와의 원활한 의사소통<br/>
나. 회사가 제공하는 서비스에 관한 서비스 문의 접수 및 처리결과 회신<br/>
다. 제휴 문의 접수 및 처리결과 회신
</p>
                <p style={{fontWeight: 'bold', fontSize: 16}}>{t('3. 개인정보의 처리 및 보유 기간')}</p>
                <p>회사는 법령에 따른 개인정보 보유·이용기간 또는 이용자로부터 개인정보를 수집시에 동의 받은 개인정보 보유,이용기간 내에서 개인정보를 처리 및 보유하며, 원칙적으로 개인정보의 처리목적이 달성되면 지체 없이 파기합니다.</p>
<p>가. 서비스 문의 이용자의 정보 중, 관련 법령에 의해 보유가 허용된 정보는 법령이 정한 기간동안 보관합니다. (단, 별도 동의를 얻은 경우 동의받은 개인정보 목적/이용기간 내에서 개인정보를 처리 및 보유할 수 있습니다.)</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;1) 서비스 문의 처리 및 처리결과 통보 목적<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a) 보존근거 : 전자상거래법<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b) 보존 기간 : 3년</p>
<p>나. 제휴 문의 사항에 대한 검토 후 1년간 보관하며, 이후 해당 정보는 지체없이 파기됩니다.</p>
<p>다. 입사지원자의 지원이력 정보는 지원이력 확인 및 면접 불합격자 재지원 제한을 위해 탈퇴일로부터 1년간 보관 후 파기됩니다. (단, 이때 원래의 값을 확인하지 못하도록 안전하게 변환하며, 비교값 형태로만 활용합니다.)</p>
<p>라. 최종합격자의 정보 중, 관련 법령에 의해 보유가 허용된 정보는 법령이 정한 기간동안 보관합니다. (단, 별도 동의를 얻은 경우 동의받은 개인정보 목적/이용기간 내에서 개인정보를 처리 및 보유할 수 있습니다.)</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;1) 퇴직자의 경력증명 등 사용증명서 발급 목적<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a) 보존 근거 : 근로기준법<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b) 보존 기간 : 3년<br/>
&nbsp;&nbsp;&nbsp;&nbsp;2) 연말정산 처리 목적<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a) 보존 근거 : 소득세법, 국세기본법<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b) 보존 기간 : 5년<br/>
</p>
                <p style={{fontWeight: 'bold', fontSize: 16}}>{t('4. 개인정보 파기 절차와 방법')}</p>
                <p>회사는 이용자의 개인정보에 대해 “ 개인정보의 처리목적이 달성”된 후에는 해당 정보를 지체 없이 파기합니다. 회사의 개인정보 파기절차 및 방법은 다음과 같습니다.</p>
<p>가. 파기절차</p>
<p>- 이용자가 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져(종이의 경우 별도의 서류) 내부 방침 및 기타 관련 법령에 따른 보관사유에 따라(“3. 개인정보의 처리 및 보유기간" 참조) 일정기간 저장된 후 혹은 즉시 파기됩니다. 이 때, DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.</p>
<p>나. 파기방법</p>
<p>- 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.</p>
<p>- 전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다
</p>
                <p style={{fontWeight: 'bold', fontSize: 16}}>{t('5. 이용자의 권리/의무 및 그 행사방법')}</p>
                <p>이용자는 언제든지 등록되어 있는 자신의 개인정보를 조회하거나 수정할 수 잇으며 정보삭제 및 처리정지를 요청할 수도 있습니다. 정보삭제 또는 처리정지를 원하시는 경우 개인정보보호 책임자에게 서면, 전화 또는 이메일로 연락하시면 지체없이 조치하겠습니다.</p>
                <p style={{fontWeight: 'bold', fontSize: 16}}>{t('6. 개인정보의 안전성 확보조치')}</p>
                <p>회사는 개인정보를 처리함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 보호조치를 강구하고 있습니다.</p>
<p>가. 정기적인 자체 감사 실시<br/>
개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로 자체 감사를 실시하고 있습니다.</p>

<p>나. 개인정보 취급 직원의 최소화 및 교육<br/>
개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고 있습니다.</p>

<p>다. 내부관리계획의 수립 및 시행<br/>
개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.</p>

<p>라. 개인정보에 대한 접근 제한<br/>
개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.
</p>
                <p style={{fontWeight: 'bold', fontSize: 16}}>{t('7. 개인정보보호 책임자 및 연락처')}</p>
                <p>회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 이용자의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보보호 책임자를 지정하고 있습니다.</p>
<p>
<ul>
  <li>개인정보 보호책임자</li>
<li>성명 : 이현노</li>
<li>소속 : 기업부설연구소</li>
<li>직급 : 상무</li>
<li>메일 : henry@ulikekorea.com,</li></ul></p>
<p>기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.</p>
 
<p>개인정보침해신고센터 ( http://privacy.kisa.or.kr  / 국번없이 118 )<br/>
대검찰청 사이버수사과 ( http://www.spo.go.kr / 국번없이 130 )<br/>
경찰청 사이버안전국 ( http://cyberbureau.police.go.kr / 국번없이 182 )
</p>
                <p style={{fontWeight: 'bold', fontSize: 16}}>{t('8. 개인정보처리방침 변경')}</p>
                <p>이 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 7일 전부터 “공지사항”을 통하여 고지할 것입니다.

- 공고 일자 : 2020년 12월 3일
- 시행 일자 : 2020년 12월 10일
</p>
            </div>
          </div>
        </div>
        <DarkFooter />
      </div>
    </>
  );
}

export default Privacy;