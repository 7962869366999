import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Axios from "axios";
import { useHistory } from "react-router-dom";

const DeleteModal = (props) => {
  const history = useHistory();
  const { buttonLabel, className } = props;

  const [modal, setModal] = useState(false);

  const deleteFunction = () => {
    Axios({
      method: "delete",
      url:
        "https://livecare.io:441/" + props.deletePath + "id=" + props.deleteId,
    }).then((response) => {
      alert("삭제되었습니다.");
      return history.push("/manager/home");
    });
  };

  const toggle = () => setModal(!modal);

  return (
    <div>
      <span onClick={toggle}>삭제</span>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalBody>삭제하시겠습니가?</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={deleteFunction}>
            삭제
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            취소
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DeleteModal;
