import React, {Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.4.0";
import "assets/css/style.css?v=0.93";

import Index from "views/Index.js";
import About from "views/About.js";
import Service from "views/Service.js";
import Culture from "views/Culture.js";
import Recruiting from 'views/Recruiting.js';
import Annoucement from "views/Annoucement.js";
import AnnounceDetail from "views/AnnounceDetail.js";
import Privacy from "views/Privacy.js";
import Help from "views/Help.js";
import HelpService from "views/HelpService.js";
import HelpPartner from "views/HelpPartner.js";
import Directions from "views/Directions.js";
import History from "views/History.js";
import Patent from "views/Patent.js";
import Advts from "views/Advts.js";
import AdvtsDetail from "views/AdvtsDetail";
import LiveMarket from "views/LiveMarket";
import './i18n';

import Login from './manager/Login'
import ManagerIndex from "./manager/ManagerIndex"
import Home from './manager/Home'
import AdminService from './manager/Service/AdminService'
import AdminContact from "./manager/Contact/AdminContact";
import ServiceDetail from "./manager/Service/Sections/ServiceDetail";
import ContactDetail from "./manager/Contact/Sections/ContactDetail";
import AdminPress from "./manager/Press/AdminPress";
import NewPress from "./manager/Press/Sections/NewPress";
import PressDetail from "./manager/Press/Sections/PressDetail";
import PressDetailEdit from "./manager/Press/Sections/PressDetailEdit";
import AdminCertificate from "./manager/Certificate/AdminCertificate";
import NewCertificate from "./manager/Certificate/Sections/NewCertificate";
import CertificateDetailEdit from './manager/Certificate/Sections/CertificateDetailEdit'
import StockDetail from './manager/Stock/Sections/StockDetail'


import { createStore, applyMiddleware, compose } from "redux";
import RobotPage from "views/RobotPage";
import HelpStock from "views/HelpStock";
import AdminStock from "manager/Stock/AdminStock";


ReactDOM.render(
  <BrowserRouter basename={process.env.NODE_ENV === 'development' ? '/' : (process.env.PUBLIC_URL || '/')}>
  <Suspense fallback={null}>
    <Switch>
      <Switch>
        <Route path="/index" render={(props) => <Index {...props} />} />
        <Route
          path="/about"
          render={(props) => <About {...props} />}
        />
        <Route
          path="/service"
          render={(props) => <Service {...props} />}
        />
        <Route
          path="/culture"
          render={(props) => <Culture {...props} />}
        />
        <Route
          path="/recruiting"
          render={(props) => <Recruiting {...props} />}
        />
        <Route
          path="/annoucement"
          render={(props) => <Annoucement {...props} />}
        />
        <Route
          path="/annouce_detail"
          render={(props) => <AnnounceDetail {...props} />}
        />
        <Route
          path="/privacy"
          render={(props) => <Privacy {...props} />}
        />
        <Route
          path="/help"
          render={(props) => <Help {...props} />}
        />
        <Route
          path="/helpservice"
          render={(props) => <HelpService {...props} />}
        />
        <Route
          path="/helppartner"
          render={(props) => <HelpPartner {...props} />}
        />
        
        <Route
          path="/helpstock"
          render={(props) => <HelpStock {...props} />}
        />
        <Route
          path="/directions"
          render={(props) => <Directions {...props} />}
        />
        <Route
          path="/history"
          render={(props) => <History {...props} />}
        />
        <Route
          path="/patent"
          render={(props) => <Patent {...props} />}
        />
        <Route
          path="/advts"
          component={Advts}
        />
        <Route
          path="/robot"
          render={(props) => <RobotPage {...props} />}
        />
       
        <Route
          path="/advtsdetail/:id"
          component={AdvtsDetail}
        />
         <Route
          path="/liveMarket"
          render={(props) => <LiveMarket {...props} />}
        />



        <Route path="/manage" render={(props) => <Login {...props} />} />
        <Route path="/manager/home" render={(props) => <Home {...props} />} />


        <Route path="/manager/adminService" render={(props) => <AdminService {...props} />} />
        <Route path="/manager/adminContact" render={(props) => <AdminContact {...props} />} />
        <Route path="/manager/servicedetail" render={(props) => <ServiceDetail {...props} />} />
        <Route path="/manager/contactdetail" render={(props) => <ContactDetail {...props} />} />
        <Route path="/manager/adminpress" render={(props) => <AdminPress {...props} />} />
        <Route path="/manager/newpress" render={(props) => <NewPress {...props} />} />
        <Route path="/manager/pressdetail" render={(props) => <PressDetail {...props} />} />
        <Route path="/manager/pressdetailedit" render={(props) => <PressDetailEdit {...props} />} />
        <Route path="/manager/adminCertificate" render={(props) => <AdminCertificate {...props} />} />
        <Route path="/manager/newcertificate" render={(props) => <NewCertificate{...props} />} />
        <Route path="/manager/certificatedetailedit" render={(props) => <CertificateDetailEdit{...props} />} />
        <Route path="/manager/adminStock" render={(props) => <AdminStock {...props} />} />
        <Route path="/manager/StockDetail" render={(props) => <StockDetail {...props} />} />

        <Redirect to="/index" />
        <Redirect from="/" to="/index" />
      </Switch>
    </Switch>
    </Suspense>
  </BrowserRouter>,
  
  document.getElementById("root")
);
