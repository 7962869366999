import React from "react";

function CultureType(props) {
  return (
      <div className="culture_type_section">
          
            <span className="culture_card_title culture_type_title">{props.title}</span>
            <span className="culture_card_desc culture_type_desc">{props.description}</span>
          
      </div>
  );
}

export default CultureType;
