import React, { useEffect } from "react";
import {certificate_get, service_get} from "../../../api";
import {Pagination, PaginationItem, PaginationLink, Table} from "reactstrap";
import {useHistory} from "react-router-dom";
import DeleteModal from "../../DeleteModal";

const  ServiceTableContent = (props)=> {
    const history = useHistory();

    return props.data.map((detail, index) => {
        if(detail.type === 'E'){
            var type = "기존 사용자"
        }else if(detail.type === 'O'){
            var type = "잠재 사용자"
        }else{
            var type = "기타"
        }

        // 서비스명
        if(detail.service_name === "L"){
           var service_name = "라이브케어"
        }
        else if(detail.service_name === "P"){
            var service_name = "캡슐 구매"
        }
        else {
            var service_name = "기타"
        }

        let country = detail.country //국가
        let user_name = detail.user_name // 이름
        let email = detail.email // 이메일 주소
        let date_create = detail.date_create //문의일
        let id = detail.id

        return(
            <tr >
                <td className='cursorPointer' key={index} onClick={() => history.push(`/manager/servicedetail/${id}`,props.data)}>{type}</td>
                <td className='cursorPointer' key={index} onClick={() => history.push(`/manager/servicedetail/${id}`,props.data)}>{service_name}</td>
                <td className='cursorPointer' key={index} onClick={() => history.push(`/manager/servicedetail/${id}`,props.data)}>{country}</td>
                <td className='cursorPointer' className='cursorPointer' key={index} onClick={() => history.push(`/manager/servicedetail/${id}`,props.data)}>{user_name}</td>
                <td>{email}</td>
                <td>{date_create}</td>
                <td className='cursorPointer'><DeleteModal deletePath={'stock?'} deleteId={detail.id}/></td>
            </tr>

        )

    })

}

const ServiceTable = (props) => {
    const [curPage, setCurPage] = React.useState(1)
    const [list, setList] = React.useState(props.data)

    useEffect(() => {
        setList(props.data)
    },[props]);
    const callData = async (page) => {
        if(curPage !== page) {
            setCurPage(page)
            const response = await service_get( page, 15);
            if(response.result === "200" && response.data.length > 0) {
                setList(response.data)
            }
        }
    }
    return(
        <div style={{backgroundColor:'#EEF1F3', width:'100%',height:'105vh'}}>
        <div style={{width:'80%', margin:'0 auto',position:'relative',top:'150px'}}>
            <span className="servieSubTitle">서비스문의 목록</span>
            <Table className="manager_table">
                <thead>
                <tr>
                    <th>문의종류</th>
                    <th>서비스명</th>
                    <th>국가</th>
                    <th>이름</th>
                    <th>이메일 주소</th>
                    <th>문의일</th>
                    <td>삭제</td>

                </tr>
                </thead>
                <tbody>
                {
                    list && list.length > 0 ?
                        <ServiceTableContent data={list} cur_page={curPage}/>
                        :
                        null
                }
                </tbody>
            </Table>

            <div style={{textAlign: 'center'}}>
                <Pagination>
                    <PaginationItem>
                        <PaginationLink
                            aria-label="First"
                            href="#pablo"
                            onClick={(e) =>  { e.preventDefault(); callData(1) }}
                        >
                        <span aria-hidden={true}>
                        <i
                            aria-hidden={true}
                            className="fa fa-angle-double-left"
                        ></i>
                        </span>
                        </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                        <PaginationLink
                            aria-label="Previous"
                            href="#pablo"
                            onClick={(e) => { e.preventDefault(); callData(curPage > 1 ? curPage - 1 : 1) }}
                        >
                        <span aria-hidden={true}>
                        <i
                            aria-hidden={true}
                            className="fa fa-angle-left"
                        ></i>
                        </span>
                        </PaginationLink>
                    </PaginationItem>


                    <span className="curPage">{curPage}</span>
                    <span>/</span>
                    <span className="lastPage">{props.total}</span>

                    <PaginationItem>
                        <PaginationLink
                            aria-label="Next"
                            href="#pablo"
                            onClick={(e) => { e.preventDefault(); callData(curPage < props.total ? curPage + 1 : props.total) }}
                        >
                        <span aria-hidden={true}>
                        <i
                            aria-hidden={true}
                            className="fa fa-angle-right"
                        ></i>
                        </span>
                        </PaginationLink>
                    </PaginationItem>

                    <PaginationItem>
                        <PaginationLink
                            aria-label="Last"
                            href="#pablo"
                            onClick={(e) => { e.preventDefault(); callData(props.total) }}
                        >
                        <span aria-hidden={true}>
                        <i
                            aria-hidden={true}
                            className="fa fa-angle-double-right"
                        ></i>
                        </span>
                        </PaginationLink>
                    </PaginationItem>

                </Pagination>

            </div>
        </div>
        </div>

    )
}

export default ServiceTable