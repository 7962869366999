import React from "react";
import OtherNavbar from "components/Navbars/OtherNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import Path from "components/Path.js";
import { useTranslation } from 'react-i18next'

function About() {
  const { t } = useTranslation()
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <OtherNavbar />
      <div className="wrapper">
        <div className="main">
          <Path firstPathTitle={t('홈')} firstPath="/" secondPathTitle={t('유라이크코리아')} secondPath="/about" thirdPathTitle={t('유라이크코리아 소개')} />
          <div className="hide_path" style={{position: 'relative'}}>
            <img alt="" src={require("assets/img/about-bg.jpg")} />  
            <div className="about-transparent">
            </div>
          </div>
          
          <div className="about-detail">
            <div className="about-detail-section">
              <p className="about-detail-title">{t('가축과 사람을 연결하는 생체데이터 분석 전문기업, 유라이크코리아입니다')}</p>
              <p className="about-detail-desc about-detail-desc_desktop">{t('about_description')}</p>
              <p className="about-detail-desc_mobile">{t('about_description1')}</p>
              <p className="about-detail-desc_mobile">{t('about_description2')}</p>
            </div>
          </div>
          <div className="about-description">
            <div className="about-expert">
              <div className="about-expert-text">
                <p className="about-expert-text-title">{t('전문성과 책임')}</p>
                <p className="about-expert-text-subtitle">{t('전세계 가축의 건강을 고민하다')}</p>
                <p className="about-expert-text-desc">{t('1만년 전부터 인간의 오랜 친구 중 하나인 가축을 건강하게 관리하기 위한 고민에서부터 출발했습니다.')}</p>

                <p className="about-expert-text-desc">{t('가축이 아프지 않고 잘 자랄때 우리의 내일도 건강하다는 믿음으로, 동물 복지를 실현하고 가축케어의 최고의 경험을 선사합니다')}</p>
                <p className="about-expert-text-desc bottom-0">{t('유라이크코리아는 동물자원학, 수의학, 데이터 분석 등 다양한 분야의 전문 인력을 구성하여 목장에 필요한 맞춤 서비스를 제공하고 있습니다.')}</p>
              </div>
              <div className="about-expert-img">
                <img alt="" src={require("assets/img/about-expert.png")} />
              </div>
            </div>
            <div className="about-expert about-tech">
              <div className="about-expert-text">
                <p className="about-expert-text-title about-tech-title">{t('기술 혁신')}</p>
                <p className="about-expert-text-subtitle about-tech-subtitle">{t('생체 빅데이터와 AI가 만나다')}</p>
                <p className="about-expert-text-desc about-tech-subtitle">{t('전세계 농장에서 수집한 가축 생체 빅데이터의 가치는 무궁무진합니다.')}</p>

                <p className="about-expert-text-desc about-tech-subtitle">{t('유라이크코리아가 보유한 생체 데이터와 인공지능(AI) 기술력을 통해 생산자와 소비자 모두에게 필요한 데이터로 세상에 기여하고자 합니다.')}</p>

                <p className="about-expert-text-desc about-tech-subtitle bottom-0">{t('축우를 비롯하여 다양한 생체 데이터는 글로벌 바이오 시장, 축산 시장 및 식품 이력 추적, 백신 개발 등 다양한 산업군에서 활용 가치가 높습니다.')}</p>

              </div>
              <div className="about-tech-img about-expert-img">
                <img alt="" className="about-tech-img-expert" src={require("assets/img/about-expert.png")} />
                <img alt="" className="about-tech-img-figures" src={require("assets/img/figures.png")} />
              </div>
            </div>
            <div className="about-expert about-grow">
              <div className="about-expert-text">
                <p className="about-expert-text-title">{t('지속 성장')}</p>
                <p className="about-expert-text-subtitle">{t('헬스케어에 확장성을 더하다')}</p>
                <p className="about-expert-text-desc">{t('국내와 일본에서 인정받은 가축 헬스케어 기술로 세계를 향해 나갑니다.')}</p>

                <p className="about-expert-text-desc">{t('지속적인 연구개발(R&D)을 통해 가축에서부터 인간까지, 헬스케어의 무한한 확장성을 통해 지금은 세상에 없지만 꼭 필요한 미래의 가치를 주도합니다.')}</p>

                <p className="about-expert-text-desc bottom-0">{t('축종에 따른 특성, 국가에 따른 사양 환경 등을 고려한 최적화된 서비스로 글로벌 시장에서 경쟁 우위를 확보하겠습니다.')}</p>
                

              </div>
              <div className="about-expert-img about-grow-image">
                <img alt="" src={require("assets/img/grow-continue.png")} />
              </div>
            </div>
          </div>
          <div className="about-healthy-section">
            <img alt="" src={require("assets/img/bg-healthy.jpg")} />
            <div className="about-healthy">
              <div className="about-healthy-part culture_section about_last_section">
                <p className="about-healthy-title">{t('가축이 건강할 때 사람도 건강합니다')}</p>
                <p className="about-healthy-desc">{t('잊혀질만하면 다시 터지는 구제역 확산, 먹거리 파동에 우리 모두 지쳐갑니다.')}</p>
                <p className="about-healthy-desc">{t('유라이크코리아는 동물이 행복하게 자랄 수 있는 환경(동물복지), 안심하고 먹을 수 있는 먹거리(클린푸드)를 위해 앞장서고 있습니다.')}</p>
                <p className="about-healthy-desc">{t("한결같이 추구해온 '가축이 건강해야 사람도 건강할 수 있다'는 믿음으로 가축의 건강을 먼저 생각하겠습니다.")}</p>
                <p className="about-healthy-desc about-healthy-desc_mobile">{t('about_healthy_desc_mobile')}</p>
              </div>
            </div>
          </div>
        </div>
        <DarkFooter />
      </div>
    </>
  );
}

export default About;