import React, { useState, useEffect } from "react";
import { Form, Button } from "reactstrap";
import QuillEditor from "../../editor/QuillEditor";
import Axios from "axios";
import "react-quill/dist/quill.snow.css";
import { useHistory } from "react-router-dom";
import ManagerNavbar from "../../../components/Navbars/ManagerNavbar";
import Aside from "../../../components/Navbars/Aside";
import { useForm } from "react-hook-form";

const NewPress = (props) => {
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm();

  const [content, setContent] = useState("");
  const [files, setFiles] = useState([]);
  const [img, setImg] = useState("");

  useEffect(() => {
    let islogin = JSON.parse(localStorage.getItem("name"));
    if (islogin !== null) {
    } else {
      history.push("/manage");
    }
  }, [history]);

  const onEditorChange = (value) => {
    setContent(value);
  };

  const onFilesChange = (files) => {
    setFiles(files);
  };

  const imgUpload = async (e) => {
    const file = e.currentTarget.files[0];

    const formData = new FormData();

    const config = {
      header: { "content-type": "multipart/form-data" },
    };
    formData.append("file", file);

    await Axios.post("https://livecare.io:441/uploader", formData, config)
      .then((res) => {
        const thumbnailUrl = "https://ulikekorea.com:442" + res.data;
        setImg(thumbnailUrl);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSubmit = (data) => {
    setContent("");

    Axios({
      method: "post",
      url: "https://livecare.io:441/press?" + data,
      data: {
        language: data.language,
        title: data.title,
        image: img,
        contents: content,
      },
    }).then((response) => {
      return history.push("/manager/adminpress");
    });
  };

  return (
    <>
      <ManagerNavbar />
      <div style={{ display: "flex" }}>
        <Aside></Aside>

        <div
          style={{ backgroundColor: "#EEF1F3", width: "100%", height: "130vh" }}
        >
          <div
            style={{
              width: "80%",
              margin: "0 auto",
              position: "relative",
              top: "150px",
            }}
          >
            <p style={{ fontSize: "28px" }}>언론보도 등록</p>

            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div className="newPresstextBox">
                <label className="newPressLabel" ref={register}>
                  언어
                </label>
                <select
                  defaultValue={props.location.state}
                  className="form-control newPressSelectBox"
                  name="language"
                  ref={register}
                >
                  <option value="ko">{"한국"}</option>
                  <option value="en">{"영어"}</option>
                  <option value="pt">{"포르투갈"}</option>
                  <option value="ja">{"일본"}</option>
                </select>
              </div>

              <div className="newPresstextBox">
                <label className="newPressLabel" ref={register}>
                  제목
                </label>
                <input
                  className="newPressinputBox form-control"
                  type="text"
                  placeholder={"제목을 입력하세요"}
                  name="title"
                  ref={register({ required: true })}
                  style={{ width: "400px" }}
                />
                {errors.title && (
                  <p className="pressformError">값을 입력해주세요</p>
                )}
              </div>

              <div className="newPresstextBox">
                <label className="newPressLabel" ref={register}>
                  대표 사진
                </label>
                <input
                  className=""
                  type="file"
                  onChange={imgUpload}
                  placeholder={"사진 url을 입력하세요"}
                  name="image"
                  ref={register({ required: true })}
                />
                {errors.image && (
                  <p className="pressformError">값을 입력해주세요</p>
                )}
              </div>

              <QuillEditor
                placeholder={"Start Posting Something"}
                onEditorChange={onEditorChange}
                onFilesChange={onFilesChange}
              />

              <div className="submitBtn">
                <button className="form-control">제출</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewPress;
