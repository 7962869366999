import React, { useEffect, useState } from "react";
import ManagerNavbar from "../../../components/Navbars/ManagerNavbar";
import Aside from "../../../components/Navbars/Aside";
import { useForm, Controller } from "react-hook-form";
import Axios from "axios";
import moment from "moment";
import { useHistory } from "react-router-dom";

const CertificateDetailEdit = (props) => {
  const history = useHistory();
  const [cerDetailEditData, setCerDetailEditData] = React.useState([]);
  let cerDetailEditpathname = props.location.pathname;
  let substrPathName = cerDetailEditpathname.substr(31);

  useEffect(() => {
    let islogin = JSON.parse(localStorage.getItem("name"));
    if (islogin !== null) {
    } else {
      history.push("/manage");
    }
    fetchData();
  }, [fetchData, history]);

  const fetchData = () => {
    props.location.state.map((detail, index) => {
      if (detail.id === substrPathName) {
        setCerDetailEditData(detail);
      }
    });
  };

  const { register, handleSubmit, errors, control } = useForm();

  const [edittype, setEdittype] = useState("");
  const [editnamekr, setEeditnamekr] = useState(cerDetailEditData.name_kr);
  const [editnameen, setEeditnameen] = useState("");
  const [editnamept, setEeditnamept] = useState("");
  const [editnamejp, setEeditnamejp] = useState("");
  const [editcatekr, setEeditcatekr] = useState("");
  const [editcateen, setEeditcateen] = useState("");
  const [editcatept, setEeditcatept] = useState("");
  const [editcatejp, setEeditcatejp] = useState("");
  const [editdatecerkr, setEeditdatecerkr] = useState("");
  const [editdateceren, setEeditdateceren] = useState("");
  const [editdatecerpt, setEeditdatecerpt] = useState("");
  const [editdatecerjp, setEeditdatecerjp] = useState("");
  const [editcreate, setEeditcreate] = useState("");
  const [editlink, setEeditlink] = useState("");

  const typechange = (e) => {
    setEdittype(e.target.value);
  };
  const namekr = (e) => {
    setEeditnamekr(e.target.value);
  };

  const nameen = (e) => {
    setEeditnameen(e.target.value);
  };
  const namept = (e) => {
    setEeditnamept(e.target.value);
  };
  const namejp = (e) => {
    setEeditnamejp(e.target.value);
  };
  const catekr = (e) => {
    setEeditcatekr(e.target.value);
  };
  const cateen = (e) => {
    setEeditcateen(e.target.value);
  };
  const catept = (e) => {
    setEeditcatept(e.target.value);
  };
  const catejp = (e) => {
    setEeditcatejp(e.target.value);
  };
  const cerkr = (e) => {
    setEeditdatecerkr(e.target.value);
  };
  const ceren = (e) => {
    setEeditdateceren(e.target.value);
  };
  const cerpt = (e) => {
    setEeditdatecerpt(e.target.value);
  };
  const cerjp = (e) => {
    setEeditdatecerjp(e.target.value);
  };
 
  const create = (e) => {
    setEeditcreate(e.target.value);
  };
  const link = (e) => {
    setEeditlink(e.target.value);
  };
  let editdate = moment(cerDetailEditData.date_create).format("YYYY-MM-DD");

  const onSubmit = (data) => {
    Axios({
      method: "put",
      url: `https://livecare.io:441/certificate?id=${substrPathName}&` + data,
      data: {
        type: data.type,
        name_kr: data.name_kr,
        name_en: data.name_en,
        name_pt: data.name_pt,
        name_jp: data.name_jp,
        category_kr: data.category_kr,
        category_en: data.category_en,
        category_pt: data.category_pt,
        category_jp: data.category_jp,
        date_certificate_kr: data.date_certificate_kr,
        date_certificate_en: data.date_certificate_en,
        date_certificate_pt: data.date_certificate_pt,
        date_certificate_jp: data.date_certificate_jp,
        date_create: data.date_create,
        link: data.link,
      },
    })
      .then((response) => {
        return history.push("/manager/adminCertificate");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <ManagerNavbar />
      <div style={{ display: "flex" }}>
        <Aside></Aside>

        <div
          style={{ backgroundColor: "#EEF1F3", width: "100%", height: "120vh" }}
        >
          <div
            style={{
              width: "80%",
              margin: "0 auto",
              position: "relative",
              top: "150px",
            }}
          >
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div
                className="newcertiTwoBox "
                style={{ flexDirection: "column", marginBottom: "10px" }}
              >
                <label className="newPressLabel" ref={register}>
                  타입
                </label>
                <select
                  className="form-control"
                  value={edittype || cerDetailEditData.type}
                  name="type"
                  ref={register({ required: true })}
                  onChange={typechange}
                  style={{ width: "99%", borderRadius: "5px" }}
                >
                  <option value="P">{"특허"}</option>
                  <option value="C">{"논문"}</option>
                  <option value="I">{"인증"}</option>
                  <option value="L">{"상표"}</option>
                </select>
              </div>

              <div className="newcertiBigBox">
                <div className="cerSmallInput">
                  <label>{"한글명"}</label>
                  <textarea
                    className="form-control certiEdit"
                    type="text"
                    align="top"
                    name="name_kr"
                    ref={register({ required: true })}
                    defaultValue={editnamekr || cerDetailEditData.name_kr}
                    onChange={namekr}
                  />
                  {errors.name_kr && (
                    <p className="formError">값을 입력해주세요</p>
                  )}
                </div>

                <div className="cerSmallInput">
                  <label>{"영어명"}</label>
                  <textarea
                    className="form-control certiEdit"
                    type="text"
                    name="name_en"
                    ref={register({ required: true })}
                    defaultValue={editnameen || cerDetailEditData.name_en}
                    onChange={nameen}
                  />
                  {errors.name_en && (
                    <p className="formError">값을 입력해주세요</p>
                  )}
                </div>

                <div className="cerSmallInput">
                  <label>{"포르투갈명"}</label>
                  <textarea
                    className="form-control certiEdit"
                    type="text"
                    name="name_pt"
                    ref={register({ required: true })}
                    defaultValue={editnamept || cerDetailEditData.name_pt}
                    onChange={namept}
                  />
                  {errors.name_pt && (
                    <p className="formError">값을 입력해주세요</p>
                  )}
                </div>
                <div className="cerSmallInput">
                  <label>{"일본명"}</label>
                  <textarea
                    className="form-control certiEdit"
                    type="text"
                    name="name_jp"
                    ref={register({ required: true })}
                    defaultValue={editnamejp || cerDetailEditData.name_jp}
                    onChange={namejp}
                  />
                  {errors.name_jp && (
                    <p className="formError">값을 입력해주세요</p>
                  )}
                </div>
              </div>

              <div className="newcertiBigBox">
                <div className="cerSmallInput">
                  <label>{"한글카테고리"}</label>
                  <textarea
                    className="form-control certiEditSmall"
                    type="text"
                    name="category_kr"
                    ref={register({ required: true })}
                    defaultValue={editcatekr || cerDetailEditData.category_kr}
                    onChange={catekr}
                  />
                  {errors.category_kr && (
                    <p className="formError">값을 입력해주세요</p>
                  )}
                </div>
                <div className="cerSmallInput">
                  <label>{"영어카테고리"}</label>
                  <textarea
                    className="form-control certiEditSmall"
                    type="text"
                    name="category_en"
                    ref={register({ required: true })}
                    defaultValue={editcateen || cerDetailEditData.category_en}
                    onChange={cateen}
                  />
                  {errors.category_en && (
                    <p className="formError">값을 입력해주세요</p>
                  )}
                </div>
                <div className="cerSmallInput">
                  <label>{"포르투갈카테고리"}</label>
                  <textarea
                    className="form-control certiEditSmall"
                    type="text"
                    name="category_pt"
                    ref={register({ required: true })}
                    defaultValue={editcatept || cerDetailEditData.category_pt}
                    onChange={catept}
                  />
                  {errors.category_pt && (
                    <p className="formError">값을 입력해주세요</p>
                  )}
                </div>
                <div className="cerSmallInput">
                  <label>{"일본카테고리"}</label>
                  <textarea
                    className="form-control certiEditSmall"
                    type="text"
                    name="category_jp"
                    ref={register({ required: true })}
                    defaultValue={editcatejp || cerDetailEditData.category_jp}
                    onChange={catejp}
                  />
                  {errors.category_jp && (
                    <p className="formError">값을 입력해주세요</p>
                  )}
                </div>
              </div>

              <div className="newcertiBigBox">
                <div className="cerSmallInput">
                  <label>{"한글번호"}</label>
                  <textarea
                    className="form-control certiEditSmall"
                    type="text"
                    name="date_certificate_kr"
                    ref={register({ required: true })}
                    defaultValue={
                      editdatecerkr || cerDetailEditData.date_certificate_kr
                    }
                    onChange={cerkr}
                  />
                  {errors.date_certificate_kr && (
                    <p className="formError">값을 입력해주세요</p>
                  )}
                </div>
                <div className="cerSmallInput">
                  <label>{"영어번호"}</label>
                  <textarea
                    className="form-control certiEditSmall"
                    type="text"
                    name="date_certificate_en"
                    ref={register({ required: true })}
                    defaultValue={
                      editdateceren || cerDetailEditData.date_certificate_en
                    }
                    onChange={ceren}
                  />
                  {errors.date_certificate_en && (
                    <p className="formError">값을 입력해주세요</p>
                  )}
                </div>
                <div className="cerSmallInput">
                  <label>{"포르투갈번호"}</label>
                  <textarea
                    className="form-control certiEditSmall"
                    type="text"
                    name="date_certificate_pt"
                    ref={register({ required: true })}
                    defaultValue={
                      editdatecerpt || cerDetailEditData.date_certificate_pt
                    }
                    onChange={cerpt}
                  />
                  {errors.date_certificate_pt && (
                    <p className="formError">값을 입력해주세요</p>
                  )}
                </div>
                <div className="cerSmallInput">
                  <label>{"일본번호"}</label>
                  <textarea
                    className="form-control certiEditSmall "
                    type="text"
                    name="date_certificate_jp"
                    ref={register({ required: true })}
                    defaultValue={
                      editdatecerjp || cerDetailEditData.date_certificate_jp
                    }
                    onChange={cerjp}
                  />
                  {errors.date_certificate_jp && (
                    <p className="formError">값을 입력해주세요</p>
                  )}
                </div>
              </div>

              <div className="newcertiBigBox">
                <div className="cerSmallInput">
                  <label>{"등록일자"}</label>
                  <input
                    className="form-control certiEditDate"
                    type="date"
                    name="date_create"
                    ref={register({ required: true })}
                    defaultValue={editcreate || editdate}
                    onChange={create}
                  />
                  {errors.date_create && (
                    <p className="formError">값을 입력해주세요</p>
                  )}
                </div>
              </div>
              <div className="newcertiBigBox">
                <div className="cerSmallInput">
                  <label>{"링크"}</label>
                  <textarea
                    className="form-control certiEditSmall"
                    type="text"
                    name="link"
                    ref={register}
                    defaultValue={editlink || cerDetailEditData.link}
                    onChange={link}
                  />
                </div>

              </div>

              <div className="submitBtn">
                <button
                  className="form-control"
                  style={{ borderRadius: "5px" }}
                >
                  수정
                </button>
              </div>
            </form>
          </div>
        </div>

       
      </div>
    </>
  );
};

export default CertificateDetailEdit;
