import React from "react";
import OtherNavbar from "components/Navbars/OtherNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import Path from "components/Path.js";
import { useTranslation } from 'react-i18next'
import { Button } from "reactstrap";
import { contact } from "../api";
import { countries, countries_eng } from "../assets/countries.js";
import detectBrowserLanguage from "detect-browser-language";

function HelpService() {
  const { t,i18n } = useTranslation()
  const [isValid, setIsValid] = React.useState(false);
  const [isNameValid, setIsNameValid] = React.useState(true);
  const [isInquryValid, setIsInquryValid] = React.useState(true);
  const [isCountryValid, setIsCountryValid] = React.useState(true);
  const [isEmailValid, setIsEmailValid] = React.useState(true);
  const [isEmailFormatValid, setIsEmailFormatValid] = React.useState(true);
  const [isPhoneValid, setIsPhoneValid] = React.useState(true);
  const [isAddrValid, setIsAddrValid] = React.useState(true);
  const [isDetailValid, setIsDetailValid] = React.useState(true);
  const [inquryValue, setInquryValue] = React.useState('');
  const [countryValue, setCountryValue] = React.useState('');
  const [curPosition, setCurPosition] = React.useState(0);
  const [curLang, setCurLang] = React.useState(i18n.language.substring(0,2) === 'ko' || !i18n.language && detectBrowserLanguage().substring(0,2) === 'ko' ? 'ko' : '')
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    if(curPosition === 0) {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    }
    setCurLang(i18n.language.substring(0,2) === 'ko' || !i18n.language && detectBrowserLanguage().substring(0,2) === 'ko' ? 'ko' : '')
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  let nameRef = React.createRef();
  let emailRef = React.createRef();
  let phoneRef = React.createRef();
  let addressRef = React.createRef();
  let ownCattleRef = React.createRef();
  let detailRef = React.createRef();
  let selectInqury = (e) => {
      setCurPosition(document.documentElement.scrollTop)
    setInquryValue(e.target.value)
  }
  let selectCountry = (e) => {
    setCurPosition(document.documentElement.scrollTop)
    setCountryValue(e.target.value)
  }
  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
  const handleSubmit = async (e) => {
        setCurPosition(document.documentElement.scrollTop)
      e.preventDefault()
      let valid = true;
        if(nameRef.current.value === '') {
            setIsNameValid(false)
            valid = false
        } else {
            setIsNameValid(true)
        }
        if(inquryValue === '') {
            setIsInquryValid(false)
            valid = false
        } else {
            setIsInquryValid(true)
        }
        if(countryValue === '') {
            setIsCountryValid(false)
            valid = false
        } else {
            setIsCountryValid(true)
        }
        if(emailRef.current.value === '') {
            setIsEmailValid(false)
            valid = false
        } else {
            setIsEmailValid(true)
        }
        if(validateEmail(emailRef.current.value)) {
            setIsEmailFormatValid(true)
        } else {
            setIsEmailFormatValid(false)
            valid = false
        }
        if(phoneRef.current.value === '') {
            setIsPhoneValid(false)
            valid = false
        } else {
            setIsPhoneValid(true)
        }
        if(addressRef.current.value === '') {
            setIsAddrValid(false)
            valid = false
        } else {
            setIsAddrValid(true)
        }
        if(detailRef.current.value === '') {
            setIsDetailValid(false)
            valid = false
        } else {
            setIsDetailValid(true)
        }
        if(valid) {
            const response = await contact(nameRef.current.value, emailRef.current.value, ownCattleRef.current.value, inquryValue, countryValue, phoneRef.current.value, addressRef.current.value, detailRef.current.value)
            if(response.result === "200") {
                setIsValid(true)
            } else{
                setIsValid(false)
            }
        } else {
            setIsValid(false)
        }
  }
  return (
    <>
      <OtherNavbar />
      <div className="wrapper">
        <div className="main">
          <Path firstPathTitle={t('홈')} firstPath="/" secondPathTitle={t('고객지원')} secondPath="/help" thirdPathTitle={t('제휴 문의')}/>
          <div className="culture_section privacy_secion help_service_section">
            <p className="privacy_title service_title">{t('제휴 문의')}</p>
            <p className="service_description">{t('유라이크코리아는 다양한 제휴 제안을 받고 있습니다. 유라이크코리아에게 하고 싶은 협업제안 혹은 사업 문의가 있으시면 아래 내용을 작성하세요.')}</p>
            <div className="privacy_detail announce_detail_part">
                <div className="help_service_form">
                    <form onSubmit={handleSubmit}>
                        <div className="service_multi_form">
                            <div className="form-group">
                                <label htmlFor="name">{t('담당자명')}*</label>
                                <input type="text" ref={nameRef} className="form-control" id="name" placeholder={t('담당자명')} />
                                {
                                    !isNameValid ?
                                    <p className="form_error">{t('담당자명을 입력하세요.')}</p>
                                    :
                                    null
                                }
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">{t('이메일 주소')}*</label>
                                <input type="email" ref={emailRef} className="form-control" id="email" placeholder={t('이메일 주소')} />
                                {
                                    !isEmailValid ?
                                    <p className="form_error">{t('이메일 주소를 입력하세요.')}</p>
                                    :
                                    !isEmailFormatValid ?
                                    <p className="form_error">{t('올바른 형식이 아닙니다.')}</p>
                                    :
                                    null
                                }
                            </div>
                        </div>
                        <div className="service_multi_form">
                            <div className="form-group">
                                <label htmlFor="name">{t('법인명')}</label>
                                <input type="text" ref={ownCattleRef} className="form-control" id="name" placeholder={t('법인명')} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="inqury">{t('카테고리')}*</label>
                                <select className="form-control" id="inqury" onChange={selectInqury}>
                                    <option value="">{t('카테고리')}</option>
                                    <option value="Goverment">{t('정부기관')}</option>
                                    <option value="Individually">{t('개인소유 목장')}</option>
                                    <option value="Corporate">{t('기업소유 목장')}</option>
                                    <option value="Livestock">{t('축산 기업')}</option>
                                    <option value="ICT">{t('ICT 기업')}</option>
                                    <option value="Etc">{t('기타')}</option>
                                </select>
                                {
                                    !isInquryValid ?
                                    <p className="form_error">{t('카테고리를 선택하세요.')}</p>
                                    :
                                    null
                                }
                            </div>
                        </div>
                        <div className="service_multi_form">
                            <div className="form-group">
                                <label htmlFor="country">{t('국가')}*</label>
                                <select className="form-control" id="country" onChange={selectCountry}>
                                    <option value="">{t('국가')}</option>
                                    {
                                        curLang === 'ko' ?
                                        countries.map((country, index) => {
                                            return <option key={index} value={country.name}>{country.name}</option>
                                        })
                                        :
                                        countries_eng.map((country, index) => {
                                            return <option key={index} value={country.name}>{country.name}</option>
                                        })
                                    }
                                </select>
                                {
                                    !isCountryValid ?
                                    <p className="form_error">{t('국가를 선택하세요.')}</p>
                                    :
                                    null
                                }
                            </div>
                            <div className="form-group">
                                <label htmlFor="phone">{t('전화번호')}*</label>
                                <input type="text" ref={phoneRef} className="form-control" id="phone" placeholder={t('전화번호')} />
                                {
                                    !isPhoneValid ?
                                    <p className="form_error">{t('전화번호를 입력하세요.')}</p>
                                    :
                                    null
                                }
                            </div>
                        </div>
                        <div className="service_multi_form">
                            <div className="form-group">
                                <label htmlFor="address">{t('주소')}*</label>
                                <input type="text" ref={addressRef} className="form-control" id="address" placeholder={t('주소')} />                                
                                {
                                    !isAddrValid ?
                                    <p className="form_error">{t('주소를 입력하세요.')}</p>
                                    :
                                    null
                                }
                            </div>
                        </div>
                        <div className="service_multi_form">
                            <div className="form-group">
                                <label htmlFor="detail">{t('문의 내용')}*</label>
                                <textarea className="form-control" ref={detailRef} id="detail" rows="6" placeholder={t('문의 내용을 입력하세요.')}></textarea>
                                {
                                    !isDetailValid ?
                                    <p className="form_error">{t('문의 내용을 입력하세요.')}</p>
                                    :
                                    null
                                }
                            </div>
                        </div>
                    </form>
                </div>
                <div className="service_btn">
                    {
                        isValid ?
                        <p className="accept_form_success">{t('문의가 접수되었습니다. 최대한 빠른 시일안에 연락드리겠습니다.')}</p>
                        :
                        null
                    }
                    
                    <Button className="rec_btn" onClick={(e) => handleSubmit(e)} style={{color: 'white', backgroundColor: '#1c4487'}}>{t('문의하기')}</Button>
                </div>
            </div>
          </div>
        </div>
        <DarkFooter />
      </div>
    </>
  );
}

export default HelpService;