import React, { useEffect  } from "react";
import {  Link } from 'react-router-dom';
function Aside(){
    return (
        <>
            <div className="asideDiv">
                <div className="asideLinks">

                    <Link to='/manager/adminStock' className="asidenav"><li className="asideli">예상매도가능 주식수</li></Link>
                    <Link to='/manager/adminpress' className="asidenav"><li className="asideli">언론보도</li></Link>
                    <Link to='/manager/adminCertificate' className="asidenav"><li className="asideli">연구특허</li></Link>
                    <Link to='/manager/adminService' className="asidenav"><li className="asideli">서비스문의</li></Link>
                    <Link to='/manager/adminContact' className="asidenav"><li className="asideli">제휴문의</li></Link>

                </div>
            </div>
        </>

    )
}

export default Aside