import React, { useState, useEffect } from "react";
import ManagerNavbar from "../../../components/Navbars/ManagerNavbar";
import Aside from "../../../components/Navbars/Aside";
// import QuillEditor from "../components/editor/QuillEditor";
import { useForm } from "react-hook-form";
import Axios from "axios";
import moment from "moment";
import ReactQuill, { Quill } from "react-quill";
import { useHistory } from "react-router-dom";
import axios from "axios";
import ImageResize from "quill-image-resize-module";
Quill.register("modules/ImageResize", ImageResize);

const BlockEmbed = Quill.import("blots/block/embed");

class ImageBlot extends BlockEmbed {
  static create(value) {
    const imgTag = super.create();
    imgTag.setAttribute("src", value.src);
    imgTag.setAttribute("alt", value.alt);
    imgTag.setAttribute("width", "100%");

    return imgTag;
  }

  static value(node) {
    return { src: node.getAttribute("src"), alt: node.getAttribute("alt") };
  }
}

ImageBlot.blotName = "image";
ImageBlot.tagName = "img";
Quill.register(ImageBlot);
class QuillEditor extends React.Component {
  bandId;
  placeholder;
  onEditorChange;
  onFilesChange;
  onPollsChange;
  _isMounted;

  constructor(props) {
    super(props);

    this.state = {
      editorHtml: "",
      files: [],
    };
    this.reactQuillRef = null;
    this.inputOpenImageRef = React.createRef();
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = (html) => {
    this.setState(
      {
        editorHtml: html,
      },
      () => {
        this.props.onEditorChange(this.state.editorHtml);
      }
    );
  };

  imageHandler = () => {
    this.inputOpenImageRef.current.click();
  };

  insertImage = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (
      e.currentTarget &&
      e.currentTarget.files &&
      e.currentTarget.files.length > 0
    ) {
      const file = e.currentTarget.files[0];

      let formData = new FormData();
      const config = {
        header: { "content-type": "multipart/form-data" },
      };
      formData.append("file", file);
      axios
        .post("https://livecare.io:441/uploader", formData, config)
        .then((response) => {
          const quill = this.reactQuillRef.getEditor();
          quill.focus();

          let range = quill.getSelection();
          let position = range ? range.index : 0;

          quill.insertEmbed(position, "image", {
            src: "https://ulikekorea.com:442" + response.data,
          });

          quill.setSelection(position + 1);

          if (this._isMounted) {
            this.setState(
              {
                files: [...this.state.files, file],
              },
              () => {
                this.props.onFilesChange(this.state.files);
              }
            );
          } else {
            return alert("FAILED");
          }
        });
    }
  };

  render() {
    return (
      <div>
        <div id="toolbar">
          <select
            className="ql-header"
            defaultValue={""}
            onChange={(e) => e.persist()}
          >
            <option value="1" />
            <option value="2" />
            <option value="3" />
            <option value="4" />
            <option value="5" />
            <option value="6" />
          </select>
          <select className="ql-color">
            <option value="red" />
            <option value="green" />
            <option value="blue" />
            <option value="orange" />
            <option value="violet" />
            <option value="#d0d1d2" />
            <option selected />
          </select>

          <button className="ql-bold" />
          <button className="ql-italic" />
          <button className="ql-underline" />
          <button className="ql-strike" />

          <button className="ql-insertImage">I</button>
          <button className="ql-insertVideo">V</button>
          <button className="ql-insertFile">F</button>
          <button className="ql-link" />
          <button className="ql-code-block" />
          <button className="ql-video" />
          <button className="ql-blockquote" />
          <button className="ql-clean" />
        </div>
        <ReactQuill
          ref={(el) => {
            this.reactQuillRef = el;
          }}
          theme={"snow"}
          onChange={this.handleChange}
          modules={this.modules}
          formats={this.formats}
          value={this.state.editorHtml || this.props.defaultValue || ""}
          placeholder={this.props.placeholder}
          style={{ height: "700px", backgroundColor: "white" }}
        />

        <input
          type="file"
          accept="image/*"
          ref={this.inputOpenImageRef}
          style={{ display: "none" }}
          onChange={this.insertImage}
        />
       
      </div>
    );
  }

  modules = {
    ImageResize: {
      modules: ["Resize", "DisplaySize", "Toolbar"],
    },
    toolbar: {
      container: "#toolbar",
     
      handlers: {
        insertImage: this.imageHandler,
      },
    },

  };

  formats = [
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "color",
    "background",
    "list",
    "bullet",
    "indent",
    "align",
    "link",
    "image",
    "video",
  ];
  
}

const PressDetailEdit = (props) => {
  const history = useHistory();
  const [pressDetailEditData, setPressDetailEditData] = React.useState([]);
  let pressDetailEditpathname = props.location.pathname;
  let substrPathName = pressDetailEditpathname.substr(25);

  useEffect(() => {
    let islogin = JSON.parse(localStorage.getItem("name"));
    if (islogin !== null) {
    } else {
      history.push("/manage");
    }
    fetchData();
  }, [fetchData, history]);

  const fetchData = () => {
    props.location.state.map((detail, index) => {
      if (detail.id === substrPathName) {
        setPressDetailEditData(detail);
      }
    });
  };

  let pressdate = moment(pressDetailEditData.date_create).format("YYYY.MM.DD");

  const { register, handleSubmit, errors } = useForm();

  const [editLanguage, setEditLanguage] = useState(
    pressDetailEditData.language
  );
  const [editTtile, setEditTitle] = useState("");
  const [editImage, setEditImage] = useState("");
  const [content, setContent] = useState("");
  const [files, setFiles] = useState([]);
  const [img, setImg] = useState("");
  const [editorContents, setEditorContents] = useState("");

  const onEditorChangeContent = (value) => {
    setContent(value);
  };
  const onFilesChange = (files) => {
    setFiles(files);
  };
  const onEditchangeLanguage = (e) => {
    setEditLanguage(e.target.value);
  };
  const onEditchangeTitle = (e) => {
    setEditTitle(e.target.value);
  };
  const onEditchangeImage = async (e) => {
    const file = e.currentTarget.files[0];

    const formData = new FormData();

    const config = {
      header: { "content-type": "multipart/form-data" },
    };
    formData.append("file", file);

    await Axios.post("https://livecare.io:441/uploader", formData, config)
      .then((res) => {
        const editThumbnailUrl = "https://ulikekorea.com:442" + res.data;
        setImg(editThumbnailUrl);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    setEditorContents(pressDetailEditData.contents);
  }, [pressDetailEditData.contents]);

  const onSubmit = (data) => {
    const variables = {
      language: data.language,
      title: data.title,
      image: data.image,
      content: content,
    };

    Axios({
      method: "put",
      url: `https://livecare.io:441/press?id=${substrPathName}&` + data,
      data: {
        language: data.language,
        title: data.title,
        image: img || pressDetailEditData.image,
        contents: content,
      },
    }).then((response) => {
      return history.push("/manager/adminpress");
    });
  };

  return (
    <>
      <ManagerNavbar />
      <div style={{ display: "flex" }}>
        <Aside></Aside>

        <div
          style={{ backgroundColor: "#EEF1F3", width: "100%", height: "120vh" }}
        >
          <div
            style={{
              width: "80%",
              margin: "0 auto",
              position: "relative",
              top: "150px",
            }}
          >
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div className="newPresstextBox">
                <label className="newPressLabel" ref={register}>
                  언어
                </label>
                <select
                  value={editLanguage || pressDetailEditData.language}
                  className="form-control newPressSelectBox"
                  name="language"
                  ref={register}
                  onChange={onEditchangeLanguage}
                >
                  <option value="ko">{"한국"}</option>
                  <option value="en">{"영어"}</option>
                  <option value="pt">{"포르투갈"}</option>
                  <option value="ja">{"일본"}</option>
                </select>
              </div>

              <div className="newPresstextBox">
                <label className="newPressLabel" ref={register}>
                  제목
                </label>
                <input
                  type="text"
                  className="newPressinputBox form-control"
                  onChange={onEditchangeTitle}
                  placeholder={"제목을 입력하세요"}
                  defaultValue={editTtile || pressDetailEditData.title}
                  name="title"
                  ref={register}
                />
                {errors.title && (
                  <p className="pressformError">값을 입력해주세요</p>
                )}
              </div>

              <div className="newPresstextBox">
                <label className="newPressLabel" ref={register}>
                  대표 사진
                </label>
                <input
                  type="file"
                  className=" "
                  onChange={onEditchangeImage}
                  placeholder={"사진 url을 입력하세요"}
                  name="image"
                  ref={register}
                />
                {/*|| pressDetailEditData.image*/}
                <span>기존 대표 사진 :</span>
                <span style={{ marginLeft: "10px" }}>
                  {pressDetailEditData.image}
                </span>
              </div>

              <QuillEditor
                // placeholder={"Start Posting Something"}
                defaultValue={editorContents}
                onEditorChange={onEditorChangeContent}
                onFilesChange={onFilesChange}
              />
              <div className="submitBtn">
                <button className="form-control">제출</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default PressDetailEdit;
