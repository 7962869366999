import React from "react";

function MainPartner(props) {
  return (
      <div className={props.left ? "margin_auto margin_left_0 "+props.class_name : "margin_auto "+props.class_name}>
        <span></span>
        <img alt="" src={require("assets/img/"+props.imgSrc)}/>
      </div>
  );
}

export default MainPartner;
