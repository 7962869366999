import React from "react";
import OtherNavbar from "components/Navbars/OtherNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import Path from "components/Path.js";
import { useTranslation } from 'react-i18next'
import { Button } from "reactstrap";
import { stock } from "../api";
import detectBrowserLanguage from "detect-browser-language";
import { countries, countries_eng } from "../assets/countries.js";
function HelpStock() {
const { t, i18n } = useTranslation()
  const [isValid, setIsValid] = React.useState(false);
  const [isNameValid, setIsNameValid] = React.useState(true);
  const [isServiceValid, setIsServiceValid] = React.useState(true);
  const [isInquryValid, setIsInquryValid] = React.useState(true);
  const [isCountryValid, setIsCountryValid] = React.useState(true);
  const [isEmailValid, setIsEmailValid] = React.useState(true);
  const [isEmailFormatValid, setIsEmailFormatValid] = React.useState(true);
  const [isPhoneValid, setIsPhoneValid] = React.useState(true);
  const [isTypeValid, setIsTypeValid] = React.useState(true);
  const [isAddrValid, setIsAddrValid] = React.useState(true);
  const [isDetailValid, setIsDetailValid] = React.useState(true);

  const [typeValue, setTypeValue] = React.useState('');
  const [curPosition, setCurPosition] = React.useState(0);
  const [curLang, setCurLang] = React.useState(i18n.language.substring(0,2) === 'ko' || !i18n.language && detectBrowserLanguage().substring(0,2) === 'ko' ? 'ko' : '')
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    if(curPosition === 0) {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    }
    setCurLang(i18n.language.substring(0,2) === 'ko' || !i18n.language && detectBrowserLanguage().substring(0,2) === 'ko' ? 'ko' : '')
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
    
  }, [curPosition, i18n.language]);

  const [clearName, setClearNmae] = React.useState('')
  const [clearemail, setClearemail] = React.useState('')
  const [clearphone, setClearphone] = React.useState('')
  const [clearstockCount, setClearstockCount] = React.useState('')
  const [cleardetail, setCleardetail] = React.useState('')
  const [clearcontactTime, setClearcontactTime] = React.useState('')

  function claerNameFuc(e) {
    setClearNmae(e.target.value)
  
}

function clearEmailFuc(e) {
    setClearemail(e.target.value)

}

function clearphoneFuc(e) {
    setClearphone(e.target.value)

}

function clearstockCountFuc(e) {
    setClearstockCount(e.target.value)
}

function clearDetailFunc(e) {
    setCleardetail(e.target.value)
}

function clearcontactTimeFuc(e) {
    setClearcontactTime(e.target.value)

}
  let nameRef = React.createRef();
  let emailRef = React.createRef();
  let phoneRef = React.createRef();
  let stockCountRef = React.createRef();
  let detailRef = React.createRef();
  let contactTimeRef = React.createRef();

  let selectType = (e) => {
    setTypeValue(e.target.value)
  }
  const handleSubmit = async (e) => {

      setCurPosition(document.documentElement.scrollTop)
      e.preventDefault()
      let valid = true;
        if(nameRef.current.value === '') {
            setIsNameValid(false)
            valid = false
        } else {
            setIsNameValid(true)
        }
       
        if(emailRef.current.value === '') {
            setIsEmailValid(true)
            valid = true
        } else {
            setIsEmailValid(true)
        }
        if(validateEmail(emailRef.current.value)) {
            setIsEmailFormatValid(true)
        } else {
            setIsEmailFormatValid(false)
            valid = true
        }
        if(phoneRef.current.value === '') {
            setIsPhoneValid(false)
            valid = false
        } else {
            setIsPhoneValid(true)
        }
        if(typeValue === '') {
            setIsTypeValid(false)
            valid = false
        } else {
            setIsTypeValid(true)
        }
 
        if(detailRef.current.value === '') {
            setIsDetailValid(true)
            valid = true
        } else {
            setIsDetailValid(true)
        }
        if(valid) {
            const response = await stock(clearName, clearemail, clearphone, clearstockCount, typeValue, cleardetail)
            
            if(response.result === "200") {
                setClearNmae('')
                setClearemail('')
                setClearphone('')
                setClearstockCount('')
                setCleardetail('')
                setClearcontactTime('')
                setTypeValue('')
               
                setIsValid(true)
                alert('문의가 접수되었습니다')
            } else{
                setIsValid(false)
            }
        } else {
            setIsValid(false)
        }
  }
  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
  return (
    <>
      <OtherNavbar />
      <div className="wrapper">
        <div className="main">
          <Path firstPathTitle={t('홈')} firstPath="/" secondPathTitle={t('고객지원')} secondPath="/help" thirdPathTitle="주식 양도신청"/>
          <div className="culture_section privacy_secion help_service_section">
            <p className="privacy_title service_title">주식 양도신청</p>
            <p className="service_description">주식 양도신청을 원하시는 고객분들은 아래 내용을 작성하세요.</p>
            <div className="privacy_detail announce_detail_part">
                <div className="help_service_form">
                    <form onSubmit={handleSubmit}>
                      
                        <div className="service_multi_form">
                            <div className="form-group">
                                <label htmlFor="name">{t('이름')}*</label>
                                <input type="text" ref={nameRef} className="form-control" value={clearName} onChange={claerNameFuc} id="name" placeholder={t('이름')} />
                                {
                                    !isNameValid ?
                                    <p className="form_error">{t('이름을 입력하세요.')}</p>
                                    :
                                    null
                                }
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">{t('이메일 주소')}</label>
                                <input type="email" ref={emailRef} value={clearemail} className="form-control" onChange={clearEmailFuc} id="email" placeholder={t('이메일 주소')} />
                               
                            </div>
                            
                        </div>
                        <div className="service_multi_form">
                           
                            <div className="form-group">
                                <label htmlFor="phone">{t('전화번호')}*</label>
                                <input type="text" ref={phoneRef} value={clearphone} className="form-control" onChange={clearphoneFuc} id="phone" placeholder={t('전화번호')} />
                                {
                                    !isPhoneValid ?
                                    <p className="form_error">{t('전화번호를 입력하세요.')}</p>
                                    :
                                    null
                                }
                            </div>
                            <div className="form-group">
                                <label htmlFor="phone">예상매도가능 주식수*</label>
                                <input type="text" ref={stockCountRef} value={clearstockCount} className="form-control" onChange={clearstockCountFuc} id="phone" placeholder="예상매도가능 주식수" />
                                {
                                    !isPhoneValid ?
                                    <p className="form_error">{t('전화번호를 입력하세요.')}</p>
                                    :
                                    null
                                }
                            </div>
                        </div>
                        <div className="service_multi_form">
                          
                            <div className="form-group">
                                <label htmlFor="type">연락가능 시간*</label>
                                <select className="form-control" id="type" value={typeValue}  ref={contactTimeRef} onChange={selectType}>
                                    <option value="">시간</option>
                                    <option value="E">09 ~ 12</option>
                                    <option value="M">12 ~ 15</option>
                                    <option value="L">15 ~ 18</option>
                                </select>
                                {
                                    !isTypeValid ?
                                    <p className="form_error">시간을 선택하세요</p>
                                    :
                                    null
                                }
                            </div>
                        </div>
                       
                        <div className="service_multi_form">
                            <div className="form-group">
                                <label htmlFor="detail">추가문의사항</label>
                                <textarea className="form-control" value={cleardetail} ref={detailRef} onChange={clearDetailFunc} id="detail" rows="6" placeholder={t('문의 내용을 입력하세요.')}></textarea>
                              
                            </div>
                        </div>
                    </form>
                </div>
                <div className="service_btn">
                    {
                        isValid ?
                        <p className="accept_form_success">{t('문의가 접수되었습니다. 최대한 빠른 시일안에 연락드리겠습니다.')}</p>
                        :
                        null
                    }
                    
                    <Button className="rec_btn" onClick={(e) => handleSubmit(e)} style={{color: 'white', backgroundColor: '#1c4487'}}>신청하기</Button>
                </div>
            </div>
          </div>
        </div>
        <DarkFooter />
      </div>
    </>
  );
}

export default HelpStock;