import React, { useEffect } from "react";
import {certificate_get, service_get, stock_get} from "../../../api";
import {Pagination, PaginationItem, PaginationLink, Table} from "reactstrap";
import {useHistory} from "react-router-dom";
import DeleteModal from "../../DeleteModal";

const  ServiceTableContent = (props)=> {
    const history = useHistory();
    return props.data.map((detail, index) => {
   
        if(detail.contactTime == "E" ){ 
            var contactTimes = "09 ~ 12"
        } else if(detail.contactTime == "M") {
            var contactTimes = "12 ~ 15"
        } else{
            var contactTimes = "15 ~ 18"

        }

        let contactTimeval = contactTimes
        return(
            <tr key={index}>
                <td className='cursorPointer'  onClick={() => history.push(`/manager/stockdetail/${detail.id}`,props.data)}>{detail.user_name}</td>
                <td className='cursorPointer'  onClick={() => history.push(`/manager/stockdetail/${detail.id}`,props.data)}>{detail.email}</td>
                <td className='cursorPointer'  onClick={() => history.push(`/manager/stockdetail/${detail.id}`,props.data)}>{detail.phone}</td>
                <td className='cursorPointer'  onClick={() => history.push(`/manager/stockdetail/${detail.id}`,props.data)}>{detail.stockCount}</td>
                <td className='cursorPointer'  onClick={() => history.push(`/manager/stockdetail/${detail.id}`,props.data)}>{contactTimeval}</td>
                <td>{detail.date_create}</td>
                <td className='cursorPointer'><DeleteModal deletePath={'stock?'} deleteId={detail.id}/></td>
            </tr>

        )

    })

}

const StockTable = (props) => {
    const [curPage, setCurPage] = React.useState(1)
    const [list, setList] = React.useState(props.data)

    useEffect(() => {
        setList(props.data)
    },[props]);
    const callData = async (page) => {
        if(curPage !== page) {
            setCurPage(page)
            const response = await stock_get( page, 15);
            if(response.result === "200" && response.data.length > 0) {
                setList(response.data)
            }
        }
    }
    return(
        <div style={{backgroundColor:'#EEF1F3', width:'100%',height:'105vh'}}>
        <div style={{width:'80%', margin:'0 auto',position:'relative',top:'150px'}}>
            <span className="servieSubTitle">주식 양도 신청 목록</span>
            <Table className="manager_table">
                <thead>
                <tr>
                    <th>이름</th>
                    <th>이메일</th>
                    <th>전화번호</th>
                    <th>예상매도가능 주식수</th>
                    <th>예상 연락 선호시간</th>
                    <th>문의일</th>
                    <td>삭제</td>

                </tr>
                </thead>
                <tbody>
                {
                    list && list.length > 0 ?
                        <ServiceTableContent data={list} cur_page={curPage}/>
                        :
                        null
                }
                </tbody>
            </Table>

            <div style={{textAlign: 'center'}}>
                <Pagination>
                    <PaginationItem>
                        <PaginationLink
                            aria-label="First"
                            href="#pablo"
                            onClick={(e) =>  { e.preventDefault(); callData(1) }}
                        >
                        <span aria-hidden={true}>
                        <i
                            aria-hidden={true}
                            className="fa fa-angle-double-left"
                        ></i>
                        </span>
                        </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                        <PaginationLink
                            aria-label="Previous"
                            href="#pablo"
                            onClick={(e) => { e.preventDefault(); callData(curPage > 1 ? curPage - 1 : 1) }}
                        >
                        <span aria-hidden={true}>
                        <i
                            aria-hidden={true}
                            className="fa fa-angle-left"
                        ></i>
                        </span>
                        </PaginationLink>
                    </PaginationItem>


                    <span className="curPage">{curPage}</span>
                    <span>/</span>
                    <span className="lastPage">{props.total}</span>

                    <PaginationItem>
                        <PaginationLink
                            aria-label="Next"
                            href="#pablo"
                            onClick={(e) => { e.preventDefault(); callData(curPage < props.total ? curPage + 1 : props.total) }}
                        >
                        <span aria-hidden={true}>
                        <i
                            aria-hidden={true}
                            className="fa fa-angle-right"
                        ></i>
                        </span>
                        </PaginationLink>
                    </PaginationItem>

                    <PaginationItem>
                        <PaginationLink
                            aria-label="Last"
                            href="#pablo"
                            onClick={(e) => { e.preventDefault(); callData(props.total) }}
                        >
                        <span aria-hidden={true}>
                        <i
                            aria-hidden={true}
                            className="fa fa-angle-double-right"
                        ></i>
                        </span>
                        </PaginationLink>
                    </PaginationItem>

                </Pagination>

            </div>
        </div>
        </div>

    )
}

export default StockTable