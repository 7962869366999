import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
    Collapse,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    NavbarBrand,
    Navbar,
    NavItem,
    NavLink,
    Nav,
    Container,
} from "reactstrap";
import { useTranslation } from 'react-i18next'
import detectBrowserLanguage from 'detect-browser-language'
function ManagerNavbar() {
    const [collapseOpen, setCollapseOpen] = React.useState(false);
    const { t, i18n } = useTranslation()
    const [curLang , setCurLang] = React.useState(i18n.language.substring(0,2) === 'ko' || !i18n.language.substring(0,2) && detectBrowserLanguage().substring(0,2) === 'ko' ? "KOR" : i18n.language.substring(0,2) === 'ja' || !i18n.language && detectBrowserLanguage().substring(0,2) === 'ja' ? 'JPN' : i18n.language.substring(0,2) === 'pt' || !i18n.language && detectBrowserLanguage().substring(0,2) === 'pt' ? 'POR' : 'ENG');

    const logoutclick = () => {
        localStorage.removeItem('name');

    }
    return (
        <>
            <Navbar className={"fixed-top header other_header"} expand="lg">
                <Container>
                    <div className="navbar-translate" style={{width:'100%', display:'flex', justifyContent:'inherit'}}>
                        <NavbarBrand
                            to="/manager/home" tag={Link}
                            id="navbar-brand"
                        >
                            <img alt="" src={require("assets/img/solid--white.png")} className="header_logo" />

                        </NavbarBrand>
                        <NavbarBrand
                            to="/manage" tag={Link}
                            id="navbar-brand"
                            style={{paddingTop:'15px'}}
                            onClick={logoutclick}
                        >
                            로그아웃

                        </NavbarBrand>
                       
                    </div>
                    <Collapse
                        className="justify-content-end"
                        isOpen={collapseOpen}
                        navbar
                    >
                       
                    </Collapse>
                </Container>
            </Navbar>
        </>
    );
}

export default ManagerNavbar;