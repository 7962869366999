export const countries = [{"id":288,"name":"가나","alpha2":"gh","alpha3":"gha"},
{"id":266,"name":"가봉","alpha2":"ga","alpha3":"gab"},
{"id":328,"name":"가이아나","alpha2":"gy","alpha3":"guy"},
{"id":270,"name":"감비아","alpha2":"gm","alpha3":"gmb"},
{"id":320,"name":"과테말라","alpha2":"gt","alpha3":"gtm"},
{"id":308,"name":"그레나다","alpha2":"gd","alpha3":"grd"},
{"id":300,"name":"그리스","alpha2":"gr","alpha3":"grc"},
{"id":324,"name":"기니","alpha2":"gn","alpha3":"gin"},
{"id":624,"name":"기니비사우","alpha2":"gw","alpha3":"gnb"},
{"id":516,"name":"나미비아","alpha2":"na","alpha3":"nam"},
{"id":520,"name":"나우루","alpha2":"nr","alpha3":"nru"},
{"id":566,"name":"나이지리아","alpha2":"ng","alpha3":"nga"},
{"id":728,"name":"남수단","alpha2":"ss","alpha3":"ssd"},
{"id":710,"name":"남아프리카 공화국","alpha2":"za","alpha3":"zaf"},
{"id":528,"name":"네덜란드","alpha2":"nl","alpha3":"nld"},
{"id":524,"name":"네팔","alpha2":"np","alpha3":"npl"},
{"id":578,"name":"노르웨이","alpha2":"no","alpha3":"nor"},
{"id":554,"name":"뉴질랜드","alpha2":"nz","alpha3":"nzl"},
{"id":562,"name":"니제르","alpha2":"ne","alpha3":"ner"},
{"id":558,"name":"니카라과","alpha2":"ni","alpha3":"nic"},
{"id":410,"name":"대한민국","alpha2":"kr","alpha3":"kor"},
{"id":208,"name":"덴마크","alpha2":"dk","alpha3":"dnk"},
{"id":214,"name":"도미니카 공화국","alpha2":"do","alpha3":"dom"},
{"id":212,"name":"도미니카 연방","alpha2":"dm","alpha3":"dma"},
{"id":276,"name":"독일","alpha2":"de","alpha3":"deu"},
{"id":626,"name":"동티모르","alpha2":"tl","alpha3":"tls"},
{"id":418,"name":"라오스","alpha2":"la","alpha3":"lao"},
{"id":430,"name":"라이베리아","alpha2":"lr","alpha3":"lbr"},
{"id":428,"name":"라트비아","alpha2":"lv","alpha3":"lva"},
{"id":643,"name":"러시아","alpha2":"ru","alpha3":"rus"},
{"id":422,"name":"레바논","alpha2":"lb","alpha3":"lbn"},
{"id":426,"name":"레소토","alpha2":"ls","alpha3":"lso"},
{"id":642,"name":"루마니아","alpha2":"ro","alpha3":"rou"},
{"id":442,"name":"룩셈부르크","alpha2":"lu","alpha3":"lux"},
{"id":646,"name":"르완다","alpha2":"rw","alpha3":"rwa"},
{"id":434,"name":"리비아","alpha2":"ly","alpha3":"lby"},
{"id":440,"name":"리투아니아","alpha2":"lt","alpha3":"ltu"},
{"id":438,"name":"리히텐슈타인","alpha2":"li","alpha3":"lie"},
{"id":450,"name":"마다가스카르","alpha2":"mg","alpha3":"mdg"},
{"id":584,"name":"마셜 제도","alpha2":"mh","alpha3":"mhl"},
{"id":807,"name":"북마케도니아","alpha2":"mk","alpha3":"mkd"},
{"id":454,"name":"말라위","alpha2":"mw","alpha3":"mwi"},
{"id":458,"name":"말레이시아","alpha2":"my","alpha3":"mys"},
{"id":466,"name":"말리","alpha2":"ml","alpha3":"mli"},
{"id":484,"name":"멕시코","alpha2":"mx","alpha3":"mex"},
{"id":492,"name":"모나코","alpha2":"mc","alpha3":"mco"},
{"id":504,"name":"모로코","alpha2":"ma","alpha3":"mar"},
{"id":480,"name":"모리셔스","alpha2":"mu","alpha3":"mus"},
{"id":478,"name":"모리타니","alpha2":"mr","alpha3":"mrt"},
{"id":508,"name":"모잠비크","alpha2":"mz","alpha3":"moz"},
{"id":499,"name":"몬테네그로","alpha2":"me","alpha3":"mne"},
{"id":498,"name":"몰도바","alpha2":"md","alpha3":"mda"},
{"id":462,"name":"몰디브","alpha2":"mv","alpha3":"mdv"},
{"id":470,"name":"몰타","alpha2":"mt","alpha3":"mlt"},
{"id":496,"name":"몽골","alpha2":"mn","alpha3":"mng"},
{"id":840,"name":"미국","alpha2":"us","alpha3":"usa"},
{"id":104,"name":"미얀마","alpha2":"mm","alpha3":"mmr"},
{"id":583,"name":"미크로네시아 연방","alpha2":"fm","alpha3":"fsm"},
{"id":548,"name":"바누아투","alpha2":"vu","alpha3":"vut"},
{"id":48,"name":"바레인","alpha2":"bh","alpha3":"bhr"},
{"id":52,"name":"바베이도스","alpha2":"bb","alpha3":"brb"},
{"id":44,"name":"바하마","alpha2":"bs","alpha3":"bhs"},
{"id":50,"name":"방글라데시","alpha2":"bd","alpha3":"bgd"},
{"id":204,"name":"베냉","alpha2":"bj","alpha3":"ben"},
{"id":862,"name":"베네수엘라","alpha2":"ve","alpha3":"ven"},
{"id":704,"name":"베트남","alpha2":"vn","alpha3":"vnm"},
{"id":56,"name":"벨기에","alpha2":"be","alpha3":"bel"},
{"id":112,"name":"벨라루스","alpha2":"by","alpha3":"blr"},
{"id":84,"name":"벨리즈","alpha2":"bz","alpha3":"blz"},
{"id":70,"name":"보스니아 헤르체고비나","alpha2":"ba","alpha3":"bih"},
{"id":72,"name":"보츠와나","alpha2":"bw","alpha3":"bwa"},
{"id":68,"name":"볼리비아","alpha2":"bo","alpha3":"bol"},
{"id":108,"name":"부룬디","alpha2":"bi","alpha3":"bdi"},
{"id":854,"name":"부르키나파소","alpha2":"bf","alpha3":"bfa"},
{"id":64,"name":"부탄","alpha2":"bt","alpha3":"btn"},
{"id":100,"name":"불가리아","alpha2":"bg","alpha3":"bgr"},
{"id":76,"name":"브라질","alpha2":"br","alpha3":"bra"},
{"id":96,"name":"브루나이","alpha2":"bn","alpha3":"brn"},
{"id":882,"name":"사모아","alpha2":"ws","alpha3":"wsm"},
{"id":682,"name":"사우디아라비아","alpha2":"sa","alpha3":"sau"},
{"id":674,"name":"산마리노","alpha2":"sm","alpha3":"smr"},
{"id":678,"name":"상투메 프린시페","alpha2":"st","alpha3":"stp"},
{"id":686,"name":"세네갈","alpha2":"sn","alpha3":"sen"},
{"id":688,"name":"세르비아","alpha2":"rs","alpha3":"srb"},
{"id":690,"name":"세이셸","alpha2":"sc","alpha3":"syc"},
{"id":662,"name":"세인트루시아","alpha2":"lc","alpha3":"lca"},
{"id":670,"name":"세인트빈센트 그레나딘","alpha2":"vc","alpha3":"vct"},
{"id":659,"name":"세인트키츠 네비스","alpha2":"kn","alpha3":"kna"},
{"id":706,"name":"소말리아","alpha2":"so","alpha3":"som"},
{"id":90,"name":"솔로몬 제도","alpha2":"sb","alpha3":"slb"},
{"id":729,"name":"수단","alpha2":"sd","alpha3":"sdn"},
{"id":740,"name":"수리남","alpha2":"sr","alpha3":"sur"},
{"id":144,"name":"스리랑카","alpha2":"lk","alpha3":"lka"},
{"id":752,"name":"스웨덴","alpha2":"se","alpha3":"swe"},
{"id":756,"name":"스위스","alpha2":"ch","alpha3":"che"},
{"id":724,"name":"스페인","alpha2":"es","alpha3":"esp"},
{"id":703,"name":"슬로바키아","alpha2":"sk","alpha3":"svk"},
{"id":705,"name":"슬로베니아","alpha2":"si","alpha3":"svn"},
{"id":760,"name":"시리아","alpha2":"sy","alpha3":"syr"},
{"id":694,"name":"시에라리온","alpha2":"sl","alpha3":"sle"},
{"id":702,"name":"싱가포르","alpha2":"sg","alpha3":"sgp"},
{"id":784,"name":"아랍에미리트","alpha2":"ae","alpha3":"are"},
{"id":51,"name":"아르메니아","alpha2":"am","alpha3":"arm"},
{"id":32,"name":"아르헨티나","alpha2":"ar","alpha3":"arg"},
{"id":352,"name":"아이슬란드","alpha2":"is","alpha3":"isl"},
{"id":332,"name":"아이티","alpha2":"ht","alpha3":"hti"},
{"id":372,"name":"아일랜드","alpha2":"ie","alpha3":"irl"},
{"id":31,"name":"아제르바이잔","alpha2":"az","alpha3":"aze"},
{"id":4,"name":"아프가니스탄","alpha2":"af","alpha3":"afg"},
{"id":20,"name":"안도라","alpha2":"ad","alpha3":"and"},
{"id":8,"name":"알바니아","alpha2":"al","alpha3":"alb"},
{"id":12,"name":"알제리","alpha2":"dz","alpha3":"dza"},
{"id":24,"name":"앙골라","alpha2":"ao","alpha3":"ago"},
{"id":28,"name":"앤티가 바부다","alpha2":"ag","alpha3":"atg"},
{"id":232,"name":"에리트레아","alpha2":"er","alpha3":"eri"},
{"id":748,"name":"에스와티니","alpha2":"sz","alpha3":"swz"},
{"id":233,"name":"에스토니아","alpha2":"ee","alpha3":"est"},
{"id":218,"name":"에콰도르","alpha2":"ec","alpha3":"ecu"},
{"id":231,"name":"에티오피아","alpha2":"et","alpha3":"eth"},
{"id":222,"name":"엘살바도르","alpha2":"sv","alpha3":"slv"},
{"id":826,"name":"영국","alpha2":"gb","alpha3":"gbr"},
{"id":887,"name":"예멘","alpha2":"ye","alpha3":"yem"},
{"id":512,"name":"오만","alpha2":"om","alpha3":"omn"},
{"id":36,"name":"오스트레일리아","alpha2":"au","alpha3":"aus"},
{"id":40,"name":"오스트리아","alpha2":"at","alpha3":"aut"},
{"id":340,"name":"온두라스","alpha2":"hn","alpha3":"hnd"},
{"id":400,"name":"요르단","alpha2":"jo","alpha3":"jor"},
{"id":800,"name":"우간다","alpha2":"ug","alpha3":"uga"},
{"id":858,"name":"우루과이","alpha2":"uy","alpha3":"ury"},
{"id":860,"name":"우즈베키스탄","alpha2":"uz","alpha3":"uzb"},
{"id":804,"name":"우크라이나","alpha2":"ua","alpha3":"ukr"},
{"id":368,"name":"이라크","alpha2":"iq","alpha3":"irq"},
{"id":364,"name":"이란","alpha2":"ir","alpha3":"irn"},
{"id":376,"name":"이스라엘","alpha2":"il","alpha3":"isr"},
{"id":818,"name":"이집트","alpha2":"eg","alpha3":"egy"},
{"id":380,"name":"이탈리아","alpha2":"it","alpha3":"ita"},
{"id":356,"name":"인도","alpha2":"in","alpha3":"ind"},
{"id":360,"name":"인도네시아","alpha2":"id","alpha3":"idn"},
{"id":392,"name":"일본","alpha2":"jp","alpha3":"jpn"},
{"id":388,"name":"자메이카","alpha2":"jm","alpha3":"jam"},
{"id":894,"name":"잠비아","alpha2":"zm","alpha3":"zmb"},
{"id":226,"name":"적도 기니","alpha2":"gq","alpha3":"gnq"},
{"id":408,"name":"조선민주주의인민공화국","alpha2":"kp","alpha3":"prk"},
{"id":268,"name":"조지아","alpha2":"ge","alpha3":"geo"},
{"id":156,"name":"중국","alpha2":"cn","alpha3":"chn"},
{"id":140,"name":"중앙아프리카 공화국","alpha2":"cf","alpha3":"caf"},
{"id":262,"name":"지부티","alpha2":"dj","alpha3":"dji"},
{"id":716,"name":"짐바브웨","alpha2":"zw","alpha3":"zwe"},
{"id":148,"name":"차드","alpha2":"td","alpha3":"tcd"},
{"id":203,"name":"체코","alpha2":"cz","alpha3":"cze"},
{"id":152,"name":"칠레","alpha2":"cl","alpha3":"chl"},
{"id":120,"name":"카메룬","alpha2":"cm","alpha3":"cmr"},
{"id":132,"name":"카보베르데","alpha2":"cv","alpha3":"cpv"},
{"id":398,"name":"카자흐스탄","alpha2":"kz","alpha3":"kaz"},
{"id":634,"name":"카타르","alpha2":"qa","alpha3":"qat"},
{"id":116,"name":"캄보디아","alpha2":"kh","alpha3":"khm"},
{"id":124,"name":"캐나다","alpha2":"ca","alpha3":"can"},
{"id":404,"name":"케냐","alpha2":"ke","alpha3":"ken"},
{"id":174,"name":"코모로","alpha2":"km","alpha3":"com"},
{"id":188,"name":"코스타리카","alpha2":"cr","alpha3":"cri"},
{"id":384,"name":"코트디부아르","alpha2":"ci","alpha3":"civ"},
{"id":170,"name":"콜롬비아","alpha2":"co","alpha3":"col"},
{"id":178,"name":"콩고 공화국","alpha2":"cg","alpha3":"cog"},
{"id":180,"name":"콩고 민주 공화국","alpha2":"cd","alpha3":"cod"},
{"id":192,"name":"쿠바","alpha2":"cu","alpha3":"cub"},
{"id":414,"name":"쿠웨이트","alpha2":"kw","alpha3":"kwt"},
{"id":191,"name":"크로아티아","alpha2":"hr","alpha3":"hrv"},
{"id":417,"name":"키르기스스탄","alpha2":"kg","alpha3":"kgz"},
{"id":296,"name":"키리바시","alpha2":"ki","alpha3":"kir"},
{"id":196,"name":"키프로스","alpha2":"cy","alpha3":"cyp"},
{"id":762,"name":"타지키스탄","alpha2":"tj","alpha3":"tjk"},
{"id":834,"name":"탄자니아","alpha2":"tz","alpha3":"tza"},
{"id":764,"name":"태국","alpha2":"th","alpha3":"tha"},
{"id":792,"name":"터키","alpha2":"tr","alpha3":"tur"},
{"id":768,"name":"토고","alpha2":"tg","alpha3":"tgo"},
{"id":776,"name":"통가","alpha2":"to","alpha3":"ton"},
{"id":795,"name":"투르크메니스탄","alpha2":"tm","alpha3":"tkm"},
{"id":798,"name":"투발루","alpha2":"tv","alpha3":"tuv"},
{"id":788,"name":"튀니지","alpha2":"tn","alpha3":"tun"},
{"id":780,"name":"트리니다드 토바고","alpha2":"tt","alpha3":"tto"},
{"id":591,"name":"파나마","alpha2":"pa","alpha3":"pan"},
{"id":600,"name":"파라과이","alpha2":"py","alpha3":"pry"},
{"id":586,"name":"파키스탄","alpha2":"pk","alpha3":"pak"},
{"id":598,"name":"파푸아뉴기니","alpha2":"pg","alpha3":"png"},
{"id":585,"name":"팔라우","alpha2":"pw","alpha3":"plw"},
{"id":604,"name":"페루","alpha2":"pe","alpha3":"per"},
{"id":620,"name":"포르투갈","alpha2":"pt","alpha3":"prt"},
{"id":616,"name":"폴란드","alpha2":"pl","alpha3":"pol"},
{"id":250,"name":"프랑스","alpha2":"fr","alpha3":"fra"},
{"id":242,"name":"피지","alpha2":"fj","alpha3":"fji"},
{"id":246,"name":"핀란드","alpha2":"fi","alpha3":"fin"},
{"id":608,"name":"필리핀","alpha2":"ph","alpha3":"phl"},
{"id":348,"name":"헝가리","alpha2":"hu","alpha3":"hun"}];

export const countries_eng = [ 
    {name: 'Afghanistan', code: 'AF'}, 
    {name: 'Åland Islands', code: 'AX'}, 
    {name: 'Albania', code: 'AL'}, 
    {name: 'Algeria', code: 'DZ'}, 
    {name: 'American Samoa', code: 'AS'}, 
    {name: 'AndorrA', code: 'AD'}, 
    {name: 'Angola', code: 'AO'}, 
    {name: 'Anguilla', code: 'AI'}, 
    {name: 'Antarctica', code: 'AQ'}, 
    {name: 'Antigua and Barbuda', code: 'AG'}, 
    {name: 'Argentina', code: 'AR'}, 
    {name: 'Armenia', code: 'AM'}, 
    {name: 'Aruba', code: 'AW'}, 
    {name: 'Australia', code: 'AU'}, 
    {name: 'Austria', code: 'AT'}, 
    {name: 'Azerbaijan', code: 'AZ'}, 
    {name: 'Bahamas', code: 'BS'}, 
    {name: 'Bahrain', code: 'BH'}, 
    {name: 'Bangladesh', code: 'BD'}, 
    {name: 'Barbados', code: 'BB'}, 
    {name: 'Belarus', code: 'BY'}, 
    {name: 'Belgium', code: 'BE'}, 
    {name: 'Belize', code: 'BZ'}, 
    {name: 'Benin', code: 'BJ'}, 
    {name: 'Bermuda', code: 'BM'}, 
    {name: 'Bhutan', code: 'BT'}, 
    {name: 'Bolivia', code: 'BO'}, 
    {name: 'Bosnia and Herzegovina', code: 'BA'}, 
    {name: 'Botswana', code: 'BW'}, 
    {name: 'Bouvet Island', code: 'BV'}, 
    {name: 'Brazil', code: 'BR'}, 
    {name: 'British Indian Ocean Territory', code: 'IO'}, 
    {name: 'Brunei Darussalam', code: 'BN'}, 
    {name: 'Bulgaria', code: 'BG'}, 
    {name: 'Burkina Faso', code: 'BF'}, 
    {name: 'Burundi', code: 'BI'}, 
    {name: 'Cambodia', code: 'KH'}, 
    {name: 'Cameroon', code: 'CM'}, 
    {name: 'Canada', code: 'CA'}, 
    {name: 'Cape Verde', code: 'CV'}, 
    {name: 'Cayman Islands', code: 'KY'}, 
    {name: 'Central African Republic', code: 'CF'}, 
    {name: 'Chad', code: 'TD'}, 
    {name: 'Chile', code: 'CL'}, 
    {name: 'China', code: 'CN'}, 
    {name: 'Christmas Island', code: 'CX'}, 
    {name: 'Cocos (Keeling) Islands', code: 'CC'}, 
    {name: 'Colombia', code: 'CO'}, 
    {name: 'Comoros', code: 'KM'}, 
    {name: 'Congo', code: 'CG'}, 
    {name: 'Congo, The Democratic Republic of the', code: 'CD'}, 
    {name: 'Cook Islands', code: 'CK'}, 
    {name: 'Costa Rica', code: 'CR'}, 
    {name: 'Cote D\'Ivoire', code: 'CI'}, 
    {name: 'Croatia', code: 'HR'}, 
    {name: 'Cuba', code: 'CU'}, 
    {name: 'Cyprus', code: 'CY'}, 
    {name: 'Czech Republic', code: 'CZ'}, 
    {name: 'Denmark', code: 'DK'}, 
    {name: 'Djibouti', code: 'DJ'}, 
    {name: 'Dominica', code: 'DM'}, 
    {name: 'Dominican Republic', code: 'DO'}, 
    {name: 'Ecuador', code: 'EC'}, 
    {name: 'Egypt', code: 'EG'}, 
    {name: 'El Salvador', code: 'SV'}, 
    {name: 'Equatorial Guinea', code: 'GQ'}, 
    {name: 'Eritrea', code: 'ER'}, 
    {name: 'Estonia', code: 'EE'}, 
    {name: 'Ethiopia', code: 'ET'}, 
    {name: 'Falkland Islands (Malvinas)', code: 'FK'}, 
    {name: 'Faroe Islands', code: 'FO'}, 
    {name: 'Fiji', code: 'FJ'}, 
    {name: 'Finland', code: 'FI'}, 
    {name: 'France', code: 'FR'}, 
    {name: 'French Guiana', code: 'GF'}, 
    {name: 'French Polynesia', code: 'PF'}, 
    {name: 'French Southern Territories', code: 'TF'}, 
    {name: 'Gabon', code: 'GA'}, 
    {name: 'Gambia', code: 'GM'}, 
    {name: 'Georgia', code: 'GE'}, 
    {name: 'Germany', code: 'DE'}, 
    {name: 'Ghana', code: 'GH'}, 
    {name: 'Gibraltar', code: 'GI'}, 
    {name: 'Greece', code: 'GR'}, 
    {name: 'Greenland', code: 'GL'}, 
    {name: 'Grenada', code: 'GD'}, 
    {name: 'Guadeloupe', code: 'GP'}, 
    {name: 'Guam', code: 'GU'}, 
    {name: 'Guatemala', code: 'GT'}, 
    {name: 'Guernsey', code: 'GG'}, 
    {name: 'Guinea', code: 'GN'}, 
    {name: 'Guinea-Bissau', code: 'GW'}, 
    {name: 'Guyana', code: 'GY'}, 
    {name: 'Haiti', code: 'HT'}, 
    {name: 'Heard Island and Mcdonald Islands', code: 'HM'}, 
    {name: 'Holy See (Vatican City State)', code: 'VA'}, 
    {name: 'Honduras', code: 'HN'}, 
    {name: 'Hong Kong', code: 'HK'}, 
    {name: 'Hungary', code: 'HU'}, 
    {name: 'Iceland', code: 'IS'}, 
    {name: 'India', code: 'IN'}, 
    {name: 'Indonesia', code: 'ID'}, 
    {name: 'Iran, Islamic Republic Of', code: 'IR'}, 
    {name: 'Iraq', code: 'IQ'}, 
    {name: 'Ireland', code: 'IE'}, 
    {name: 'Isle of Man', code: 'IM'}, 
    {name: 'Israel', code: 'IL'}, 
    {name: 'Italy', code: 'IT'}, 
    {name: 'Jamaica', code: 'JM'}, 
    {name: 'Japan', code: 'JP'}, 
    {name: 'Jersey', code: 'JE'}, 
    {name: 'Jordan', code: 'JO'}, 
    {name: 'Kazakhstan', code: 'KZ'}, 
    {name: 'Kenya', code: 'KE'}, 
    {name: 'Kiribati', code: 'KI'}, 
    {name: 'Korea, Democratic People\'S Republic of', code: 'KP'}, 
    {name: 'Korea, Republic of', code: 'KR'}, 
    {name: 'Kuwait', code: 'KW'}, 
    {name: 'Kyrgyzstan', code: 'KG'}, 
    {name: 'Lao People\'S Democratic Republic', code: 'LA'}, 
    {name: 'Latvia', code: 'LV'}, 
    {name: 'Lebanon', code: 'LB'}, 
    {name: 'Lesotho', code: 'LS'}, 
    {name: 'Liberia', code: 'LR'}, 
    {name: 'Libyan Arab Jamahiriya', code: 'LY'}, 
    {name: 'Liechtenstein', code: 'LI'}, 
    {name: 'Lithuania', code: 'LT'}, 
    {name: 'Luxembourg', code: 'LU'}, 
    {name: 'Macao', code: 'MO'}, 
    {name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK'}, 
    {name: 'Madagascar', code: 'MG'}, 
    {name: 'Malawi', code: 'MW'}, 
    {name: 'Malaysia', code: 'MY'}, 
    {name: 'Maldives', code: 'MV'}, 
    {name: 'Mali', code: 'ML'}, 
    {name: 'Malta', code: 'MT'}, 
    {name: 'Marshall Islands', code: 'MH'}, 
    {name: 'Martinique', code: 'MQ'}, 
    {name: 'Mauritania', code: 'MR'}, 
    {name: 'Mauritius', code: 'MU'}, 
    {name: 'Mayotte', code: 'YT'}, 
    {name: 'Mexico', code: 'MX'}, 
    {name: 'Micronesia, Federated States of', code: 'FM'}, 
    {name: 'Moldova, Republic of', code: 'MD'}, 
    {name: 'Monaco', code: 'MC'}, 
    {name: 'Mongolia', code: 'MN'}, 
    {name: 'Montserrat', code: 'MS'}, 
    {name: 'Morocco', code: 'MA'}, 
    {name: 'Mozambique', code: 'MZ'}, 
    {name: 'Myanmar', code: 'MM'}, 
    {name: 'Namibia', code: 'NA'}, 
    {name: 'Nauru', code: 'NR'}, 
    {name: 'Nepal', code: 'NP'}, 
    {name: 'Netherlands', code: 'NL'}, 
    {name: 'Netherlands Antilles', code: 'AN'}, 
    {name: 'New Caledonia', code: 'NC'}, 
    {name: 'New Zealand', code: 'NZ'}, 
    {name: 'Nicaragua', code: 'NI'}, 
    {name: 'Niger', code: 'NE'}, 
    {name: 'Nigeria', code: 'NG'}, 
    {name: 'Niue', code: 'NU'}, 
    {name: 'Norfolk Island', code: 'NF'}, 
    {name: 'Northern Mariana Islands', code: 'MP'}, 
    {name: 'Norway', code: 'NO'}, 
    {name: 'Oman', code: 'OM'}, 
    {name: 'Pakistan', code: 'PK'}, 
    {name: 'Palau', code: 'PW'}, 
    {name: 'Palestinian Territory, Occupied', code: 'PS'}, 
    {name: 'Panama', code: 'PA'}, 
    {name: 'Papua New Guinea', code: 'PG'}, 
    {name: 'Paraguay', code: 'PY'}, 
    {name: 'Peru', code: 'PE'}, 
    {name: 'Philippines', code: 'PH'}, 
    {name: 'Pitcairn', code: 'PN'}, 
    {name: 'Poland', code: 'PL'}, 
    {name: 'Portugal', code: 'PT'}, 
    {name: 'Puerto Rico', code: 'PR'}, 
    {name: 'Qatar', code: 'QA'}, 
    {name: 'Reunion', code: 'RE'}, 
    {name: 'Romania', code: 'RO'}, 
    {name: 'Russian Federation', code: 'RU'}, 
    {name: 'RWANDA', code: 'RW'}, 
    {name: 'Saint Helena', code: 'SH'}, 
    {name: 'Saint Kitts and Nevis', code: 'KN'}, 
    {name: 'Saint Lucia', code: 'LC'}, 
    {name: 'Saint Pierre and Miquelon', code: 'PM'}, 
    {name: 'Saint Vincent and the Grenadines', code: 'VC'}, 
    {name: 'Samoa', code: 'WS'}, 
    {name: 'San Marino', code: 'SM'}, 
    {name: 'Sao Tome and Principe', code: 'ST'}, 
    {name: 'Saudi Arabia', code: 'SA'}, 
    {name: 'Senegal', code: 'SN'}, 
    {name: 'Serbia and Montenegro', code: 'CS'}, 
    {name: 'Seychelles', code: 'SC'}, 
    {name: 'Sierra Leone', code: 'SL'}, 
    {name: 'Singapore', code: 'SG'}, 
    {name: 'Slovakia', code: 'SK'}, 
    {name: 'Slovenia', code: 'SI'}, 
    {name: 'Solomon Islands', code: 'SB'}, 
    {name: 'Somalia', code: 'SO'}, 
    {name: 'South Africa', code: 'ZA'}, 
    {name: 'South Georgia and the South Sandwich Islands', code: 'GS'}, 
    {name: 'Spain', code: 'ES'}, 
    {name: 'Sri Lanka', code: 'LK'}, 
    {name: 'Sudan', code: 'SD'}, 
    {name: 'Suriname', code: 'SR'}, 
    {name: 'Svalbard and Jan Mayen', code: 'SJ'}, 
    {name: 'Swaziland', code: 'SZ'}, 
    {name: 'Sweden', code: 'SE'}, 
    {name: 'Switzerland', code: 'CH'}, 
    {name: 'Syrian Arab Republic', code: 'SY'}, 
    {name: 'Taiwan, Province of China', code: 'TW'}, 
    {name: 'Tajikistan', code: 'TJ'}, 
    {name: 'Tanzania, United Republic of', code: 'TZ'}, 
    {name: 'Thailand', code: 'TH'}, 
    {name: 'Timor-Leste', code: 'TL'}, 
    {name: 'Togo', code: 'TG'}, 
    {name: 'Tokelau', code: 'TK'}, 
    {name: 'Tonga', code: 'TO'}, 
    {name: 'Trinidad and Tobago', code: 'TT'}, 
    {name: 'Tunisia', code: 'TN'}, 
    {name: 'Turkey', code: 'TR'}, 
    {name: 'Turkmenistan', code: 'TM'}, 
    {name: 'Turks and Caicos Islands', code: 'TC'}, 
    {name: 'Tuvalu', code: 'TV'}, 
    {name: 'Uganda', code: 'UG'}, 
    {name: 'Ukraine', code: 'UA'}, 
    {name: 'United Arab Emirates', code: 'AE'}, 
    {name: 'United Kingdom', code: 'GB'}, 
    {name: 'United States', code: 'US'}, 
    {name: 'United States Minor Outlying Islands', code: 'UM'}, 
    {name: 'Uruguay', code: 'UY'}, 
    {name: 'Uzbekistan', code: 'UZ'}, 
    {name: 'Vanuatu', code: 'VU'}, 
    {name: 'Venezuela', code: 'VE'}, 
    {name: 'Viet Nam', code: 'VN'}, 
    {name: 'Virgin Islands, British', code: 'VG'}, 
    {name: 'Virgin Islands, U.S.', code: 'VI'}, 
    {name: 'Wallis and Futuna', code: 'WF'}, 
    {name: 'Western Sahara', code: 'EH'}, 
    {name: 'Yemen', code: 'YE'}, 
    {name: 'Zambia', code: 'ZM'}, 
    {name: 'Zimbabwe', code: 'ZW'} 
]