import React from "react";
import OtherNavbar from "components/Navbars/OtherNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import Path from "components/Path.js";
import { useTranslation, Trans } from "react-i18next";
import RecruitingStep from "../components/RecruitingStep";
import { Button } from "reactstrap";
import Process from "components/Process.js";
function Recruiting() {
  const { t } = useTranslation();
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <OtherNavbar />
      <div className="wrapper">
        <div className="main">
          <Path
            firstPathTitle={t("홈")}
            firstPath="/"
            secondPathTitle={t("인재영입")}
            secondPath="/culture"
            thirdPathTitle={t("채용절차")}
          />
          <div
            className="recruiting_img_section"
            style={{ position: "relative" }}
          >
            <img alt="" src={require("assets/img/recruiting_bg.jpg")} />
            <div className="about-transparent"></div>
          </div>
          <div className="about-detail recruiting_section">
            <div className="about-detail-section">
              <p className="about-detail-title">{t("채용절차")}</p>
              <p className="about-detail-desc recruiting_desc bottom-0">
                <Trans i18nKey="유라이크코리아는 신규 서비스 연구개발 및 글로벌 사업 확장에 따라 수시 채용하고 있습니다.">
                  유라이크코리아는 신규 서비스 연구개발 및 글로벌 사업 확장에
                  따라 <strong>수시 채용</strong>하고 있습니다.
                </Trans>
              </p>
              <p className="about-detail-desc recruiting_desc">
                {t(
                  "불필요한 스펙보다는 개인의 경험과 역량을 평가하여 다양한 구성원을 영입하고 있습니다"
                )}
              </p>
              <p className="about-detail-desc recruiting_desc">
                {t("유라이크코리아와 함께 성장하실 귀한 인재를 모십니다.")}
              </p>
              <p className="about-detail-desc recruiting_desc_mobile">
                {t("about_detail_desc_mobile")}
              </p>
            </div>
          </div>
          <div className="recuriting_img">
            <div className="recuriting_img_web">
              <Process name={t("입사지원서 접수")} imgSrc={"process-01.svg"} />
              <div>
                <img
                  alt=""
                  src={require("assets/img/process-arrow.svg")}
                  className="process_arrow"
                />
              </div>
              <Process name={t("서류 전형")} imgSrc={"process-02.svg"} />
              <div>
                <img
                  alt=""
                  src={require("assets/img/process-arrow.svg")}
                  className="process_arrow"
                />
              </div>
              <Process
                name={t("1차 면접 (실무/임원 면접)")}
                imgSrc={"process-03.svg"}
              />
              <div>
                <img
                  alt=""
                  src={require("assets/img/process-arrow.svg")}
                  className="process_arrow"
                />
              </div>
              <Process
                name={t("2차 면접 (최종 면접)")}
                imgSrc={"process-04.svg"}
              />
              <div>
                <img
                  alt=""
                  src={require("assets/img/process-arrow.svg")}
                  className="process_arrow"
                />
              </div>
              <Process name={t("최종합격 발표")} imgSrc={"process-05.svg"} />
            </div>
            <div className="recuriting_img_mobile">
              <Process
                name={t("입사지원서 접수")}
                mobile={true}
                imgSrc={"process-01.svg"}
              />
              <div className="step_arrow">
                <img
                  alt=""
                  src={require("assets/img/process-arrow-down.svg")}
                  className="process_arrow"
                />
              </div>
              <Process
                name={t("서류 전형")}
                mobile={true}
                imgSrc={"process-02.svg"}
              />
              <div className="step_arrow">
                <img
                  alt=""
                  src={require("assets/img/process-arrow-down.svg")}
                  className="process_arrow"
                />
              </div>
              <Process
                name={t("1차 면접 (실무/임원 면접)")}
                mobile={true}
                imgSrc={"process-03.svg"}
              />
              <div className="step_arrow">
                <img
                  alt=""
                  src={require("assets/img/process-arrow-down.svg")}
                  className="process_arrow"
                />
              </div>
              <Process
                name={t("2차 면접 (최종 면접)")}
                mobile={true}
                imgSrc={"process-04.svg"}
              />
              <div className="step_arrow">
                <img
                  alt=""
                  src={require("assets/img/process-arrow-down.svg")}
                  className="process_arrow"
                />
              </div>
              <Process
                name={t("최종합격 발표")}
                mobile={true}
                imgSrc={"process-05.svg"}
              />
            </div>
          </div>

          <div className="culture_section recruiting_step_part" style={{paddingBottom:'0px'}}>
            <div>
              <RecruitingStep
                stepNo={t("First")}
                stepTitle={t("입사지원서 접수")}
                stepDesc={t("entry_support")}
                stepDesc2={t("entry_support2")}
                stepResume={t("entry_resume")}
                stepDetail={[
                  t(
                    "* 일부 직군(서비스 기획, 디자인, 개발) 또는 경력직의 경우 포트폴리오 제출 필수"
                  ),
                ]}
              />
              <RecruitingStep
                stepNo={t("Second")}
                stepTitle={t("서류 전형")}
                stepDesc={t(
                  "입사지원서와 자기소개서를 토대로 내부 서류 전형을 진행합니다. 서류 통과할 경우 개별 연락하여 1차 면접일정을 안내 드립니다."
                )}
              />
              <RecruitingStep
                stepNo={t("Third")}
                stepTitle={t("1차 면접(실무/임원 면접)")}
                stepDesc={t(
                  "지원 부서의 실무 팀장 및 임원진이 진행하며, 인성, 산업 이해도 및 실무역량 등을 종합 평가합니다."
                )}
              />
              <RecruitingStep
                stepNo={t("Fourth")}
                stepTitle={t("2차 면접(최종 면접)")}
                stepDesc={t(
                  "1차 면접 합격자 대상으로 대표 면접을 통해 최종 합격 여부를 결정합니다."
                )}
              />
              <RecruitingStep
                noBorder={true}
                stepNo={t("Fifth")}
                stepTitle={t("최종합격 발표")}
                stepDesc={t(
                  "최종합격 여부 발표 후, 확인 절차를 거쳐 입사절차를 안내합니다."
                )}
              />
            </div>
          </div>
          {/* <div className="rec_feature_bg">
            <img src={require("assets/img/features_bg.jpg")} alt="" />
            <img className="recImg" src={require("assets/img/newMember2.jpg")} alt="" />
          </div> */}
          <div className="culture_section rec_description">
            <div>
              <p className="rec_desc_text">
                {t(
                  "글로벌 경쟁력을 가진 바이오전문 기업 유라이크코리아와 함께 세계로 뻗어나갈 유능한 동료를 환영합니다."
                )}
              </p>
            </div>
            <div className="rec_desc_btn_section">
              {/* <Button
                className="rec_btn"
                onClick={() =>
                  window.open("https://www.wanted.co.kr/company/745", "_blank")
                }
              >
                {t("현재 진행 중인 채용공고 확인")}
              </Button> */}
              <Button
                className="rec_btn"
                onClick={() =>
                  window.open(
                    "https://ulikekorea.recruiter.co.kr/appsite/company/index",
                    "_blank"
                  )
                }
              >
                {t("직접 지원하기")}
              </Button>
              {/* <Button
                className="rec_btn"
                onClick={() =>
                  window.open("https://www.wanted.co.kr/company/745", "_blank")
                }
              >
                {t("WANTED로 지원하기")}
              </Button> */}
            </div>
          </div>
        </div>
        <DarkFooter />
      </div>
    </>
  );
}

export default Recruiting;
