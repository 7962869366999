import React from "react";
import OtherNavbar from "components/Navbars/OtherNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import Path from "components/Path.js";
import { useTranslation } from 'react-i18next'
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
function Help() {
  const { t } = useTranslation()
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <OtherNavbar />
      <div className="wrapper">
        <div className="main">
          <Path firstPathTitle={t('홈')} firstPath="/" secondPathTitle={t('고객지원')}/>
          <div className="culture_section culture_work help_section">
              <p className="subtitle customer_service">{t('고객지원')}</p>
              <p className="description top_desc">{t('유라이크코리아는 한 분, 한 분의 의견을 소중히 생각합니다.')}</p>
              <p className="description bottom_desc">{t('서비스 문의 및 사업 제휴 등 보내주시는 문의사항은 메일이나 전화로 최대한 신속히 답변드리겠습니다.1')}</p>
          </div>
          
          <div className="service_alliance_section" style={{position: 'relative'}}>
            <img alt="" src={require("assets/img/help_bg1.jpg")} />  
            <div className="about-healthy service_alliance">
              <div className="about-healthy-part help_part">
                <p className="about-healthy-title help_title">{t('서비스 문의')}</p>
                <p className="about-healthy-desc help_desc">{t("신규 서비스 도입에 관심이 있거나, 서비스 이용 중 궁금하신 점이 있으시면 '서비스 문의하기'를 통해 연락 부탁드립니다.")}</p>
                <Button className="rec_btn" to={"/helpservice"} tag={Link}>{t('서비스 문의하기')}</Button>
              </div>
            </div>
          </div>
          <div className="service_alliance_section" style={{position: 'relative'}}>
            <img alt="" src={require("assets/img/help_bg2.jpg")} />  
            <div className="about-healthy service_alliance">
              <div className="about-healthy-part help_part">
                <p className="about-healthy-title help_title">{t('제휴문의')}</p>
                <p className="about-healthy-desc help_desc">{t("유라이크코리아 서비스 관련 사업 제휴 및 투자 관련 문의는 '제휴 문의하기'를 통해 연락 부탁드립니다.")}</p>
                <Button className="rec_btn" to={"/helppartner"} tag={Link}>{t('제휴 문의하기')}</Button>
              </div>
            </div>
          </div>
          <div className="help_last_section">

          </div>
        </div>
        <DarkFooter />
      </div>
    </>
  );
}

export default Help;