import React from "react";
import { VerticalTimelineElement }  from 'react-vertical-timeline-component';

function CultureType(props) {
  return (

    <>
    {
      
        props.img && props.img ? 
          <div className={props.is_first ? "timeline_first month_line vertical-timeline-element" : (props.line ? "vertical-timeline-element--work month_line vertical-timeline-element" : "vertical-timeline-element--work no_month vertical-timeline-element" ) } > 
          <span class="vertical-timeline-element-icon bounce-in" style={{ top: '8px', background: '#0b386e', color: '#0b386e', width: 22, height: 22, boxShadow: 'none', left: 12}}></span>
          <div class="vertical-timeline-element-content bounce-in" style={{color: 'rgb(51, 51, 51)',  padding: 0, boxShadow: 'none'}}>
            <div class="vertical-timeline-element-content-arrow" style={{display: 'none'}}></div>
            <p class="history_month">{props.month}</p>
            <img src={props.img} className="historyImg" />
            <p class="history_month_detail" style={{position: 'relative', top:'22px'}}>{props.detail}</p>
            <p class="history_month_detail">{props.detail1}</p>
            <p class="history_month_detail">{props.detail2}</p>
            <p class="history_month_detail">{props.detail3}</p>
            <p class="history_month_detail">{props.detail4}</p>
             
            <span class="vertical-timeline-element-date"></span>
          </div>
        </div>  
        : 
        <div className={props.is_first ? "timeline_first dd month_line  vertical-timeline-element" : (props.line ? "vertical-timeline-element--work  dd month_line vertical-timeline-element" : "vertical-timeline-element--work no_month vertical-timeline-element" ) } > 
          <span class="vertical-timeline-element-icon bounce-in" style={{ top: '8px', background: '#0b386e', color: '#0b386e', width: 22, height: 22, boxShadow: 'none', left: 12}}></span>
          <div class="vertical-timeline-element-content bounce-in" style={{color: 'rgb(51, 51, 51)',  padding: 0, boxShadow: 'none'}}>
            <div class="vertical-timeline-element-content-arrow" style={{display: 'none'}}></div>
            <p class="history_month">{props.month}</p>
            <p class="history_month_detail">{props.detail}</p>
            <p class="history_month_detail">{props.detail1}</p>
            <p class="history_month_detail">{props.detail2}</p>
            <p class="history_month_detail">{props.detail3}</p>
            <p class="history_month_detail">{props.detail4}</p>
           
            <span class="vertical-timeline-element-date"></span>
          </div>
    </div>
      
    }

  </>


    
  );
}

export default CultureType;
