import React from "react";

function WorkBenefit(props) {
  return (
      <div className="work_benefit_section">
          <div className="work_benefit_img">
              <img alt="" src={require("assets/img/"+props.imgSrc)} />
          </div>
          <div>
            <p className="work_benefit_text">{props.title}</p>
            <p className="work_benefit_text work_benefit_desc">{props.description}</p>
          </div>
      </div>
  );
}

export default WorkBenefit;
