import React, { useEffect } from "react";
import { useHistory,Link  } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import LinesEllipsis from 'react-lines-ellipsis'
import { useDispatch } from "react-redux";
import {advtsDetailAction} from '../actions/advtsAction'

function NewsCard(props) {

  
  const history = useHistory();
  const { t } = useTranslation()
  return (
      <Link 
        to={{
          pathname: `/advtsdetail/${props.no}`,
          state:{
            pressD :props
          }
        }}
        className="news-card-section"
        style={{textDecoration:'none'}}   >
        
        {
          props.imgSrc ?
          <div className="pr_card_image">
            <img alt="" src={props.imgSrc} />
          </div>
          :
          <div>
            <img alt="" src={require("assets/img/news"+(props.number % 3 === 0 ? 3 : props.number % 3)+".png")} />
          </div>
        }

          <div className="news-desc-section">
              <LinesEllipsis className="news-title" text={props.title} maxLine="3" ellipsis="..." trimRight basedOn='letters' />
              <div>
                <span>{t('자세히 보기')}</span>
                <i className="now-ui-icons arrows-1_minimal-right"></i>
              </div>
          </div>
        </Link>
  );
}

export default NewsCard;
