import React, { useEffect } from "react";
import ManagerNavbar from "../../../components/Navbars/ManagerNavbar";
import Aside from "../../../components/Navbars/Aside";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import Axios from "axios";

const NewCertificate = (props) => {
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm();

  const [typetitle, setTypetitle] = React.useState("");
  const [typename, setTypename] = React.useState("");

  const [typeSelect, setTypeSelect] = React.useState("P");

  useEffect(() => {
    setTypeSelect("P");
  }, [typeSelect]);

  const onchangeType = (e) => {
    setTypeSelect(e.target.value);

    if (e.target.value === "P") {
      setTypetitle("국가");
      setTypename("특허명");
    } else if (e.target.value === "C") {
      setTypetitle("기고지");
      setTypename("논문명");
    } else if (e.target.value === "I") {
      setTypetitle("인증기관");
      setTypename("인증명");
    } else if (e.target.value === "L") {
      setTypetitle("국가");
      setTypename("상표명");
    }
  };

  useEffect(() => {
    let islogin = JSON.parse(localStorage.getItem("name"));
    if (islogin !== null) {
    } else {
      history.push("/manage");
    }
    setTypename("특허명");
    setTypetitle("국가");

   
  }, [history]);

  const onSubmit = (data) => {
    const isnull = data.name_kr;

    if (data.name_kr === null) {
      alert("no");
    }

    Axios({
      method: "post",
      url: "https://livecare.io:441/certificate?" + data,
      data: {
        type: data.type,
        name_kr: data.name_kr,
        name_en: data.name_en,
        name_pt: data.name_pt,
        name_jp: data.name_jp,
        category_kr: data.category_kr,
        category_en: data.category_en,
        category_pt: data.category_pt,
        category_jp: data.category_jp,
        date_certificate_kr: data.date_certificate_kr,
        date_certificate_en: data.date_certificate_en,
        date_certificate_pt: data.date_certificate_pt,
        date_certificate_jp: data.date_certificate_jp,
        date_create: data.date_create,
        link: data.link,
      },
    })
      .then((response) => {
        return history.push("/manager/adminCertificate");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <ManagerNavbar />
      <div style={{ display: "flex" }}>
        <Aside></Aside>
        <div
          style={{ backgroundColor: "#EEF1F3", width: "100%", height: "120vh" }}
        >
          <div
            style={{
              width: "80%",
              margin: "0 auto",
              position: "relative",
              top: "150px",
            }}
          >
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div
                className="newcertiTwoBox"
                style={{ flexDirection: "column", marginBottom: "10px" }}
              >
                <label>{"타입"}</label>
                <select
                  className="form-control"
                  name="type"
                  ref={register({ required: true })}
                  style={{ width: "99%", borderRadius: "5px" }}
                  onChange={onchangeType}
                >
                  <option value="P">{"특허"}</option>
                  <option value="C">{"논문"}</option>
                  <option value="I">{"인증"}</option>
                  <option value="L">{"상표"}</option>
                </select>
              </div>

              <div className="newcertiBigBox">
                <div className="cerSmallInput">
                  <label>{typename}(국문)</label>
                  <textarea
                    className="form-control certiEdit"
                    type="text"
                    name="name_kr"
                    ref={register({
                      required: true,
                    })}
                    placeholder="개체 정보 획득 시스템 및 방법"
                  />
                  {errors.name_kr && (
                    <p className="formError">값을 입력해주세요</p>
                  )}
                </div>
                <div className="cerSmallInput">
                  <label>{typename}(영문)</label>
                  <textarea
                    className="form-control certiEdit"
                    type="text"
                    name="name_en"
                    ref={register({
                      required: true,
                    })}
                  />
                  {errors.name_en && (
                    <p className="formError">값을 입력해주세요</p>
                  )}
                </div>

                <div className="cerSmallInput">
                  <label>{typename}(포어)</label>
                  <textarea
                    className="form-control certiEdit"
                    type="text"
                    name="name_pt"
                    ref={register({
                      required: true,
                    })}
                  />
                  {errors.name_pt && (
                    <p className="formError">값을 입력해주세요</p>
                  )}
                </div>
                <div className="cerSmallInput">
                  <label>{typename}(일문)</label>
                  <textarea
                    className="form-control certiEdit"
                    type="text"
                    name="name_jp"
                    ref={register({
                      required: true,
                    })}
                  />
                  {errors.name_jp && (
                    <p className="formError">값을 입력해주세요</p>
                  )}
                </div>
              </div>

              <div className="newcertiBigBox">
                <div className="cerSmallInput">
                  <label>{typetitle}(국문)</label>
                  <textarea
                    className="form-control certiEditSmall"
                    type="text"
                    name="category_kr"
                    ref={register({
                      required: true,
                    })}
                    placeholder="대한민국, 대한민국, 농림축산검역본부 "
                  />
                  {errors.category_kr && (
                    <p className="formError">값을 입력해주세요</p>
                  )}
                </div>

                <div className="cerSmallInput">
                  <label>{typetitle}(영문)</label>
                  <textarea
                    className="form-control certiEditSmall"
                    type="text"
                    name="category_en"
                    ref={register({
                      required: true,
                    })}
                  />
                  {errors.category_en && (
                    <p className="formError">값을 입력해주세요</p>
                  )}
                </div>
                <div className="cerSmallInput">
                  <label>{typetitle}(포어)</label>
                  <textarea
                    className="form-control certiEditSmall"
                    type="text"
                    name="category_pt"
                    ref={register({
                      required: true,
                    })}
                  />
                  {errors.category_pt && (
                    <p className="formError">값을 입력해주세요</p>
                  )}
                </div>
                <div className="cerSmallInput">
                  <label>{typetitle}(일문)</label>
                  <textarea
                    className="form-control certiEditSmall"
                    type="text"
                    name="category_jp"
                    ref={register({
                      required: true,
                    })}
                  />
                  {errors.category_jp && (
                    <p className="formError">값을 입력해주세요</p>
                  )}
                </div>
              </div>

              {typeSelect === "C" ? null : (
                <div className="newcertiBigBox">
                  <div className="cerSmallInput">
                    {" "}
                    <label>번호(국문)</label>
                    <textarea
                      className="form-control certiEditSmall"
                      type="text"
                      name="date_certificate_kr"
                      ref={register({
                        required: true,
                      })}
                      placeholder="제10-2122578호, 913806501"
                    />
                    {errors.date_certificate_kr && (
                      <p className="formError">값을 입력해주세요</p>
                    )}
                  </div>
                  <div className="cerSmallInput">
                    <label>번호(영문)</label>
                    <textarea
                      className="form-control certiEditSmall"
                      type="text"
                      name="date_certificate_en"
                      ref={register({
                        required: true,
                      })}
                    />
                    {errors.date_certificate_en && (
                      <p className="formError">값을 입력해주세요</p>
                    )}
                  </div>

                  <div className="cerSmallInput">
                    <label>번호(포어)</label>
                    <textarea
                      className="form-control certiEditSmall"
                      type="text"
                      name="date_certificate_pt"
                      ref={register({
                        required: true,
                      })}
                    />
                    {errors.date_certificate_pt && (
                      <p className="formError">값을 입력해주세요</p>
                    )}
                  </div>
                  <div className="cerSmallInput">
                    <label>번호(일문)</label>
                    <textarea
                      className="form-control certiEditSmall"
                      type="text"
                      name="date_certificate_jp"
                      ref={register({
                        required: true,
                      })}
                    />
                    {errors.date_certificate_jp && (
                      <p className="formError">값을 입력해주세요</p>
                    )}
                  </div>
                </div>
              )}
              <div className="newcertiBigBox">
                <div className="cerSmallInput">
                  <label>{"등록일자"}</label>
                  <input
                    className="form-control certiEditDate"
                    type="date"
                    name="date_create"
                    ref={register({
                      required: true,
                    })}
                    placeholder="2020.10.30, 2020"
                  />
                  {errors.date_create && (
                    <p className="formError">값을 입력해주세요</p>
                  )}
                </div>
              </div>

              {typeSelect === "C" ? (
                <div className="newcertiBigBox">
                  <div className="cerSmallInput">
                    <label>{"링크"}</label>
                    <textarea
                      className="form-control certiEditSmall"
                      type="text"
                      name="link"
                      ref={register}
                      placeholder="www.naver.com"
                    />
                  </div>
                </div>
              ) : null}

              <div className="submitBtn">
                <button
                  className="form-control"
                  style={{ borderRadius: "5px" }}
                >
                  제출
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewCertificate;
