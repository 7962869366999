import React, { useEffect } from "react";
import { stock_get,stock_total } from "../../api";
import StockTable from '../Stock/Sections/StockTable'
import ManagerNavbar from "../../components/Navbars/ManagerNavbar";
import Aside from "../../components/Navbars/Aside";
import { useHistory } from "react-router-dom";

const AdminStock = () => {
  const history = useHistory();
  const [stockList, setStockList] = React.useState([]);
  const [stockTotal, setStockTotal] = React.useState("1");
  useEffect(() => {
    let islogin = JSON.parse(localStorage.getItem("name"));
    if (islogin !== null) {
      console.log("Y");
    } else {
      history.push("/manage");
    }
    fetchData();
    fetchTotal();
  }, [history]);

  const fetchData = async () => {
    const response = await stock_get(1, 15);
    if (response.result === "200") {
        setStockList(response.data);
    }
  };

  const fetchTotal = async () => {
    const response = await stock_total();
    if (response.result === "200") {
      if (response.data.length > 0) {
        setStockTotal(Math.ceil(response.data.length / 15));
      }
    }
  };

  return (
    <>
      <ManagerNavbar />
      <div style={{ display: "flex" }}>
        <Aside></Aside>
        <StockTable data={stockList} total={stockTotal} />
      </div>
    </>
  );
};

export default AdminStock;
