import React from "react";
import { Container, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
function Path(props) {
  return (
    <>
      <div className="path-section">
        <Container>
            {
                props.firstPath ?
                <div className="path">
                    <NavLink to={props.firstPath} tag={Link}>
                      <span>{props.firstPathTitle}</span>
                    </NavLink>
                    {
                        props.secondPathTitle ?
                        <div className="second_path">
                            <i className="now-ui-icons arrows-1_minimal-right"></i>
                            {
                              props.secondPath || props.secondPath === '' ?
                              <NavLink to={props.secondPath ? props.secondPath : ''} tag={Link}>
                                <span>{props.secondPathTitle}</span>
                              </NavLink>
                              :
                              <span>{props.secondPathTitle}</span>
                            }
                        </div>
                        :
                        null
                    }
                    {
                        props.thirdPathTitle ?
                        <div className="second_path">
                            <i className="now-ui-icons arrows-1_minimal-right"></i>
                            {
                              props.thirdPath ?
                              <NavLink to={props.thirdPath ? props.thirdPath : ''} tag={Link}>
                                <span>{props.thirdPathTitle}</span>
                              </NavLink>
                              :
                              <span>{props.thirdPathTitle}</span>
                            }
                        </div>
                        :
                        null
                    }
                </div>
                :
                null
            }
        </Container>
      </div>
     
    </>
  );
}

export default Path;