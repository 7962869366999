import React, { useEffect } from "react";
import ManagerNavbar from "../../components/Navbars/ManagerNavbar";
import Aside from "../../components/Navbars/Aside";
import CertificateTable from "./Sections/CertificateTable";
import { certificate_get, certificate_total } from "../../api";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

import { useHistory, Link } from "react-router-dom";

const AdminCertificate = () => {
  const history = useHistory();

  const [pills, setPills] = React.useState("1");
  const [patentPList, setPatentPList] = React.useState([]);
  const [patentCList, setPatentCList] = React.useState([]);
  const [patentIList, setPatentIList] = React.useState([]);
  const [patentLList, setPatentLList] = React.useState([]);
  const [patentPTotal, setPatentPTotal] = React.useState("1");
  const [patentCTotal, setPatentCTotal] = React.useState("1");
  const [patentITotal, setPatentITotal] = React.useState("1");
  const [patentLTotal, setPatentLTotal] = React.useState("1");

  useEffect(() => {
    let islogin = JSON.parse(localStorage.getItem("name"));
    if (islogin !== null) {
    } else {
      history.push("/manage");
    }
    fetchData("P");
    fetchTotal("P");
    fetchData("C");
    fetchTotal("C");
    fetchData("I");
    fetchTotal("I");
    fetchData("L");
    fetchTotal("L");
  }, [history]);

  const fetchData = async (type) => {
    const response = await certificate_get(type, 1, 15);
    if (response.result === "200") {
      if (response.data.length > 0) {
        let temp = [];
        response.data.map((detail) => {
          if (detail.type.replace(/\s/g, "") === type) {
            temp.push(detail);
          }
        });
        if (type === "P") setPatentPList(temp);
        else if (type === "C") setPatentCList(temp);
        else if (type === "I") setPatentIList(temp);
        else if (type === "L") setPatentLList(temp);
      }
    }
  };

  const fetchTotal = async (type) => {
    const response = await certificate_total(type);
    if (response.result === "200") {
      if (response.data.length > 0) {
        if (type === "P") setPatentPTotal(Math.ceil(response.data.length / 15));
        else if (type === "C")
          setPatentCTotal(Math.ceil(response.data.length / 15));
        else if (type === "I")
          setPatentITotal(Math.ceil(response.data.length / 15));
        else if (type === "L")
          setPatentLTotal(Math.ceil(response.data.length / 15));
      }
    }
  };

  return (
    <>
      <ManagerNavbar />
      <div style={{ display: "flex" }}>
        <Aside></Aside>

        <div style={{ width: "100%" }}>
          <div style={{ fontSize: "20px", margin: "0 auto", width: "80%" }}>
            <span className="certificateSubTitle">연구특허</span>
          </div>

          <Nav
            style={{ position: "relative", top: "150px" }}
            className="nav-tabs-neutral justify-content-center patent_tab"
            data-background-color="blue"
            role="tablist"
            tabs
          >
            <NavItem>
              <NavLink
                className={pills === "1" ? "active" : ""}
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  setPills("1");
                }}
              >
                {"특허"}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={pills === "2" ? "active" : ""}
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  setPills("2");
                }}
              >
                {"논문"}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={pills === "3" ? "active" : ""}
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  setPills("3");
                }}
              >
                {"인증"}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={pills === "4" ? "active" : ""}
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  setPills("4");
                }}
              >
                {"상표"}
              </NavLink>
            </NavItem>
          </Nav>


          <TabContent
            className="text-center patent_table"
            activeTab={"pills" + pills}
          >
            <TabPane tabId="pills1">
              {patentPList.length > 0 ? (
                <CertificateTable
                  cate1={"특허명"}
                  cate2={"국가"}
                  cate3={"번호"}
                  cate4={"일자"}
                  data={patentPList}
                  total={patentPTotal}
                  type="P"
                />
              ) : null}
            </TabPane>
            <TabPane tabId="pills2">
              {patentCList.length > 0 ? (
                <CertificateTable
                  cate1={"눈문명"}
                  cate2={"기고지"}
                  cate3={"연도"}
                  cate4={"일자"}
                  cate5={"링크"}
                  data={patentCList}
                  total={patentCTotal}
                  type="C"
                />
              ) : null}
            </TabPane>
            <TabPane tabId="pills3">
              {patentIList.length > 0 ? (
                <CertificateTable
                  cate1={"인증명"}
                  cate2={"인증기관"}
                  cate3={"번호"}
                  cate4={"일자"}
                  data={patentIList}
                  total={patentITotal}
                  type="I"
                />
              ) : null}
            </TabPane>
            <TabPane tabId="pills4">
              {patentLList.length > 0 ? (
                <CertificateTable
                  cate1={"상표명"}
                  cate2={"국가"}
                  cate3={"번호"}
                  cate4={"일자"}
                  data={patentLList}
                  total={patentLTotal}
                  type="L"
                />
              ) : null}
            </TabPane>
          </TabContent>

       
        </div>
      </div>
    </>
  );
};

export default AdminCertificate;
