import React from "react";
import HoverImage from "react-hover-image";
import detectBrowserLanguage from "detect-browser-language";
import { useTranslation } from 'react-i18next'
function Animal(props) {
  const { t, i18n } = useTranslation()
  let lang = i18n.language === 'ko' ? 'ko' : i18n.language === 'ja' ? 'ja' : i18n.language === 'pt' ? 'pt' : 'en'
  if(!lang)
    lang = detectBrowserLanguage().substring(0,2)
  let link = "https://livecare.xyz/lc?lang=en&flag=1"
  if(lang === 'ko')
    link = 'https://livecare.xyz/lc?lang=ko&flag=1'
  else if(lang === 'pt')
    link = 'https://livecare.xyz/lc?lang=pt&flag=1'
  else if(lang === 'ja')
    link = 'https://www.livecare.co.jp/'

  return (
    <a href={props.cattle ? link : props.link} className="animal_link" target="_blank" rel="noopener noreferrer">
      <div className="animal">
          <HoverImage className="animal_img" src={require("assets/img/"+props.imgSrc)} hoverSrc={require("assets/img/"+props.imgHoverSrc)} />
          <span className="animal_name">{props.name}</span>
      </div>
    </a>
  );
}

export default Animal;
