import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";
import { useTranslation } from 'react-i18next'
import detectBrowserLanguage from 'detect-browser-language'
function IndexNavbar() {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const { t, i18n } = useTranslation()
  const [curLang , setCurLang] = React.useState(i18n.language.substring(0,2) === 'ko' || !i18n.language.substring(0,2) && detectBrowserLanguage().substring(0,2) === 'ko' ? "KOR" : i18n.language.substring(0,2) === 'ja' || !i18n.language && detectBrowserLanguage().substring(0,2) === 'ja' ? 'JPN' : i18n.language.substring(0,2) === 'pt' || !i18n.language && detectBrowserLanguage().substring(0,2) === 'pt' ? 'POR' : 'ENG');
  return (
    <>
      <Navbar className={"fixed-top header other_header"} expand="lg">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand
              to="/" tag={Link}
              id="navbar-brand"
            >
              <img alt="" src={require("assets/img/solid--white.png")} className="header_logo" />
            </NavbarBrand>
            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
            <button
              className="navbar-toggler navbar-toggler navbar_close_btn"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <i
                  aria-hidden={true}
                  className="fa fa-times"
                  style={{color: 'white'}}
              ></i>
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
              <NavItem></NavItem>
              <NavItem></NavItem>
              <UncontrolledDropdown nav>
                <DropdownToggle
                  to="/about" tag={Link}
                  nav
                >
                  <p>{t('유라이크코리아')}</p>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem to="/about" tag={Link}>
                  {t('유라이크코리아 소개')}
                  </DropdownItem>
                  <DropdownItem to="/history" tag={Link}>
                    {t('걸어온 길')}
                  </DropdownItem>
                  <DropdownItem to="/patent" tag={Link}>
                  {t('연구/특허')}
                  </DropdownItem>
                  <DropdownItem to="/directions" tag={Link}>
                  {t('오시는 길')}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav>
                <DropdownToggle
                  to="/service" tag={Link}
                  nav
                >
                  <p>{t('서비스')}</p>
                </DropdownToggle>
                <DropdownMenu className="first_drop_menu">
                  <DropdownItem href={i18n.language == 'ko' ? "http://livecare.xyz/lc?lang=ko&flag=1" : i18n.language == 'pt' ? "http://livecare.xyz/lc?lang=pt&flag=1" : i18n.language === "ja" ? "https://www.livecare.co.jp/" : "http://livecare.xyz/lc?lang=en&flag=1"} target="_blank">
                    {t('성우')}
                  </DropdownItem>
                  <DropdownItem href={i18n.language === 'ko' ? "http://calfedition.com" : "https://en.calfedition.com" } target="_blank">
                    {t('송아지')}
                  </DropdownItem>
                  <DropdownItem href="http://sheepedition.com" target="_blank">
                    {t('양')}
                  </DropdownItem>
                  <DropdownItem href="http://pigedition.com" target="_blank">
                    {t('돼지')}
                  </DropdownItem>
                  <DropdownItem href="http://horseedition.com" target="_blank">
                    {t('말')}
                  </DropdownItem>
                  <DropdownItem href="http://chickenedition.com" target="_blank">
                    {t('닭')}
                  </DropdownItem>
                  <DropdownItem href="http://livecare.xyz" target="_blank" style={{color: '#10a849'}}>
                    {t('LiveCare')}
                  </DropdownItem>
                  <DropdownItem href="http://www.livecaretracking.com/" target="_blank" style={{color:'#0086B3'}}>
                    {t('Tracking')}
                  </DropdownItem>
                  <DropdownItem href="https://livecaremarket.com/" target="_blank" style={{color:'#0ba29a'}}>
                    {t('LivePatch')}
                  </DropdownItem>
                </DropdownMenu>
                
              </UncontrolledDropdown>
              <NavItem>
                <NavLink to="/advts" tag={Link}>
                  <p>{t('홍보')}</p>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/help" tag={Link}
                >
                  <p>{t('고객지원')}</p>
                </NavLink>
              </NavItem>
              <UncontrolledDropdown nav>
                <DropdownToggle to="/culture" tag={Link} nav >
                  <p>{t('인재영입')}</p>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem to="/culture" tag={Link}>
                    {t('조직문화')}
                  </DropdownItem>
                  <DropdownItem to="/recruiting" tag={Link}>
                    {t('채용절차')}
                  </DropdownItem>
                  <DropdownItem href="https://ulikekorea.recruiter.co.kr/appsite/company/index" target="_blank">
                    {t('지원하기')}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  href="#"
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="now-ui-icons business_globe"></i>
                  <p>{curLang}</p>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      localStorage.setItem('lang', 'ko')
                      i18n.changeLanguage('ko')
                      setCurLang('KOR')
                    }}>
                    KOR
                  </DropdownItem>
                  <DropdownItem
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      localStorage.setItem('lang', 'en')
                      i18n.changeLanguage('en')
                      setCurLang('ENG')
                    }}
                  >
                    ENG
                  </DropdownItem>
                  <DropdownItem
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      localStorage.setItem('lang', 'ja')
                      i18n.changeLanguage('ja')
                      setCurLang('JPN')
                    }}
                  >
                    JPN
                  </DropdownItem>
                  <DropdownItem
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      localStorage.setItem('lang', 'pt')
                      i18n.changeLanguage('pt')
                      setCurLang('POR')
                    }}
                  >
                    POR
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default IndexNavbar;