import React from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import DarkFooter from "components/Footers/DarkFooter.js";

import Home from "./Home.js";
import Login from "./Login";

function ManagerIndex() {

    return (
        <>
            <Login></Login>
            
        </>
    );
}

export default ManagerIndex;
