import React from "react";
import { useTranslation } from 'react-i18next'
import { Link } from "react-router-dom";
import Naverblog from '../../assets/img/IC_naverblog_ULK.png'
function DarkFooter() {
  const { t } = useTranslation()
  return (
    <>
    <footer className="footer" data-background-color="black">
      <div className="container footer_container">
        <div className="footer_section">
          <div className="footer_link_section">
            <Link to={"/"}>
              <img alt="" src={require("assets/img/solid--white.png")} className="header_logo" />
            </Link>
            <Link to={"/about"}>
              <span className="footer_menu">{t('회사 소개')}</span>
            </Link>
            <Link to="/annoucement">
              <span className="footer_menu">{t('투자정보')}</span>
            </Link>
            <Link to="/helppartner">
              <span className="footer_menu">{t('제휴문의')}</span>
            </Link>
            <Link to="/privacy">
              <span className="footer_menu">{t('개인정보처리방침')}</span>
            </Link>
          </div>
          <div className="footer_blog">
            <div style={{flex: 1}}>

            </div>
            <div style={{flex: 1, display: 'flex', justifyContent: 'space-between'}}>
              <a href="https://www.facebook.com/livecare153" target="_blank"rel="noopener noreferrer">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="https://www.youtube.com/channel/UCnnBCHCl32Ff_T-S3FgWNSQ" target="_blank"rel="noopener noreferrer">
                <i className="fab fa-youtube"></i>
              </a>
              <a href="https://www.linkedin.com/company/ulikekorea" target="_blank"rel="noopener noreferrer">
                <i className="fab fa-linkedin-in"></i>
              </a>
              <a href="https://blog.naver.com/ulike_123" target="_blank"rel="noopener noreferrer">
                <img src={Naverblog} className="naver_blog_img" />
              </a>
            </div>
            
          </div>
        </div>
        
        <div className="footer_copyright">
          <span className="copy_access">Copyright ⓒ 2016. uLikeKorea Co., Inc. All Rights Reserved.</span>
        </div>
      </div>
    </footer>
    </>
  );
}

export default DarkFooter;