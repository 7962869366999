import React, { useEffect } from "react";
import Axios from "axios";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  Redirect,
} from "react-router-dom";
import { Form, FormGroup, Label, Input, FormText, Button } from "reactstrap";

function Login() {
  const history = useHistory();
  const { register, handleSubmit } = useForm();

  useEffect(() => {
    if (history.location.pathname === "/manage") {
      window.history.pushState(null, document.title, window.location.href);
      window.addEventListener("popstate", function (event) {
        window.history.pushState(null, document.title, window.location.href);
      });
    }
  }, [history.location.pathname]);

  const onSubmit = (data) => {
    Axios({
      method: "post",
      url:
        "https://livecare.io:441/login?" +
        "name=" +
        data.name +
        "&password=" +
        data.password,
      headers: {},

  
    })
      .then((response) => {

        localStorage.setItem(
          "name",
          JSON.stringify(response.data.data[0].name)
        );
        return history.push("/manager/home");
      })
      .catch((error) => {
        alert("아이디와 비밀번호를 확인해주세요");
        return <Redirect to="/manage" />;
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="login_form">
        <h2
          style={{ textAlign: "center", color: "#18235b", fontWeight: "700" }}
        >
          유라이크코리아 웹사이트
        </h2>
        <h3
          style={{ textAlign: "center", color: "#2e9d64", fontWeight: "600" }}
        >
          {" "}
          관리자 페이지
        </h3>
        <Label htmlFor="ID" ref={register}>
          {"아이디"}*
        </Label>
        <input
          className="form-control"
          type="text"
          name="name"
          placeholder={"아이디"}
          ref={register}
        />

        <div style={{ marginTop: "10px" }}>
          <Label htmlFor="password" ref={register}>
            {"비밀번호"}*
          </Label>
          <input
            className="form-control"
            type="password"
            name="password"
            placeholder={"비밀번호"}
            ref={register}
          />
        </div>

        <input
          className="form-control"
          style={{ marginTop: "20px" }}
          type="submit"
        />
      </form>
    </>
  );
}

export default Login;
