import React from "react";

function CultureCard(props) {
  return (
      <div className={props.mobile ? "culture_card_section_mobile" : "culture_card_section"} style={props.bottom ? { borderBottom: '1px solid #E9F8F9'} : {}}>
          <div style={props.mobile ? {} : {}}>
            <img alt="" src={require("assets/img/"+props.imgSrc)} />
          </div>
          <div className="culture_card">
            <span className="culture_card_title">{props.title}</span>
            <span className="culture_card_desc">{props.description}</span>
          </div>
      </div>
  );
}

export default CultureCard;
