import React from "react";
import OtherNavbar from "components/Navbars/OtherNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import Path from "components/Path.js";
import { useTranslation } from 'react-i18next'
import Animal from "../components/Animal";
import { Row, Col } from "reactstrap";

function Service() {
  const { t } = useTranslation()

  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <OtherNavbar />
      <div className="wrapper">
        <div className="main">
          <Path firstPathTitle={t('홈')} firstPath="/" secondPathTitle={t('서비스')}/>
          <div className="service_bg_image_section" style={{position: 'relative'}}>
            <img alt="" src={require("assets/img/service-bg.jpg")} />  
            <div className="about-healthy service_desc_section">
              <div className="about-healthy-part ">
                <p className="about-healthy-title service-title" style={{color: '#e5ecea'}}>{t('가축이 건강할 때 사람도 건강하다는 믿음으로')}</p>
                <p className="about-healthy-desc service_desc" style={{color: '#e5ecea'}}>{t('유라이크코리아는 축산학, 수의학 및 컴퓨터공학 등 전문 인력들로 구성된 연구팀을 주축으로 가축이 건강하게 자랄 수 있는 서비스를 지속적으로 연구 개발(R&D)하고 있습니다. 가축이 건강할 때, 사람도 함께 건강할 수 있다는 믿음으로 혁신적인 서비스를 제공할 수 있도록 나아가겠습니다.')}</p>
              </div>
            </div>
          </div>
          <div className="service-section">
              <div className="service-livecare">
                  <p className="livecare_title">{t('LiveCare Services')}</p>
                  <img alt="" src={require("assets/img/livecare.png")} />
              </div>
              <div style={{marginTop: 20}}>
                  <p className="about-expert-text-desc livecare_service_web">{t('2010년 런칭한 라이브케어 서비스는 인공지능 가축 헬스케어 솔루션으로 개별 가축의 생체 데이터를 수집하여 딥러닝(Deep Learning)기반 분석합니다.')}</p>
                  
                  <p className="about-expert-text-desc livecare_service_web bottom-0">{t('반추위 보유 여부 등 가축의 특성에 최적화한 가축 헬스케어 서비스를 통해 우리 가축이 건강하고 지속가능한 환경에서 자랄 수 있도록 지원합니다.')}</p>
                  <p className="about-expert-text-desc livecare_service_mobile bottom-0">{t('livercare_service')}</p>
              </div>
              <div className="service_animal">
                  <Row>
                    <Col md="2" sm="4">
                        <Animal name={t("성우")} imgSrc={"cattle-normal.svg"} imgHoverSrc={"cattle-hover.svg"} cattle={true} link="http://livecare.xyz/lc?lang=ko&flag=1"/>
                    </Col>
                    <Col md="2" sm="4">
                        <Animal name={t("송아지")} imgSrc={"calf-normal.svg"} imgHoverSrc={"calf-hover.svg"} link="http://calfedition.com"/>
                    </Col>
                    <Col md="2" sm="4">
                        <Animal name={t("양")} imgSrc={"sheep-normal.svg"} imgHoverSrc={"sheep-hover.svg"} link="http://sheepedition.com"/>
                    </Col>
                    <Col md="2" sm="4">
                        <Animal name={t("말")} imgSrc={"horse-normal.svg"} imgHoverSrc={"horse-hover.svg"} link="http://horseedition.com"/>
                    </Col>
                    <Col md="2" sm="4">
                        <Animal name={t("돼지")} imgSrc={"pig-normal.svg"} imgHoverSrc={"pig-hover.svg"} link="http://pigedition.com"/>
                    </Col>
                    <Col md="2" sm="4">
                        <Animal name={t("닭")} imgSrc={"chicken-normal.svg"} imgHoverSrc={"chicken-hover.svg"} link="http://chickenedition.com"/>
                    </Col>
                  </Row>
              </div>
          </div>
        </div>
        <DarkFooter />
      </div>
    </>
  );
}

export default Service;