
import React from "react";

function Process(props) {
    
  return (
    <div className={props.mobile ? "step_mobile" : ""}>
        <img alt="" src={require("assets/img/"+props.imgSrc)} className="process_img" />
        <p className="step_name">{props.name}</p>
    </div>
  );
}

export default Process;
