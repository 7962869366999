import React,{useState, useEffect} from "react";
import OtherNavbar from "components/Navbars/OtherNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import Path from "components/Path.js";
import { useTranslation } from 'react-i18next'
import { VerticalTimeline }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import HistoryDetail from "../components/HistoryDetail.js";
import { Row, Col } from "reactstrap";
import Archive from "../components/Archive.js";
import AnchorLink from 'react-anchor-link-smooth-scroll'

function History() {
  const { t } = useTranslation()
  const [pills, setPills] = React.useState("1");
  const [ScrollY, setScrollY] = useState(0);
  const [BtnStatus, setBtnStatus] = useState(false); // 버튼 상태
  const [tab21,setTab21] = useState(false);
 const [tab18,setTab18] = useState(false);
 const [tab15,setTab15] = useState(false);
 const [tab11,setTab11] = useState(false);

  const handleFollow = () => {
    setScrollY(window.pageYOffset);
    if(ScrollY > 500) {
      setBtnStatus(true);
    } else {
      setBtnStatus(false);
    } 
  }

  const handleTop = () => {  // 클릭하면 스크롤이 위로 올라가는 함수
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    setScrollY(0);  // ScrollY 의 값을 초기화
    setBtnStatus(false); // BtnStatus의 값을 false로 바꿈 => 버튼 숨김
    setTab21(true)
    setTab18(false)
    setTab15(false)
    setTab11(false)
  }

  useEffect(() => {
    const watch = () => {
      window.addEventListener('scroll', handleFollow)
    }
    watch();
    return () => {
      window.removeEventListener('scroll', handleFollow)
    }
  })

  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

 const [scrollNav,setScrollNav] = useState(false);

 const handlescrollNav = () => {
   const offset = window.pageYOffset;
   if(offset > 540) {
    setScrollNav(true)
   } else {
    setScrollNav(false)
   }
 }

 useEffect(() => {
  window.addEventListener('scroll',handlescrollNav)
 })

 
 const whiteTab21Menu = () =>  {
  setTab21(true)
  setTab18(false)
  setTab15(false)
  setTab11(false)
 }
 const whiteTab18Menu = () =>  {
  setTab21(false)
  setTab18(true)
  setTab15(false)
  setTab11(false)
 }
 const whiteTab15Menu = () =>  {
  setTab21(false)
  setTab18(false)
  setTab15(true)
  setTab11(false)
 }
 const whiteTab11Menu = () =>  {
  setTab21(false)
  setTab18(false)
  setTab15(false)
  setTab11(true)
 }
 
  return (
    <>
      <OtherNavbar />
      <div className="wrapper">
        <div className="main">
          <Path firstPathTitle={t('홈')} firstPath="/" secondPathTitle={t('유라이크코리아')} secondPath="/about" thirdPathTitle={t('걸어온 길')}/>
          <div className="about-healthy-section history_back_img">
            {/* <img alt="" src={require("assets/img/history_bg.jpg")} /> */}
            <img alt="" className="historyWay" src={require("assets/img/historyImg.png")} />
            <div className="about-healthy history_section">
              <div className="about-healthy-part culture_section">
                <p className="about-healthy-title history_title">{t('걸어온 길')}</p>
              </div>
            </div>
            <div className={scrollNav == true ? "hitoryBoldHrFixed" : "hitoryBoldHr"}>
               <div className="historyAnchorDiv">
              <AnchorLink offset='50' className="none_underline" style={{color:'black'}}  href='#history_2021'>
                <span className=""  className={tab21 ? "historyWhiteText" : "historyAnchor" } onClick={whiteTab21Menu}  >2021 ~ 2019</span>
                <div className="responsiveHistoryAnchor"> 
                  <div className="" className={tab21 ? "historyWhiteTextRes" : "historyAnchorRes" } onClick={whiteTab21Menu} >2021 </div>
                  <div className=""className={tab21 ? "historyWhiteTextRes" : "historyAnchorRes" } onClick={whiteTab21Menu}  >~ 2019</div>
                </div>
                <span className="historyAnchorLine">|</span>
              </AnchorLink>
                
              <AnchorLink offset='50' className="none_underline"  style={{color:'black'}}  href='#history_2018'>

                <span className="" className={tab18 ? "historyWhiteText" : "historyAnchor" } onClick={whiteTab18Menu} >2018 ~ 2016</span>
                <div className="responsiveHistoryAnchor"> 
                  <div className="" className={tab18 ? "historyWhiteTextRes" : "historyAnchorRes" } onClick={whiteTab18Menu}>2018 </div>
                  <div className="" className={tab18 ? "historyWhiteTextRes" : "historyAnchorRes" } onClick={whiteTab18Menu}>~ 2016</div>
                </div>
                <span className="historyAnchorLine">|</span>
              </AnchorLink>

              <AnchorLink offset='50' className="none_underline"  style={{color:'black'}}  href='#history_2015'>
                <span className="" className={tab15 == true ? "historyWhiteText" : "historyAnchor" } onClick={whiteTab15Menu} >2015 ~ 2012</span>
                <div className="responsiveHistoryAnchor"> 
                  <div className="" className={tab15 == true ? "historyWhiteTextRes" : "historyAnchorRes" } onClick={whiteTab15Menu}>2015 </div>
                  <div className="" className={tab15 == true ? "historyWhiteTextRes" : "historyAnchorRes" } onClick={whiteTab15Menu}>~ 2012</div>
                </div>
                <span className="historyAnchorLine">|</span>
              </AnchorLink>


                <AnchorLink offset='50' className="none_underline" style={{color:'black'}}  href='#history_achieve'>

                <span className=""  className={tab11 == true ? "historyWhiteText" : "historyAnchor" } onClick={whiteTab11Menu} >{t('주요 수상내역')}</span>
                <div className="responsiveHistoryAnchor"> 
                  <div className="" className={tab11 == true ? "historyWhiteTextRes" : "historyAnchorRes" } onClick={whiteTab11Menu} style={{textAlign:'center'}}>{t('주요')} </div>
                  <div className="" className={tab11 == true ? "historyWhiteTextRes" : "historyAnchorRes" } onClick={whiteTab11Menu} >{t('수상내역')}</div>
                </div>
                <span className="historyAnchorLine">|</span>
                </AnchorLink>
                </div>
            </div>


          
          </div>
          <div   >
            <img alt="" src={require("assets/img/scrollTopImg.png")}  className="scroolBtn" className={BtnStatus ? "topBtn active" : "topBtn"} onClick={handleTop}    />
          </div>   
          
          <img src={require("assets/img/historyLeft.png")} className="historyres2021Img" />
          
          
          
        
              
          <div className="historyDivPadding" style={{position: 'relative'}}>
          
           
          
            <div className={pills === "1" ? "" : "mobile_history_hide"}>
            <img src={require("assets/img/historyresfirst.png")} className="historyresfirst" />
             
              <div id="history_2021" className="yearSpace">
                <p className="history_year reshistory_year">2021</p>
                <VerticalTimeline layout={'1-column-left'} animate={false}>
                
                  <HistoryDetail month={t('12월')} img={require("assets/img/wooImg.png")} detail={t("제15회 하반기 대한민국 우수특허 大賞 '전자/IT부문' 수상")}  detail1={t('미국 JMB 미국 시장 공동개발 협력 MOU 체결')} line={true} ></HistoryDetail>
                  <HistoryDetail month={t('11월')} detail={t('삼성물산 미국시장 공동개발 및 협력 MOU 체결')}  line={true} ></HistoryDetail>
                  <HistoryDetail month={t('9월')} detail={t('블록체인 기반 ‘완벽한’ 한우 브랜드 론칭')} img={require("assets/img/blockChainSep.png")} line={true} ></HistoryDetail>
                  <HistoryDetail month={t('7월')} detail={t(`라이브케어 블록체인 농장 개소`)} detail1={t('영국 FUTUROLOGY 2021 ‘대한민국 가장 혁신적인 AI 스타트업 TOP 101’ 선정')}  line={true} />
                  <HistoryDetail month={t('6월')} detail={t('메탄가스 및 온·습도 측정 환경센서 개발 성공')} line={true} />
                  <HistoryDetail month={t('5월')} detail={t('과학기술정보통신부 산하기관인 본투글로벌센터 주관')} detail1={t('‘2021 DNA 융합제품 서비스 해외진출 지원사업’ 최종 선정')} detail2={t('‘목표지역특화형 해외진출지원사업’ 최종 선정')} detail3={t('대통령직속 4차산업혁명위원회 4기 위원 선정')} line={true} />
                  <HistoryDetail month={t('4월')} detail={t('스마트 ‘양돈’(養豚) 전용 ICT 솔루션 개발 성공')} line={true} />
                  <HistoryDetail month={t('3월')} detail={t('아랍에미리트(UAE) Al Ain 낙농기업 목장에 시스템 구축 성공')} line={true} />
                  <HistoryDetail month={t('1월')} detail={t('유라이크코리아-한국 스미토모상사와 MOU 체결')} img={require("assets/img/historySumi.png")}  line={false} />
                </VerticalTimeline>
                <img src={require("assets/img/hitoryrightSec.png")} className="history2021Res" style={{ height: '356px', right:'-87px', top: '1730px', position: 'absolute'}}/>

              </div>


              <img  src={require("assets/img/historyressecond.png")} className="historyressecond"/>
              <div id="history_2020" className="yearSpace">
                <p className="history_year">2020</p>
                <VerticalTimeline layout={'1-column-left'} animate={false}>
                  <HistoryDetail month={t('11월')} detail={t('양계용 헬스케어 서비스 개발 성공')} img={require("assets/img/historyChicken.png")} line={true} />
                  <HistoryDetail month={t('9월')} detail={t('굿네이버스 글로벌 임팩트와 파라과이 진출 MOU 체결')} line={true} />
                  <HistoryDetail month={t('6월')} detail={t('서울대학교 산업동물임상교육연수원과 연구협약 체결')} detail1={t('라이브케어 AI 데이터센터 개소')} line={true} />
                  <HistoryDetail month={t('5월')} detail={t('국내 최초 COVID-19 모니터링 패치 개발 성공')} line={true} />
                  <HistoryDetail month={t('2월')} detail={t('세계 최초 마이크로 바이오캡슐 개발 성공')} img={require("assets/img/historyDevelopSuccess.png")} line={true} />
                  <HistoryDetail month={t('1월')} detail={t('덴마크 통신사 TDC와 유럽사업 MOU 체결')}  line={false} />
                </VerticalTimeline>
              </div>
              <div className="yearSpace">
              <img className="histoyr20REs" src={require("assets/img/history2019.png")} style={{ height: '356px', right:'-87px',  position: 'absolute'}}/>
              

              <img  src={require("assets/img/historyresThird.png")} className="historyresThird"/>
              
                <p className="history_year">2019</p>
                <VerticalTimeline layout={'1-column-left'} animate={false}>
                  <HistoryDetail month={t('12월')} detail={t('2019 해럴드일자리대상 - 중소벤처기업부 장관상 수상')} detail1={t('말 전용 헬스케어 기술 개발 성공')} img={require("assets/img/historyHorse.png")}   line={true} />
                  <HistoryDetail month={t('11월')} detail={t('양 전용 바이오캡슐 상용화 성공')} detail1={t('LiveCare 유럽법인 설립')} line={true} />
                  <HistoryDetail month={t('10월')} detail={t("MS IoT in Action 국내 유일 '농업 혁신 헬스케어 스타트업' 선정")} line={true} />
                  <HistoryDetail month={t('5월')} detail={t('SoftBank와 호주 총판계약 체결')} line={true} />
                  <HistoryDetail month={t('2월')} detail={t('SK TNS-SKY 일본사업 3자 MOU 체결')} img={require("assets/img/history19.png")}   line={true} />
                  <HistoryDetail month={t('1월')} detail={t('일본 최초 동물의료기기 인증 획득')} line={false} />
                </VerticalTimeline>
              </div>
              <div id="history_2018" className="yearSpace">
                <p className="history_year">2018</p>
                <img src={require("assets/img/historyright18.png")} className="history2018Res" style={{ height: '734px', left:'-310px', position: 'absolute'}}/>
                <img src={require("assets/img/historyresFourth.png")} className="historyresFourth" />

                <VerticalTimeline layout={'1-column-left'} animate={false}>
                  <HistoryDetail month={t('12월')} detail={t('한-UAE 공동 정부과제 최종 선정 (농림축산식품부)')}
                  detail1={t('중동사업 공동협력 3자 MOU 체결')}
                  detail2={t('(유라이크코리아-SK TNS-네이블커뮤니케이션즈)')}
                  detail3={t('[중국 특허출원] 201680087011.5')} 
                  detail4={t('네이블커뮤니-유라이크코리아 MOU 체결')} 
                  img={require("assets/img/history_18_12.png")} 
                  line={true} />
                  <HistoryDetail month={t('10월')} detail={t("축산 ICT 부문 최초 '배상 책임 보험' 시행")} line={true} />
                  <HistoryDetail month={t('9월')} detail={t('덴마크 정부 및 Copenhagen Capacity와 MOU 체결')}
                  detail1={t('세계 최초 송아지용 바이오캡슐 개발 성공')} line={true} />
                  <HistoryDetail month={t('7월')} detail={t('주식회사 유라이크코리아 부설연구소 개소')} line={true} />
                  <HistoryDetail month={t('5월')} detail={t('소프트뱅크 이노베이션 프로그램(SIP) 최종 선정')} line={true} />
                  <HistoryDetail month={t('4월')} detail={t('[유럽(EU) 특허출원] 16918464.5')} line={true} />
                  <HistoryDetail month={t('2월')} 
                  detail={t('Mobile World Congress(MWC) 2018 ‘기업용 모바일 혁신상’ 수상')}
                  detail1={t('유라이크코리아-SKT-남양 3자 MOU 체결')} 
                  img={require("assets/img/history18_2.png")} 
                line={true} />
                  <HistoryDetail month={t('1월')} detail={t('[호주, 뉴질랜드 특허출원] 2018200416,739154')} line={false} />
                </VerticalTimeline>
              </div>
              <img src={require("assets/img/historyresFifth.png")} className="historyresFifth" />
              
              <div className="yearSpace">
                <p className="history_year">2017</p>
                <VerticalTimeline layout={'1-column-left'} animate={false}>
                <img src={require("assets/img/historyRightImg.png")} className="history2017Res" style={{ height: '424px', left:'881px',top:'200px', position: 'absolute'}}/>

                  <HistoryDetail month={t('12월')} detail={t('[브라질 특허출원] BR-112017027077-3')} detail1={t('국내 최초 동물 의료기기 인증 획득 (No. 174-001)')}     line={true} />
                  <HistoryDetail month={t('9월')} detail={t("북유럽 'Growth-Train acceleration program'선정")} line={true} />
                  <HistoryDetail month={t('8월')}   img={require("assets/img/historyGerman.png")} detail={t('농림축산식품부 장관상 수상')} detail1={t("독일 'AIL(Agro Innovation Lab) acceleration program'선정")} line={true} />
                  <HistoryDetail month={t('7월')} detail={t('SKT-유라이크코리아 MOU 체결 및 LoRa제품 출시')} line={true} />
                  <HistoryDetail month={t('5월')} detail={t('소프트뱅크 이노베이션 프로그램(SIP) 최종 선정')} img={require("assets/img/historySoftbank.png")} line={true} />
                  <img src={require("assets/img/history2017left.png")} style={{ height: '356px', left:'-410px', position: 'absolute'}}/>
                  <HistoryDetail month={t('4월')} detail={t('대만 사이보그룹-유라이크코리아 MOU 체결')} detail1={t('[한국상표등록] 제45-2016-0006183호')}  line={true} />
                  <HistoryDetail month={t('3월')} detail={t("한국경제TV 주최 '2017 세계경제금융컨퍼런스' 참가")} line={true} />
                  <HistoryDetail month={t('2월')} detail={t("LiveCare 미국법인 LTS(Livestock Technology Services, Inc) 설립")} line={true} />
                  <HistoryDetail month={t('1월')} detail={t("[일본 특허출원] 2017-018488")} line={false} />
                </VerticalTimeline>
              </div>
            
              <img src={require("assets/img/historyresSixth.png")} className="historyresSixth" />

              <div className="yearSpace">
                <p className="history_year">2016</p>

                <VerticalTimeline layout={'1-column-left'} animate={false}>
                  <HistoryDetail month={t('12월')} img={require("assets/img/16historyImgd.png")} detail={t('2017 스타트업 월드컵 한국대회 최종 우승')} detail1={t('인포마크-유라이크코리아 MOU 및 투자유치')} line={true} />
                  <HistoryDetail month={t('11월')} detail={t('[특허출원] 질병 관리 방법 및 이를 수행하기 위한 장치들')} detail1={t('미래에셋, CCVC Pre-A 투자유치')} line={true} />
           
                  <HistoryDetail month={t('7월')} detail={t("한국여성벤처협회 주관 '선도벤처연계 창업지원사업 2차년도 계속지원’ 최우수사례 선정")} detail1={t('[PCT출원] LiveCare 특허 PCT출원 (일본,중국,호주)')} detail2={t('[상표출원] LiveCare 제 9,41,44 류')} line={true} />
                  <HistoryDetail month={t('6월')} detail={t("선도벤처연계사업-협업경진대회 대상")} line={false} />
                </VerticalTimeline>
              </div>
            </div>
            
            <div className={pills === "2" ? "" : ""}>
            <img src={require("assets/img/histoyrresSeven.png")} className="histoyrresSeven" />
            
              <div id="history_2015" className="yearSpace">
                <p className="history_year">2015</p>
               
                
                <VerticalTimeline layout={'1-column-left'} animate={false}>
                  <HistoryDetail month={t('10월')} img={require("assets/img/historygreen.png")} detail={t('라이브케어 서비스 정식 런칭')} line={true} />
                  <HistoryDetail month={t('4월')} detail={t("미래창조과학부 주관 ‘비타민프로젝트-축우질병관리’ 참여기관 협약 체결")} line={false} />
                </VerticalTimeline>
              </div>
              <div className="yearSpace">
                <p className="history_year">2014</p>
                
                <img src={require("assets/img/histoyrreseight.png")} className="histoyrreseight" />

                <VerticalTimeline layout={'1-column-left'} animate={false}>
                  <HistoryDetail month={t('5월')} img={require("assets/img/kosme.png")} detail={t('중소기업진흥공단 주관 ‘건강진단연계형 공정혁신컨설팅 지원사업’ 최종선정')} line={true} />
                  <HistoryDetail month={t('1월')} detail={t("[특허 등록] 라이브케어 서비스")} line={false} />
                  <img className="histroy14Img" src={require("assets/img/history13right.png")} style={{ height: '465px', right:'-350px',top:'310px', position: 'absolute'}}/>
                
                </VerticalTimeline>
              </div>
              <img src={require("assets/img/historyresnine.png")} className="historyresnine" />
              
              <div className="yearSpace">
                <p className="history_year">2013</p>
                <VerticalTimeline layout={'1-column-left'} animate={false}>
                  
                  <HistoryDetail month={t('1월')} img={require("assets/img/historygreen.png")} detail={t('[특허 출원] 라이브케어 서비스')} line={false} />
                </VerticalTimeline>
              </div>
              <div className="yearSpace">
                <p className="history_year">2012</p>
                <VerticalTimeline layout={'1-column-left'} animate={false}>
                  <HistoryDetail month={t('10월')} img={require("assets/img/historywhite.png")} detail={t('주식회사 유라이크코리아 설립')} line={false} />
                  <img src={require("assets/img/history12.png")} className="histoyr12Img" style={{ height: '356px', left:'-640px',top:'310px', position: 'absolute'}}/>
                  
                </VerticalTimeline>
              </div>
            </div>
          </div>

          <div className="hitory_container culture_section culture_work achieve_history" id="history_achieve">
           
            <div className="main_achieve_section">

              <div style={{display:'flex'}} className="historyResAcheive">
               <p className="about-healthy-title achiveTitle" style={{color: '#333333'}}>{t('주요 수상내역')}</p>
              </div>

              <div style={{display:'flex'}} className="historyResAcheive">
             
                <div className="achieveBox" date={t("2021년 12월")}>
                  <img className="achieveImg" src={require("assets/img/21awardIcon.png")} style={{width:'42px'}}></img>
                  <div>{t('제 15회')}</div>
                  <div>{t('하반기 대한민국 우수특허 大賞')}</div>
                  <div>{t("‘전자/IT부문’ 수상")}</div>
                  <div className="achieveYear">{t('2021년 12월')}</div>
                </div>

                <div className="achieveBox" date={t("2018년 2월")}>
                  <img className="achieveImg" src={require("assets/img/medal.png")} style={{width:'42px'}}></img>
                  <div>2019</div>
                  <div>{t('해럴드일자리대상')}</div>
                  <div>{t('중소벤처기업부 장관상')}</div>
                  <div className="achieveYear">{t('2019년 12월')}</div>
                </div>
              </div>

              <div style={{display:'flex'}} className="historyResAcheive">
              <div className="achieveBox" date={t("2018년 2월")}>
                  <img className="achieveImg" src={require("assets/img/starMedal.png")} style={{width:'46px'}}></img>
                  <div>{t('Mobile World')}</div>
                  <div>{t('Congress(MWC)')}</div>
                  <div>{t('2018 ‘기업용 모바일 혁신상’')}</div>
                  <div className="achieveYear">{t('2018년 2월')}</div>
                </div>
                <div className="achieveBox" date={t("2018년 2월")}>
                
                <img className="achieveImg" src={require("assets/img/trophy.png")} style={{width:'48px'}}></img>
                  <div>{t('4차산업혁명')}</div>
                    <div>{t('파워코리아대전')}</div>
                    <div>{t('농림축산식품부 장관상')}</div>
                    <div className="achieveYear">{t('2017년 8월')}</div>
                </div>
              </div>

              <div style={{display:'flex', justifyContent:'center'}} className="historyResAcheive">
                <div className="achieveBox" date={t("2018년 2월")}>
                
                <img className="achieveImg" src={require("assets/img/soccerTrophy.png")} style={{width:'39px'}}></img>

                    <div>{t('2017 스타트업 월드컵')}</div>
                    <div>{t('(Startup World Cup)')}</div>
                    <div>{t('한국대회 최종 우승')}</div>
                    <div className="achieveYear">{t('2016년 12월')}</div>
                </div>
                
              </div>

              
            </div>
          </div>
        </div>

          
        <DarkFooter />
      </div>
    </>
  );
}

export default History;