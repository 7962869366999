import React from 'react';
import {Link, useHistory} from "react-router-dom";
import DeleteModal from "../../DeleteModal";

const CertificateTd = (props) =>{
    const history = useHistory();
    let id = props.id

    return(
        <>
            <tr>
                <td className="tableTd">{props.name}</td>
                <td className="tableTd">{props.category}</td>
                <td className="tableTd">{props.date_certificate}</td>
                <td className="tableTd">{props.date_create}</td>
                {
                    props.Link ?
                    <td>{props.Link}</td>
                        :
                        null
                }
                <td onClick={() => history.push(`/manager/certificatedetailedit/${id}`,props.data)}>수정</td>
                <td><DeleteModal deletePath={'certificate?'} deleteId={props.id}/></td>
            </tr>
            </>
    )
}

export default CertificateTd