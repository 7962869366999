
import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'
import detectBrowserLanguage from 'detect-browser-language'
i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: detectBrowserLanguage().substring(0,2),
    backend: {
      loadPath: '/assets/i18n/{{ns}}/{{lng}}.json'
    },
    fallbackLng: detectBrowserLanguage().substring(0,2) !== 'ko' && detectBrowserLanguage().substring(0,2) !== 'ja' && detectBrowserLanguage().substring(0,2) !== 'pt' ? 'en' : detectBrowserLanguage().substring(0,2),
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ','
    },
    react: {
      wait: true
    }
  })

export default i18n