import React, { useEffect } from "react";
import ManagerNavbar from "../../components/Navbars/ManagerNavbar";
import Aside from "../../components/Navbars/Aside";
import { press_get, press_detail } from "../../api";
import PressTable from "./Sections/PressTable";
import { useHistory } from "react-router-dom";

const AdminPress = () => {
  const history = useHistory();
  const [pressListko, setPressListko] = React.useState([]);
  const [pressenListen, setPressListen] = React.useState([]);
  const [pressListpt, setPressListpt] = React.useState([]);
  const [pressListja, setPressListja] = React.useState([]);

  const [pressTotalko, setPressTotalko] = React.useState("1");
  const [pressenTotalen, setPressTotalen] = React.useState("1");
  const [pressTotalpt, setPressTotalpt] = React.useState("1");
  const [pressTotalja, setPressTotalja] = React.useState("1");

  const [selects, SetSelects] = React.useState("ko");

  useEffect(() => {
    fetchData("ko");
    fetchTotal("ko");
    fetchData("en");
    fetchTotal("en");
    fetchData("pt");
    fetchTotal("pt");
    fetchData("ja");
    fetchTotal("ja");
    let islogin = JSON.parse(localStorage.getItem("name"));
    if (islogin !== null) {
    } else {
      history.push("/manage");
    }
  }, [history]);

  const fetchData = async (language) => {
    const response = await press_get(language, 1, 15);
    if (response.result === "200") {
      if (response.data.length > 0) {
        let temp = [];
        response.data.map((detail) => {
          if (detail.language.replace(/\s/g, "") === language) {
            temp.push(detail);
          }
        });
        if (language === "ko") setPressListko(temp);
        else if (language === "en") setPressListen(temp);
        else if (language === "pt") setPressListpt(temp);
        else if (language === "ja") setPressListja(temp);
      }
    }
  };

  const fetchTotal = async (language) => {
    const response = await press_detail(language);
    if (response.result === "200") {
      if (response.data.length > 0) {
        let temp2 = [];
        response.data.map((detail) => {
          if (detail.language.replace(/\s/g, "") === language) {
            temp2.push(detail);
          }
        });

        if (language === "ko") setPressTotalko(Math.ceil(temp2.length / 15));
        else if (language === "en")
          setPressTotalen(Math.ceil(temp2.length / 15));
        else if (language === "pt")
          setPressTotalpt(Math.ceil(temp2.length / 15));
        else if (language === "ja")
          setPressTotalja(Math.ceil(temp2.length / 15));
      }
    }
  };

  const selectLan = (e) => {
    var Lang = e.target.value;
    SetSelects(Lang);
  };

  return (
    <>
      {/*className={selects === 'ko' ? "active" : ""}*/}
      {/*className={selects === 'en' ? "active" : ""}*/}
      {/*className={selects === 'pt' ? "active" : ""}*/}
      {/*className={selects === 'ja' ? "active" : ""}*/}
      <ManagerNavbar />
      <div style={{ display: "flex" }}>
        <Aside></Aside>

        <div style={{ width: "100%" }}>
          <div style={{ width: "80%", margin: "0 auto" }}>
            <span className="pressSubTitle">언론보도 목록</span>
            <select
              className="press_selectBox form-control"
              defaultValue="ko"
              id="service"
              onChange={selectLan}
            >
              <option value="ko">{"한국"}</option>
              <option value="en">{"영어"}</option>
              <option value="pt">{"포르투갈"}</option>
              <option value="ja">{"일본"}</option>
            </select>
          </div>

          {selects == "ko" ? (
            <PressTable data={pressListko} total={pressTotalko} language="ko" />
          ) : null}
          {selects == "en" ? (
            <PressTable
              data={pressenListen}
              total={pressenTotalen}
              language="en"
            />
          ) : null}
          {selects == "pt" ? (
            <PressTable data={pressListpt} total={pressTotalpt} language="pt" />
          ) : null}
          {selects == "ja" ? (
            <PressTable data={pressListja} total={pressTotalja} language="ja" />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default AdminPress;
