import React from "react";
import { Container } from "reactstrap";
import { Player, ControlBar, Shortcut } from 'video-react';
import { useTranslation } from 'react-i18next'
import 'video-react/dist/video-react.css';
function IndexHeader() {
  const { t } = useTranslation()

  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
      <div className="page-header clear-filter">
        <div
          ref={pageHeader}
        ></div>
        <Player autoPlay={true} controls={false} loop={true} muted={true} playsInline>
          <source src={require("assets/video/ulike.mp4")} />
          <Shortcut clickable={false} dblclickable={false} />
          <ControlBar disableDefaultControls={true} />
        </Player>
        <Container className="home_video_web">
          <div className="content-center brand">
            <p className="brand_title">{t('데이터로 만드는 헬스케어 혁신')}</p>
            <p className="brand_description">
              {t('가축이 건강할 때 사람도 건강하다는 신념으로 시작했습니다.')}<br/>
              {t('가축과 사람을 연결하는 데이터를 중심으로 디지털 혁신을 주도합니다.')}
            </p>
          </div>
        </Container>
        <div className="home_video_gradient_section"></div>  
        <i
          aria-hidden={true}
          className="fa fa-angle-double-down angle_double_down home_video_web"
        ></i>
      </div>
      <Container className="home_video_mobile">
          <div className="content-center brand">
            <p className="brand_title">{t('데이터로 만드는 헬스케어 혁신')}</p>
            <p className="brand_description">
              {t('가축이 건강할 때 사람도 건강하다는 신념으로 시작했습니다.')}<br/>
              {t('가축과 사람을 연결하는 데이터를 중심으로 디지털 혁신을 주도합니다.')}
            </p>
          </div>
          <i aria-hidden={true} className="fa fa-angle-double-down angle_double_down"></i>
      </Container>
    </>
  );
}
export default IndexHeader;
