import React, { useEffect  } from "react";
import { Row, Col, Button } from "reactstrap";
import Animal from "../components/Animal";
import MainPartner from "../components/MainPartner";
import SubPartner from "../components/SubPartner";
import NewsCard from "../components/NewsCard";
import { useTranslation } from 'react-i18next'
import { Link } from "react-router-dom";
import { press_detail } from "../api";
import detectBrowserLanguage from "detect-browser-language";
function renderPress(data) {
  return data.map((detail, index) => {
    if(index < 3)
      return <Col key={index} md="4">
        <NewsCard imgSrc={detail.image} title={detail.title} data={data} detail={detail} number={index+1}  no={detail.id} />
      </Col>
  })
}

function Home() {
  const { t, i18n } = useTranslation()
  const [pressENList, setPressENList] = React.useState([]);
  const [pressKOList, setPressKOList] = React.useState([]);
  const [pressJPList, setPressJPList] = React.useState([]);
  const [pressPTList, setPressPTList] = React.useState([]);
  const [curLang, setCurLang] = React.useState(localStorage.getItem("lang") ? localStorage.getItem("lang") : detectBrowserLanguage().substring(0,2));
  React.useEffect(() => {
    const updateScrollPosition = () => {
      if(document.documentElement.scrollTop > 1900) {
        if(document.getElementById("capsule"))
          document.getElementById("capsule").style.paddingLeft = window.innerWidth - 500 - (document.documentElement.scrollTop - 1900) +"px";
      }
    };
    setCurLang(i18n.language === 'ko' ? 'ko' : i18n.language === 'ja' ? 'ja' : i18n.language === 'pt' ? 'pt' : 'en')
    window.addEventListener("scroll", updateScrollPosition);
  }, [i18n.language]);
  useEffect(() => {
    fetchData();
  },[])
  const fetchData = async () => {
    const response = await press_detail();
    if(response.result === "200" && response.data && response.data.length > 0) {
      let tempE = []
      let tempK = []
      let tempJ = []
      let tempP = []
      response.data.map((item) => {
        if(item.language === 'en') {
          tempE.push(item)
        } else if(item.language === 'ko') {
          tempK.push(item)
        } else if(item.language === 'ja') {
          tempJ.push(item)
        } else if(item.language === 'pt') {
          tempP.push(item)
        }
      })

      setPressENList(tempE)
      setPressKOList(tempK)
      setPressJPList(tempJ)
      setPressPTList(tempP)
    }
  }

  const gotoLink = () => {
    let lang = i18n.language === 'ko' ? 'ko' : i18n.language === 'ja' ? 'ja' : i18n.language === 'pt' ? 'pt' : 'en'
    if(!lang)
      lang = detectBrowserLanguage().substring(0,2)
    let link = "https://livecare.xyz/?lang=en&flag=1"
    if(lang == 'ko')
      link = 'https://livecare.xyz/?lang=ko&flag=1'
    else if(lang == 'pt')
      link = 'https://livecare.xyz/?lang=pt&flag=1'
    else if(lang == 'ja')
      link = 'https://www.livecare.co.jp/'
    window.open(link, "target")
  }

  return (
    <>
    <div>
      <div className="section section-images">
        <div className="section-slogan">
          <div className="section-content">
            <div className="section-content-left"></div>
            <div className="section-content-right">
              <p className="title">{t('생체 빅데이터와 AI가 만나다')}</p>
              <p className="description">{t('전세계 농장에서 수집한 가축 생체 빅데이터의 가치는 무궁무진합니다.')} <br/>
    {t('유라이크코리아가 보유한 생체 데이터와 인공지능(AI) 기술력을 통해 생산자와 소비자 모두에게 필요한 데이터로 세상에 기여하고자 합니다.')}</p>
              <Button className="intro_btn" to={"/about"} tag={Link}>{t('유라이크코리아 소개')} <i className="now-ui-icons arrows-1_minimal-right"></i></Button>
            </div>
          </div>
        </div>
        <div className="biology_img_section">
          <img className="biology_img" alt="" src={require("assets/img/1.png")}></img>
          <img className="biology_img" alt="" src={require("assets/img/2.png")}></img>
          <img className="biology_img" alt="" src={require("assets/img/3.png")}></img>
          <img className="biology_img" alt="" src={require("assets/img/4.png")}></img>
          <img className="biology_img" alt="" src={require("assets/img/5.png")}></img>
          <img className="biology_img biology_last" alt="" src={require("assets/img/6.png")}></img>
          <div className="biology_img_gradient">
          </div>
        </div>
        <div className="section-slogan section_slogan_mobile">
          <div className="section-content">
            <div className="section-content-left"></div>
            <div className="section-content-right">
              <p className="title">{t('생체 빅데이터와 AI가 만나다')}</p>
              <p className="description">{t('전세계 농장에서 수집한 가축 생체 빅데이터의 가치는 무궁무진합니다.')} <br/>
    {t('유라이크코리아가 보유한 생체 데이터와 인공지능(AI) 기술력을 통해 생산자와 소비자 모두에게 필요한 데이터로 세상에 기여하고자 합니다.')}</p>
              <Button className="intro_btn" to={"/about"} tag={Link}>{t('유라이크코리아 소개')} <i className="now-ui-icons arrows-1_minimal-right"></i></Button>
            </div>
          </div>
        </div>
      </div>
      <div className="section section-live-care live_care_img_section">
        <div>
          <img className="live_care_img" alt="" src={require("assets/img/bg-lc-image.png")}></img>
        </div>
        <div className="live-care">
          <p className="title">{t('LiveCare Services')}</p>
          <p className="livecare_description">{t('인공지능 가축헬스케어 솔루션, 라이브케어 서비스')}<br/>{t('인간과 가축의 건강한 공존을 만들어갑니다')}</p>
          <p className="livecare_description livecare_description_mobile">{t('live_care_detail')}</p>
          <Row>
            <Col md="4" sm="4">
              <Animal name={t("성우")} imgSrc={"cattle-normal.svg"} imgHoverSrc={"cattle-hover.svg"} cattle={true} link="http://livecare.xyz/lc?lang=ko&flag=1"/>
            </Col>
            <Col md="4" sm="4">
              <Animal name={t("송아지")} imgSrc={"calf-normal.svg"} imgHoverSrc={"calf-hover.svg"} link={i18n.language === 'ko' ? "http://calfedition.com" : "https://en.calfedition.com" }/>
            </Col>
            <Col md="4" sm="4">
              <Animal name={t("말")} imgSrc={"horse-normal.svg"} imgHoverSrc={"horse-hover.svg"} link="http://horseedition.com"/>
            </Col>
            <Col md="4" sm="4">
              <Animal name={t("양")} imgSrc={"sheep-normal.svg"} imgHoverSrc={"sheep-hover.svg"} link="http://sheepedition.com"/>
            </Col>
            <Col md="4" sm="4">
              <Animal name={t("돼지")} imgSrc={"pig-normal.svg"} imgHoverSrc={"pig-hover.svg"} link="http://pigedition.com"/>
            </Col>
            <Col md="4" sm="4">
              <Animal name={t("닭")} imgSrc={"chicken-normal.svg"} imgHoverSrc={"chicken-hover.svg"} link="http://chickenedition.com"/>
            </Col>
          </Row>
     
          
        </div>
      </div>
      <div className="section capsule_section" style={{backgroundColor: '#90DAE0'}}>
        <div>
          <img alt="" id="capsule" src={require("assets/img/capsule.svg")} style={{paddingLeft: window.innerWidth - 500}} />
        </div>
      </div>
      <div className="section section-partner">
        <div className="container main_partner_section" style={{display: 'flex'}}>
          <span className="main_partner">{t('주요 파트너사')}</span>
          <span className="main_partner_desc">{t('글로벌 경쟁력을 입증한 유라이크코리아는 다양한 선도 기업들과 함께 합니다')}</span>
        </div>
        <div className="container partner_container" style={{display: 'flex'}}>
          <Col md="3" lg="3" className="mainpartner_logo">
            <MainPartner imgSrc={"softbank-logo.png"} class_name="softbank" />
          </Col>
          <Col md="3" lg="3" className="mainpartner_logo">
            <MainPartner imgSrc={"sktelecom-logo.png"} class_name="sktelecom"/>
          </Col>
          <Col md="3" lg="3" className="mainpartner_logo">
            <MainPartner imgSrc={"microsoft-logo.png"} class_name="microsoft"/>
          </Col>
          <Col md="3" lg="3" className="mainpartner_logo">
            <MainPartner imgSrc={"tdc-logo.svg"} class_name="tdc"/>
          </Col>
        </div>
        <div className="container partner_container" style={{display: 'flex'}}>
          <Col md="3" lg="3" className="mainpartner_logo">
            <MainPartner imgSrc={"sumitomo-logo-2.png"} class_name="sumitomo" />
          </Col>
          <Col md="3" lg="3" className="mainpartner_logo">
            <MainPartner imgSrc={"denmark-logo.png"} class_name="denmark"/>
          </Col>
          <Col md="3" lg="3" className="mainpartner_logo">
            <MainPartner imgSrc={"sktns-logo.svg"} class_name="sktns"/>
          </Col>
          <Col md="3" lg="3" className="mainpartner_logo">
            <MainPartner imgSrc={"uaeu-logo.svg"} class_name="uaeu" />
          </Col>
        </div>
        <div className="container partner_container " style={{display: 'flex'}}>
          <Col md="3" lg="3" className="mainpartner_logo">
            <MainPartner imgSrc={"goodngb-logo.png"} class_name="goodneighbors" />
          </Col>
          <Col md="3" lg="3" className="mainpartner_logo">
            <MainPartner imgSrc={"thebetter-logo.png"} class_name="goodneighbors" />
          </Col>
          <Col md="3" lg="3" className="mainpartner_logo" style={{width:"170px!important"}}>
            <SubPartner imgSrc={"hokkaido2.png"} class_name="goodneighbors" />
          </Col>
          <Col md="3" lg="3" className="mainpartner_logo" style={{width:"170px"}}>
            <SubPartner imgSrc={"mitsubishi2.png"} class_name="goodneighbors" />
          </Col>
        </div>
        <div className="container partner_container" style={{display: 'flex'}}>
          <Col md="3" lg="3" className="mainpartner_logo"  >
            <MainPartner imgSrc={"samsung.png"} class_name="goodneighbors hokkaidos"   />
          </Col>
         
        </div>


      </div>
      <div className="section section-news main_news_section">
        <p className="news_title">{t('주요 언론보도')}</p>
        <div className="culture_section advts_card_section">
          <Row>
            {
              curLang === 'en' ?
              renderPress(pressENList)
              :
              curLang === 'ko' ?
              renderPress(pressKOList)
              :
              curLang === 'ja' ?
              renderPress(pressJPList)
              :
              curLang === 'pt' ?
              renderPress(pressPTList)
              :
              null
            }
          </Row>
          <Button className="intro_btn" to={"/advts#pr_section"} tag={Link} >{t('언론보도 더보기')} <i className="now-ui-icons arrows-1_minimal-right"></i></Button>
        </div>
      </div>
      <div className="section section-live-care home_ai_section">
        <img alt="" src={require("assets/img/ai.jpg")} />
        <div className="ai_section home_ai">
          <div className="container ai_container">
            <div>
              <p className="ai_title">{t('인공지능 그리고 데이터에 가치를 더하다')}</p>
              <p className="ai_desc">{t('유라이크코리아는 한 분, 한 분의 의견을 소중히 생각합니다.')}<br/>
{t('서비스 문의 및 사업 제휴 등 보내주시는 문의사항은 메일이나 전화로 최대한 신속히 답변드리겠습니다.')}</p>
              <Button className="inquiry_btn" to={"/help"} tag={Link}>{t('문의하기')}</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default Home;