import React, { useEffect, useReducer } from "react";
import { Pagination, PaginationItem, PaginationLink, Table } from "reactstrap";
import CertificateTd from "./CertificateTd";
import { Link, useHistory } from "react-router-dom";
import { certificate_get } from "../../../api";

const CertificateTable = (props) => {
  const history = useHistory();

  const [selects, SetSelects] = React.useState("ko");
  const [datas, SetDatas] = React.useState([]);

  const [curPage, setCurPage] = React.useState(1);
  const [list, setList] = React.useState(props.data);

  useEffect(() => {
    SetDatas(props.data);
  }, [props]);

  const selectLan = (e) => {
    var Lang = e.target.value;
    SetSelects(Lang);
  };

  const callData = async (page) => {
    if (curPage !== page) {
      setCurPage(page);
      const response = await certificate_get(props.type, page, 15);
      if (response.result === "200" && response.data.length > 0) {
        setList(response.data);
      }
    }
  };

  return (
    <>
      <div
        style={{ backgroundColor: "#EEF1F3", width: "100%", height: "105vh" }}
      >
        <div
          style={{
            width: "80%",
            margin: "0 auto",
            position: "relative",
            top: "150px",
          }}
        >
          <div
            style={{
              width: "100%",
              margin: "10px auto",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <select
              className="form-control"
              style={{ width: "150px" }}
              id="service"
              onChange={selectLan}
            >
              <option value="ko">한국</option>
              <option value="en">영어</option>
              <option value="pt">포르투갈</option>
              <option value="jp">일본</option>
            </select>

            <Link
              to={{ pathname: "/manager/newcertificate", state: props.type }}
              style={{ color: "black", paddingTop: "8px" }}
            >
              신규작성
            </Link>
          </div>

          <div>
            <Table className=" manager_table">
              <thead>
                <tr>
                  <th className="theadTitleCenter">{props.cate1}</th>
                  <th className="theadTitleCenter">{props.cate2}</th>
                  <th className="theadTitleCenter">{props.cate3}</th>
                  <th className="theadTitleCenter">{props.cate4}</th>
                  {props.cate5 ? <th>{props.cate5}</th> : null}
                  <th style={{ width: "70px" }}>수정</th>
                  <th style={{ width: "70px" }}>삭제</th>
                </tr>
              </thead>
              <tbody>
                {selects == "ko"
                  ? list.map((detail, index) => (
                      <CertificateTd
                        data={list}
                        cur_page={curPage}
                        id={detail.id}
                        name={detail.name_kr}
                        category={detail.category_kr}
                        date_certificate={detail.date_certificate_kr}
                        date_create={detail.date_create}
                        Link={detail.link}
                      />
                    ))
                  : null}
                {selects == "en"
                  ? list.map((detail, index) => (
                      <CertificateTd
                        data={list}
                        cur_page={curPage}
                        id={detail.id}
                        name={detail.name_en}
                        category={detail.category_en}
                        date_certificate={detail.date_certificate_en}
                        date_create={detail.date_create}
                        Link={detail.link}
                      />
                    ))
                  : null}
                {selects == "pt"
                  ? list.map((detail, index) => (
                      <CertificateTd
                        data={list}
                        cur_page={curPage}
                        id={detail.id}
                        name={detail.name_pt}
                        category={detail.category_pt}
                        date_certificate={detail.date_certificate_pt}
                        date_create={detail.date_create}
                        Link={detail.link}
                      />
                    ))
                  : null}
                {selects == "jp"
                  ? list.map((detail, index) => (
                      <CertificateTd
                        data={list}
                        cur_page={curPage}
                        id={detail.id}
                        name={detail.name_jp}
                        category={detail.category_jp}
                        date_certificate={detail.date_certificate_jp}
                        date_create={detail.date_create}
                        Link={detail.link}
                      />
                    ))
                  : null}

             
              </tbody>
            </Table>

            <div style={{ textAlign: "center" }}>
              <Pagination>
                <PaginationItem>
                  <PaginationLink
                    aria-label="First"
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      callData(1);
                    }}
                  >
                    <span aria-hidden={true}>
                      <i
                        aria-hidden={true}
                        className="fa fa-angle-double-left"
                      ></i>
                    </span>
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink
                    aria-label="Previous"
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      callData(curPage > 1 ? curPage - 1 : 1);
                    }}
                  >
                    <span aria-hidden={true}>
                      <i aria-hidden={true} className="fa fa-angle-left"></i>
                    </span>
                  </PaginationLink>
                </PaginationItem>
                <span className="curPage">{curPage}</span>
                <span>/</span>
                <span className="lastPage">{props.total}</span>
                <PaginationItem>
                  <PaginationLink
                    aria-label="Next"
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      callData(
                        curPage < props.total ? curPage + 1 : props.total
                      );
                    }}
                  >
                    <span aria-hidden={true}>
                      <i aria-hidden={true} className="fa fa-angle-right"></i>
                    </span>
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink
                    aria-label="Last"
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      callData(props.total);
                    }}
                  >
                    <span aria-hidden={true}>
                      <i
                        aria-hidden={true}
                        className="fa fa-angle-double-right"
                      ></i>
                    </span>
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CertificateTable;
