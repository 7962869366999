import React from "react";

function SubPartner(props) {
  return (
      <div className={props.left ? "margin_auto margin_left_0 "+props.class_name : "margin_auto "+props.class_name}>
        <span></span>
        <img alt="" src={require("assets/img/"+props.imgSrc)} style={{width: '150px'}}/>
      </div>
  );
}

export default SubPartner;
