import React, { useEffect } from "react";
import { contact_get, contact_total } from "../../api";
import ManagerNavbar from "../../components/Navbars/ManagerNavbar";
import Aside from "../../components/Navbars/Aside";
import ServiceTable from "../Service/Sections/ServiceTable";
import ContactTable from "./Sections/ContactTable";
import { useHistory } from "react-router-dom";

const AdminContact = () => {
  const history = useHistory();
  const [contactList, setContactList] = React.useState([]);
  const [contactTotal, setContactTotal] = React.useState(1);

  useEffect(() => {
    let islogin = JSON.parse(localStorage.getItem("name"));
    if (islogin !== null) {
    } else {
      history.push("/manage");
    }
    fetchData();
    fetchTotal();
  }, [history]);

  const fetchData = async () => {
    const response = await contact_get(1, 15);
    if (response.result === "200") {
      setContactList(response.data);
    }
  };

  const fetchTotal = async () => {
    const response = await contact_total();
    if (response.result === "200") {
      if (response.data.length > 0) {
        setContactTotal(Math.ceil(response.data.length / 15));
      }
    }
  };

  return (
    <>
      <ManagerNavbar />
      <div style={{ display: "flex" }}>
        <Aside></Aside>
        <ContactTable data={contactList} total={contactTotal} />
      </div>
    </>
  );
};

export default AdminContact;
