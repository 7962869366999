import React, { useEffect } from "react";
import { certificate_get, contact_get } from "../../../api";
import { Pagination, PaginationItem, PaginationLink, Table } from "reactstrap";
import { useHistory } from "react-router-dom";
import DeleteModal from "../../DeleteModal";

const ContactTableContent = (props) => {
  const history = useHistory();
  return props.data.map((detail, index) => {
    //카테고리
    if (detail.category === "Goverment") {
      var category = "정부기관";
    } else if (detail.category === "Individually") {
      var category = "개인소유 목장";
    } else if (detail.category === "Corporate") {
      var category = "기업소유 목장";
    } else if (detail.category === "Livestock") {
      var category = "축산 기업";
    } else if (detail.category === "ICT") {
      var category = "ICT 기업";
    } else {
      var category = "기타";
    }
    let country = detail.country; // 국가
    let company_name = detail.company_name; //법인명
    let email = detail.email; // 이메일 주소
    let date_create = detail.date_create; //문의일
    let id = detail.id;
    return (
      <tr>
        <td
          key={index}
          onClick={() =>
            history.push(`/manager/contactdetail/${id}`, props.data)
          }
        >
          {category}
        </td>
        <td
          key={index}
          onClick={() =>
            history.push(`/manager/contactdetail/${id}`, props.data)
          }
        >
          {country}
        </td>
        <td
          key={index}
          onClick={() =>
            history.push(`/manager/contactdetail/${id}`, props.data)
          }
        >
          {company_name}
        </td>
        <td
          key={index}
          onClick={() =>
            history.push(`/manager/contactdetail/${id}`, props.data)
          }
        >
          {email}
        </td>
        <td>{date_create}</td>
        <td>
          <DeleteModal deletePath={"contact?"} deleteId={detail.id} />
        </td>
      </tr>
    );
  });
};

const ContactTable = (props) => {
  const [curPage, setCurPage] = React.useState(1);
  const [list, setList] = React.useState(props.data);

  useEffect(() => {
    setList(props.data);
  }, [props]);
  const callData = async (page) => {
    if (curPage !== page) {
      setCurPage(page);
      const response = await contact_get(page, 15);

      //
      if (response.result === "200" && response.data.length > 0) {
        setList(response.data);
      }
    }
  };

  return (
    <div style={{ backgroundColor: "#EEF1F3", width: "100%", height: "105vh" }}>
      <div
        style={{
          width: "80%",
          margin: "0 auto",
          position: "relative",
          top: "150px",
        }}
      >
        <span className="servieSubTitle">제휴문의 목록</span>
        <Table className="manager_table">
          <thead>
            <tr>
              <th>카테고리</th>
              <th>국가</th>
              <th>법인명</th>
              <th>이메일 주소</th>
              <th>문의일</th>
              <th>삭제</th>
            </tr>
          </thead>
          <tbody>
            {list && list.length > 0 ? (
              <ContactTableContent data={list} cur_page={curPage} />
            ) : null}
          </tbody>
        </Table>

        <div style={{ textAlign: "center" }}>
          <Pagination>
            <PaginationItem>
              <PaginationLink
                aria-label="First"
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  callData(1);
                }}
              >
                <span aria-hidden={true}>
                  <i aria-hidden={true} className="fa fa-angle-double-left"></i>
                </span>
              </PaginationLink>
            </PaginationItem>
            <PaginationItem>
              <PaginationLink
                aria-label="Previous"
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  callData(curPage > 1 ? curPage - 1 : 1);
                }}
              >
                <span aria-hidden={true}>
                  <i aria-hidden={true} className="fa fa-angle-left"></i>
                </span>
              </PaginationLink>
            </PaginationItem>

            <span className="curPage">{curPage}</span>
            <span>/</span>
            <span className="lastPage">{props.total}</span>

            <PaginationItem>
              <PaginationLink
                aria-label="Next"
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  callData(curPage < props.total ? curPage + 1 : props.total);
                }}
              >
                <span aria-hidden={true}>
                  <i aria-hidden={true} className="fa fa-angle-right"></i>
                </span>
              </PaginationLink>
            </PaginationItem>

            <PaginationItem>
              <PaginationLink
                aria-label="Last"
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  callData(props.total);
                }}
              >
                <span aria-hidden={true}>
                  <i
                    aria-hidden={true}
                    className="fa fa-angle-double-right"
                  ></i>
                </span>
              </PaginationLink>
            </PaginationItem>
          </Pagination>
        </div>
      </div>
    </div>
  );
};

export default ContactTable;
