import React ,{useEffect}from "react";
import OtherNavbar from "components/Navbars/OtherNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import { useTranslation } from 'react-i18next'
import moment from 'moment';
import { Link ,useHistory} from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';
import axios from 'axios';
import { press_detail } from "../api";
import { useSelector } from "react-redux";
import detectBrowserLanguage from "detect-browser-language";

function AdvtsDetail(props) {
   const { t, i18n } = useTranslation();
  const [title, setTitle] = React.useState('');
  const [date, setDate] = React.useState('');
  const [content, setContent] = React.useState('');
  const [data, setData] =React.useState([]);
  const [curInfo, setCurInfo] = React.useState(null)
  const [isPrev, setIsPrev] = React.useState(true)
  const [isNext, setIsNext] = React.useState(true)
  
  const history = useHistory();

  React.useEffect(() => {   
    let detail = null; 
    


   if(!curInfo) {
      const fetchData = async () => {
          let params = props.match.params.id;
         
            const response = await press_detail();
            if (
              response.result === "200" &&
              response.data &&
              response.data.length > 0
            ) {                                                                                                                                      
        
              response.data.map((item) => {
           
             
              if (item.id === params) {
                  setTitle(item.title)
                  setContent(item.contents)
                  setDate(item.date_create)
      
                }    
              });
             
            }
          };
          fetchData();
      
    } 

    else if(!curInfo){
      detail = props.location.state.pressD.detail;
      console.log('detail',detail );
      setCurInfo(detail);
   
      if(props.location.state.pressD.data) {
        props.location.state.pressD.data.map((advts, index) => {
          console.log('advts.id',advts,detail );
          if(detail && advts.id === detail.id) {
            if(index === 0){

            
              setIsPrev(false)
              console.log('aa', );
            }
            else{
              console.log('bb', );
              setIsPrev(true)
            }
            if(index === props.location.state.pressD.data.length  - 1)
            {  
              console.log('ccc',index ,props.location.state.pressD.data.length  - 1);
              setIsNext(false)
            }
            else
              
              {            
              setIsNext(true)
                console.log('dd')
              }
          }
        })
        setData(props.location.state.pressD.data)
      }
    } 
    if(detail) {
      setTitle(detail.title)
      setDate(moment(detail.date_create.slice(0, -3)).format("YYYY.MM.DD"))
      setContent(detail.contents)  
    }

    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, [curInfo, props, props.location.search, data, history]);



 
  return (
    <>
      <OtherNavbar />
      <div className="wrapper">
        <div className="main">
          <div className="culture_section privacy_secion advts_detail_section" style={{paddingBottom: 0}}>
            <p className="privacy_title service_title">{t('언론보도')}</p>
            <div className="advts_detail announce_detail_part press_detail" style={{textAlign: 'center'}}>
                <p className="help_title">{title }</p>
                <p className="announce_detail_date">{date}</p>
            </div>
            <div className="advts_detail announce_detail_part parse" style={{textAlign: 'center', borderTop: 0}}>
                {ReactHtmlParser(content )}
            </div>
    
            
          </div>
          <div className="culture_section announce_detail_pagination advts_detail_pagination">
          
              <Link className="annouce_list_color none_underline" to={"/advts#pr_section"}>
                <div className="all">
                  <i className="now-ui-icons design_bullet-list-67"></i>
                  <span style={{paddingLeft: 5}}>{t('전체목록')}</span>
                </div>
              </Link>
            </div>
        </div>
        <DarkFooter />
      </div>
    </>
  );
}

export default AdvtsDetail;