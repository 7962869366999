import React, { useEffect } from "react";
import ManagerNavbar from "../../../components/Navbars/ManagerNavbar";
import Aside from "../../../components/Navbars/Aside";
import { Pagination, PaginationItem, PaginationLink, Table } from "reactstrap";
import { useHistory } from "react-router-dom";

const ContactDetail = (props) => {
  const [detailData, setDetailData] = React.useState([]);

  var a = props.location.pathname;
  var b = a.substr(23);

  const history = useHistory();
  useEffect(() => {
    let islogin = JSON.parse(localStorage.getItem("name"));
    if (islogin !== null) {
    } else {
      history.push("/manage");
    }
    fetchData();
  }, [fetchData, history]);

  const fetchData = () => {
    props.location.state.map((detail, index) => {
      if (detail.id === b) {
        setDetailData(detail);
      }
    });
  };

  if (detailData.category === "Goverment") {
    var category = "정부기관";
  } else if (detailData.category === "Individually") {
    var category = "개인소유 목장";
  } else if (detailData.category === "Corporate") {
    var category = "기업소유 목장";
  } else if (detailData.category === "Livestock") {
    var category = "축산 기업";
  } else if (detailData.category === "ICT") {
    var category = "ICT 기업";
  } else {
    var category = "기타";
  }
  const backBtn = () => {
    history.goBack(1);
  };

  return (
    <>
      <ManagerNavbar />
      <div style={{ display: "flex" }}>
        <Aside></Aside>
        <div
          style={{
            backgroundColor: "#EEF1F3",
            width: "100%",
            height: "105vh",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "80%",
              margin: "0 auto",
              position: "absolute",
              top: "150px",
              overflowY: "scroll",
              height: "80vh",
            }}
          >
            <div>
              <span onClick={backBtn} style={{ position: "relative" }}>
                뒤로가기
              </span>
            </div>
            <span className="servieSubTitle">등록된 제휴문의</span>
            <Table className="manager_table">
              <tbody>
                <tr>
                  <td className="serviceDetailTd">문의일</td>
                  <td>{detailData.date_create}</td>
                </tr>
                <tr>
                  <td className="serviceDetailTd">카테고리</td>
                  <td>{category}</td>
                </tr>
                <tr>
                  <td className="serviceDetailTd">국가</td>
                  <td>{detailData.country}</td>
                </tr>
                <tr>
                  <td className="serviceDetailTd">법인명</td>
                  <td>{detailData.company_name}</td>
                </tr>
                <tr>
                  <td className="serviceDetailTd">담당자명</td>
                  <td>{detailData.company_name}</td>
                </tr>
                <tr>
                  <td className="serviceDetailTd">이메일 주소</td>
                  <td>{detailData.email}</td>
                </tr>
                <tr>
                  <td className="serviceDetailTd">전화번호</td>
                  <td>{detailData.phone}</td>
                </tr>
                <tr>
                  <td className="serviceDetailTd">주소</td>
                  <td>{detailData.addr}</td>
                </tr>

                <tr className="contentTd">
                  <td className="contentTd serviceDetailTd">문의내용</td>
                  <td className="contentTd">{detailData.contents}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactDetail;
