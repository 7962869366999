import React from "react";
import GoogleMapReact from 'google-map-react';
import { GoogleMapKey } from '../Global.js';
function SubcompanyMap(props) {
    
  return (
    <div className="map_section">
        <div className="sub_map">
            <GoogleMapReact bootstrapURLKeys={{ key: GoogleMapKey}}
                defaultCenter={{lat: props.lat,lng: props.lng}}
                defaultZoom={13}
            >
            </GoogleMapReact>
        </div>
        <div className="main_map_detail">
            <div className="map_detail_section submap_section">
                {props.title}
            </div>
            <p className="map_detail_section sub_company_title submap_section">{props.subtitle} </p>
            <div className="map_detail_section submap_section submap_detail">
                <i className="fa fa-map-marker-alt"></i>
                {props.addr}
            </div>
            {
                props.phone ?
                <div className="map_detail_section submap_section submap_detail">
                    <i className="fa fa-phone"></i>
                    {props.phone}
                </div>
                :
                null
            }
            
            <div className="map_detail_section submap_section submap_detail">
                <i className="fa fa-envelope"></i>
                {props.email}
            </div>
        </div>
    </div>
  );
}

export default SubcompanyMap;
