import React from "react";
import OtherNavbar from "components/Navbars/OtherNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import Path from "components/Path.js";
import { useTranslation } from 'react-i18next'
import { Button } from "reactstrap";
import { service } from "../api";
import detectBrowserLanguage from "detect-browser-language";
import { countries, countries_eng } from "../assets/countries.js";
function LiveMarket() {
const { t, i18n } = useTranslation()
  const [isValid, setIsValid] = React.useState(false);
  const [isNameValid, setIsNameValid] = React.useState(true);
  const [isServiceValid, setIsServiceValid] = React.useState(true);
  const [isInquryValid, setIsInquryValid] = React.useState(true);
  const [isCountryValid, setIsCountryValid] = React.useState(true);
  const [isEmailValid, setIsEmailValid] = React.useState(true);
  const [isEmailFormatValid, setIsEmailFormatValid] = React.useState(true);
  const [isPhoneValid, setIsPhoneValid] = React.useState(true);
  const [isTypeValid, setIsTypeValid] = React.useState(true);
  const [isAddrValid, setIsAddrValid] = React.useState(true);
  const [isDetailValid, setIsDetailValid] = React.useState(true);
  const [inquryValue, setInquryValue] = React.useState('');
  const [serviceValue, setServiceValue] = React.useState('');
  const [typeValue, setTypeValue] = React.useState('');
  const [countryValue, setCountryValue] = React.useState('');
  const [curPosition, setCurPosition] = React.useState(0);
  const [curLang, setCurLang] = React.useState(i18n.language.substring(0,2) === 'ko' || !i18n.language && detectBrowserLanguage().substring(0,2) === 'ko' ? 'ko' : '')
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    if(curPosition === 0) {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    }
    setCurLang(i18n.language.substring(0,2) === 'ko' || !i18n.language && detectBrowserLanguage().substring(0,2) === 'ko' ? 'ko' : '')
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
    
  }, [curPosition, i18n.language]);
  let nameRef = React.createRef();
  let emailRef = React.createRef();
  let phoneRef = React.createRef();
  let addressRef = React.createRef();
  let ownCattleRef = React.createRef();
  let detailRef = React.createRef();
  let selectInqury = (e) => {
    setCurPosition(document.documentElement.scrollTop)
    setInquryValue(e.target.value)
  }
  let selectService = (e) => {
    setCurPosition(document.documentElement.scrollTop)
    setServiceValue(e.target.value)
  }
  let selectCountry = (e) => {
    setCurPosition(document.documentElement.scrollTop)
    setCountryValue(e.target.value)
  }
  let selectType = (e) => {
    setCurPosition(document.documentElement.scrollTop)
    setTypeValue(e.target.value)
  }
  const handleSubmit = async (e) => {
      setCurPosition(document.documentElement.scrollTop)
      e.preventDefault()
      let valid = true;
        if(nameRef.current.value === '') {
            setIsNameValid(false)
            valid = false
        } else {
            setIsNameValid(true)
        }
        if(serviceValue === '') {
            setIsServiceValid(false)
            valid = false
        } else {
            setIsServiceValid(true)
        }
        if(inquryValue === '') {
            setIsInquryValid(false)
            valid = false
        } else {
            setIsInquryValid(true)
        }
        if(countryValue === '') {
            setIsCountryValid(false)
            valid = false
        } else {
            setIsCountryValid(true)
        }
        if(emailRef.current.value === '') {
            setIsEmailValid(false)
            valid = false
        } else {
            setIsEmailValid(true)
        }
        if(validateEmail(emailRef.current.value)) {
            setIsEmailFormatValid(true)
        } else {
            setIsEmailFormatValid(false)
            valid = false
        }
        if(phoneRef.current.value === '') {
            setIsPhoneValid(false)
            valid = false
        } else {
            setIsPhoneValid(true)
        }
        if(typeValue === '') {
            setIsTypeValid(false)
            valid = false
        } else {
            setIsTypeValid(true)
        }
        if(addressRef.current.value === '') {
            setIsAddrValid(false)
            valid = false
        } else {
            setIsAddrValid(true)
        }
        if(detailRef.current.value === '') {
            setIsDetailValid(false)
            valid = false
        } else {
            setIsDetailValid(true)
        }
        if(valid) {
            const response = await service(inquryValue, serviceValue, nameRef.current.value, countryValue, emailRef.current.value, phoneRef.current.value, ownCattleRef.current.value, typeValue, addressRef.current.value, detailRef.current.value)
            if(response.result === "200") {
                setIsValid(true)
            } else{
                setIsValid(false)
            }
        } else {
            setIsValid(false)
        }
  }
  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
  return (
    <>
    <div>
        <img src={require("assets/img/market_logo.png")} style={{left: '5%', margin: '20px 0px 0px 40px'}}/>
        <hr></hr>
    </div>
      <div className="wrapper">
        <div className="main">
          <div className="culture_section privacy_secion help_service_section" style={{paddingBottom:'40px'}}>
            <p className="privacy_title service_title" style={{color:'#000000'}}>{t('서비스 문의')}</p>
            <div className="privacy_detail announce_detail_part">
                <div className="help_service_form">
                    <form onSubmit={handleSubmit}>
                        <div className="service_multi_form">
                            <div className="form-group">
                                <label htmlFor="inqury">{t('문의 종류')}*</label>
                                <select className="form-control" id="inqury" onChange={selectInqury}>
                                    <option value="">{t('문의 종류')}</option>
                                    <option value="E">{t('기존 사용자')}: {t('서비스 이용 문의')}</option>
                                    <option value="O">{t('잠재 사용자')}: {t('서비스 도입 문의')}</option>
                                    <option value="R">{t('기타')}</option>
                                </select>
                                {
                                    !isInquryValid ?
                                    <p className="form_error">{t('문의 종류를 선택하세요.')}</p>
                                    :
                                    null
                                }
                            </div>
                            <div className="form-group">
                                <label htmlFor="service">{t('서비스명')}*</label>
                                <select className="form-control" id="service" onChange={selectService}>
                                    <option value="">{t('서비스명')}</option>
                                    <option value="L">{t('라이브케어')}</option>
                                    <option value="P">{t('캡슐 구매')}</option>
                                    <option value="E">{t('기타')}</option>
                                </select>
                                {
                                    !isServiceValid ?
                                    <p className="form_error">{t('문의하려는 서비스를 선택하세요.')}</p>
                                    :
                                    null
                                }
                            </div>
                        </div>
                        <div className="service_multi_form">
                            <div className="form-group">
                                <label htmlFor="name">{t('이름')}*</label>
                                <input type="text" ref={nameRef} className="form-control" id="name" placeholder={t('이름')} />
                                {
                                    !isNameValid ?
                                    <p className="form_error">{t('이름을 입력하세요.')}</p>
                                    :
                                    null
                                }
                            </div>
                            <div className="form-group">
                                <label htmlFor="country">{t('국가')}*</label>
                                <select className="form-control" id="country" onChange={selectCountry}>
                                    <option value="">{t('국가')}</option>
                                    {
                                        curLang === 'ko' ?
                                        countries.map((country, index) => {
                                            return <option key={index} value={country.name}>{country.name}</option>
                                        })
                                        :
                                        countries_eng.map((country, index) => {
                                            return <option key={index} value={country.name}>{country.name}</option>
                                        })
                                    }
                                </select>
                                {
                                    !isCountryValid ?
                                    <p className="form_error">{t('국가를 선택하세요.')}</p>
                                    :
                                    null
                                }
                            </div>
                        </div>
                        <div className="service_multi_form">
                            <div className="form-group">
                                <label htmlFor="email">{t('이메일 주소')}*</label>
                                <input type="email" ref={emailRef} className="form-control" id="email" placeholder={t('이메일 주소')} />
                                {
                                    !isEmailValid ?
                                    <p className="form_error">{t('이메일 주소를 입력하세요.')}</p>
                                    :
                                    !isEmailFormatValid ?
                                    <p className="form_error">{t('올바른 형식이 아닙니다.')}</p>
                                    :
                                    null
                                }
                            </div>
                            <div className="form-group">
                                <label htmlFor="phone">{t('전화번호')}*</label>
                                <input type="text" ref={phoneRef} className="form-control" id="phone" placeholder={t('전화번호')} />
                                {
                                    !isPhoneValid ?
                                    <p className="form_error">{t('전화번호를 입력하세요.')}</p>
                                    :
                                    null
                                }
                            </div>
                        </div>
                        <div className="service_multi_form">
                            <div className="form-group">
                                <label htmlFor="name">{t('보유 두 수')}</label>
                                <input type="number" ref={ownCattleRef} className="form-control" id="own_cattle" placeholder={t('보유 두 수')} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="type">{t('품종')}*</label>
                                <select className="form-control" id="type" onChange={selectType}>
                                    <option value="">{t('품종')}</option>
                                    <option value="MK">{t('젖소')}</option>
                                    <option value="BR">{t('번식우')}</option>
                                    <option value="BE">{t('비육우')}</option>
                                    <option value="E">{t('기타')}</option>
                                </select>
                                {
                                    !isTypeValid ?
                                    <p className="form_error">{t('품종을 선택하세요.')}</p>
                                    :
                                    null
                                }
                            </div>
                        </div>
                        <div className="service_multi_form">
                            <div className="form-group">
                                <label htmlFor="address">{t('주소')}*</label>
                                <input type="text" ref={addressRef} className="form-control" id="address" placeholder={t('주소')} />                                
                                {
                                    !isAddrValid ?
                                    <p className="form_error">{t('주소를 입력하세요.')}</p>
                                    :
                                    null
                                }
                            </div>
                        </div>
                        <div className="service_multi_form">
                            <div className="form-group">
                                <label htmlFor="detail">{t('문의 내용')}*</label>
                                <textarea className="form-control" ref={detailRef} id="detail" rows="6" placeholder={t('문의 내용을 입력하세요.')}></textarea>
                                {
                                    !isDetailValid ?
                                    <p className="form_error">{t('문의 내용을 입력하세요.')}</p>
                                    :
                                    null
                                }
                            </div>
                        </div>
                    </form>
                </div>
                <div className="service_btn">
                    {
                        isValid ?
                        <p className="accept_form_success">{t('문의가 접수되었습니다. 최대한 빠른 시일안에 연락드리겠습니다.')}</p>
                        :
                        null
                    }
                    
                    <Button className="livemarketHover" onClick={(e) => handleSubmit(e)} >{t('문의하기')}</Button>
                </div>
            </div>
          </div>
        </div>

        <div className="marketServiceCompanyDiv">
            <div style={{color:'#00000', fontSize:'15px'}}>C O M P A N Y</div>
            <div className="marketService">Corporate Name uLikeKorea Co., Inc. CEO HEEJIN KIM</div>
            <div className="marketService">Business Registration No. 220-88-47698</div>
            <div className="marketService">E-commerce Permit 제 2016-서울송파-1001호</div>
            <div className="marketService">Phone 82+2-2043-5300</div>
            <div className="marketService">Address 26, BEOBWON-RO 9-GIL, SONGPA-GU, SEOUL, REPUBLIC OF KOREA 1202~1206, BLDG. D, H BUSINESS PARK</div>
            <div className="marketService">Personal Information Manager uLikeKorea (info@ulikekorea.com)</div>
        
            <div className="marketServiceBtnDiv">
                <a href="https://livecaremarket.com/index.html" className="marketServiceBtn">Service confirmation</a>
                <a href="https://livecaremarket.com/member/agreement.html" className="marketServiceBtn">Terms and Conditions</a>
                <a href="https://livecaremarket.com/member/privacy.html" className="marketServiceBtn">Privacy Policy</a>
                <a href="https://livecaremarket.com/shopinfo/guide.html" className="marketServiceBtn">Help</a>
            </div>
        </div>
      </div>
    </>
  );
}

export default LiveMarket;