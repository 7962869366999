import React from "react";
import OtherNavbar from "components/Navbars/OtherNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import Path from "components/Path.js";
import { useTranslation } from 'react-i18next'

import { Row, Col } from "reactstrap";
import CultureCard from "../components/CultureCard.js";
import CultureType from "../components/CultureType.js";
import WorkBenefit from "../components/WorkBenefit.js";

function Culture() {
  const { t } = useTranslation()
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <OtherNavbar />
      <div className="wrapper">
        <div className="main">
          <Path firstPathTitle={t('홈')} firstPath="/" secondPathTitle={t('인재영입')} secondPath="/culture" thirdPathTitle={t('조직문화')}/>
          <div className="culture_section culture_work">
              <p className="title">{t('일하는 방식')}</p>
              <p className="subtitle">{t('우리는 이렇게 업무에 몰두합니다')}</p>
              <p className="description bottom-0">{t('동물과 기술을 좋아하는 사람들이 모인 곳, 유라이크코리아에서는 자기주도적으로 문제를 해결하고 가치를 창출하고 있습니다.')}</p>
              <p className="description bottom-0">{t('개인의 역량을 키우면서 스마트하게 일하는 방식의 중요성을 믿습니다.')}</p>
          </div>
          <div style={{position: 'relative', display: 'flex'}}>
              <img alt="" className="culture_img" src={require("assets/img/culture-bg.jpg")} />
              <div className="ai_section">
                <div className="culture_section culture_type_part">
                  <Row>
                    <Col md="4">
                      <CultureType title={t("자기주도적인 문제해결")} description={t('보다 나은 해결책을 스스로 고민합니다')} />
                    </Col>
                    <Col md="4">
                      <CultureType title={t("업무에 대한 주인 의식")} description={t('스스로 동기부여하여 업무를 주도합니다')} />
                    </Col>
                    <Col md="4">
                      <CultureType title={t("데이터 중심 의사 결정")} description={t('데이터를 활용하여 합리적으로 결정합니다')} />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <CultureType title={t("협업과 오픈마인드")} description={t('꾸준히 소통하여 가치를 공유합니다')} />
                    </Col>
                    <Col md="4">
                      <CultureType title={t("지속적인 역량 개발")} description={t('개인의 역량과 조직이 함께 성장합니다')} />
                    </Col>
                    <Col md="4">
                      <CultureType title={t("고객 신뢰와 책임감")} description={t('고객이 신뢰하는 서비스를 위해 책임을 다합니다')} />
                    </Col>
                  </Row>
                </div>
                <div className="culture_section culture_type_part culture_type_part_mobile">
                  <Row>
                    <Col sm="6">
                      <CultureType title={t("자기주도적인 문제해결")} description={t('보다 나은 해결책을 스스로 고민합니다')} />
                    </Col>
                    <Col sm="6">
                      <CultureType title={t("업무에 대한 주인 의식")} description={t('스스로 동기부여하여 업무를 주도합니다')} />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="6">
                      <CultureType title={t("데이터 중심 의사 결정")} description={t('데이터를 활용하여 합리적으로 결정합니다')} />
                    </Col><Col sm="6">
                      <CultureType title={t("협업과 오픈마인드")} description={t('꾸준히 소통하여 가치를 공유합니다')} />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="6">
                      <CultureType title={t("지속적인 역량 개발")} description={t('개인의 역량과 조직이 함께 성장합니다')} />
                    </Col>
                    <Col sm="6">
                      <CultureType title={t("고객 신뢰와 책임감")} description={t('고객이 신뢰하는 서비스를 위해 책임을 다합니다')} />
                    </Col>
                  </Row>
                </div>
              </div>
          </div>
          <div className="culture_section culture_work culture_together">
              <p className="title">{t('함께 만드는 문화')}</p>
              <p className="subtitle">{t('우리는 함께 성장합니다')}</p>
              <p className="description bottom-0">{t('맡은 업무를 진정 즐길 줄 아는 사람이 인생의 주인공이라 믿습니다.유라이크코리아는 구성원이 유연한 분위기에서 최적의 업무 성과를 발휘할 수 있도록 적극적으로 지원하고 있습니다.')}</p>
          </div>
          <div className="culture_section culture_card_part">
              <Row>
                  <Col md="4">
                    <CultureCard imgSrc={"culture_1.png"} title={t("스스로 찾는 목표의식")} description={t("능력있는 동료와 서로 배우고 같이 성장하는 문화를 지향합니다")} />
                  </Col>
                  <Col md="4">
                    <CultureCard imgSrc={"culture_2.png"} title={t("일할 땐 일하고, 놀 땐 놀고")} description={t("열심히 일한 당신들을 위해 준비했습니다. 업무 외에도 공동의 재미를 찾습니다")} />
                  </Col>
                  <Col md="4">
                    <CultureCard imgSrc={"culture_3.png"} title={t("개인의 가치를 인정")} description={t("신입이든 경력이든 각자가 보유한 역량을 인정하고 응원합니다")} />
                  </Col>
              </Row>
              <Row className="culture_btw">
                  <Col md="4">
                    <CultureCard imgSrc={"culture_4.png"} title={t("자유로운 지식 공유")} description={t("경험과 지식을 나눌수록 가치는 커집니다. 자유로운 지식 공유 환경을 지원합니다")} />
                  </Col>
                  <Col md="4">
                    <CultureCard imgSrc={"culture_5.png"} title={t("건강한 몸과 마음을 응원")} description={t("나이, 직함 상관없이 모두가 함께 건강한 삶을 응원합니다")} />
                  </Col>
                  <Col md="4">
                    <CultureCard imgSrc={"culture_6.png"} title={t("회식문화 개선")} description={t("과도한 음주문화는 지양합니다. 건강하고 맛있는 음식이 함께 합니다")} />
                  </Col>
              </Row>
          </div>
          <div className="culture_section culture_card_part_mbbile">
            <CultureCard mobile={true} imgSrc={"culture_1.png"} title={t("스스로 찾는 목표의식")} description={t("능력있는 동료와 서로 배우고 같이 성장하는 문화를 지향합니다")} />
            <CultureCard mobile={true} imgSrc={"culture_2.png"} title={t("일할 땐 일하고, 놀 땐 놀고")} description={t("열심히 일한 당신들을 위해 준비했습니다. 업무 외에도 공동의 재미를 찾습니다")} />
            <CultureCard mobile={true} imgSrc={"culture_3.png"} title={t("개인의 가치를 인정")} description={t("신입이든 경력이든 각자가 보유한 역량을 인정하고 응원합니다")} />
            <CultureCard mobile={true} imgSrc={"culture_4.png"} title={t("자유로운 지식 공유")} description={t("경험과 지식을 나눌수록 가치는 커집니다. 자유로운 지식 공유 환경을 지원합니다")} />
            <CultureCard mobile={true} imgSrc={"culture_5.png"} title={t("건강한 몸과 마음을 응원")} description={t("나이, 직함 상관없이 모두가 함께 건강한 삶을 응원합니다")} />
            <CultureCard mobile={true} bottom={true} imgSrc={"culture_6.png"} title={t("회식문화 개선")} description={t("과도한 음주문화는 지양합니다. 건강하고 맛있는 음식이 함께 합니다")} />
          </div>
          <div className="culture_section culture_work culture_work_benefit">
              <p className="title">{t('근무 혜택')}</p>
              <p className="subtitle">{t('우리는 함께 성장합니다')}</p>
              <p className="description bottom-0">{t('맡은 업무를 진정 즐길 줄 아는 사람이 인생의 주인공이라 믿습니다.유라이크코리아는 구성원이 유연한 분위기에서 최적의 업무 성과를 발휘할 수 있도록 적극적으로 지원하고 있습니다.')}</p>

              <div className="work_benefit_part">
                <WorkBenefit imgSrc={"work_benfit_1.svg"} title={t("복리후생비 별도 지원")} description={t("든든하게 건강하게 일하는 회사!")} />
                <WorkBenefit imgSrc={"work_benfit_2.svg"} title={t("생일자 조기퇴근 및 축하선물")} description={'"'+t('생일자의 오후는 소중합니다. 진심으로 축하하는 문화')+'"'} />
                <WorkBenefit imgSrc={"work_benfit_3.svg"} title={t("해외 전시회 및 국내세미나 참가 지원")} description={'"'+t('업무 전문성도 함께 키워가야죠. 국내외 주요 전시회 또는 세미나 참석을 지원합니다.')+'"'} />
                <WorkBenefit imgSrc={"work_benfit_4.svg"} title={t("문화의 날 행사 (분기) 및 워크샵, 월간 생일파티")} description={'"'+t('모든 직원이 함께 만들어가는 즐겁게 일하는 문화를 우선으로 합니다.')+'"'} />
                <WorkBenefit imgSrc={"work_benfit_5.svg"} title={t("사내 동호회 지원")} description={'"'+t('직급 상관없이 공통의 관심사로 모여 즐거움을 나눕니다')+'"'} />
                <WorkBenefit imgSrc={"work_benfit_6.svg"} title={t("금요일 조기퇴근제")} description={'"'+t('가족 그리고 친구들과의 신나는 불금을 위해 조기 퇴근하세요!')+'"'} />
                <WorkBenefit imgSrc={"work_benfit_7.svg"} title={t("우수사원 포상")} description={'"'+t('열심히 일한 직원들에게는 진심어린 격려를 드립니다!')+'"'} />
                <WorkBenefit imgSrc={"work_benfit_8.svg"} title={t("개인 경조금 지원")} description={'"'+t('기쁜 일은 더 기쁘게, 슬픈 일도 같이 나눌 수 있어야 합니다.')+'"'} />
                <WorkBenefit imgSrc={"work_benfit_9.svg"} title={t("카페테리아 및 릴렉스룸 운영")} description={'"'+t('모든 직원의 취향을 존중하는 건강한 음료와 릴렉스룸에서 한 템포 쉬어가세요!')+'"'} />
                <WorkBenefit imgSrc={"work_benfit_10.svg"} title={t("개인사물함 제공")} description={'"'+t('개별 전자 사물함에 개인 물품을 안전히 보관하세요!')+'"'} />
              </div>
          </div>
        </div>
        <DarkFooter />
      </div>
    </>
  );
}

export default Culture;