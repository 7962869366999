import React, { useEffect } from "react";
import ManagerNavbar from "../../../components/Navbars/ManagerNavbar";
import Aside from "../../../components/Navbars/Aside";
import { Pagination, PaginationItem, PaginationLink, Table } from "reactstrap";
import { useHistory } from "react-router-dom";

const StockDetail = (props) => {
  const [detailData, setDetailData] = React.useState([]);
  var a = props.location.pathname;
  var b = a.substr(21);

  const history = useHistory();
  useEffect(() => {
    let islogin = JSON.parse(localStorage.getItem("name"));
    if (islogin !== null) {
      console.log("Y");
    } else {
      history.push("/manage");
    }
    fetchData();
  }, [fetchData, history]);

  const backBtn = () => {
    history.goBack(1);
  };
  const fetchData = () => {
    props.location.state.map((detail, index) => {
      if (detail.id === b) {
        setDetailData(detail);
      }
    });
  };



  if(detailData.contactTime == "E" ){ 
    var contactTimes = "09 ~ 12"
} else if(detailData.contactTime == "M") {
    var contactTimes = "12 ~ 15"
} else{
    var contactTimes = "15 ~ 18"

}

  

  return (
    <>
      <ManagerNavbar />
      <div style={{ display: "flex" }}>
        <Aside></Aside>
        <div
          style={{
            backgroundColor: "#EEF1F3",
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            minWidth: "600px",
          }}
        >
          <div
            style={{
              width: "80%",
              margin: "0 auto",
              position: "absolute",
              top: "150px",
              overflowY: "scroll",
              height: "80vh",
            }}
          >
            <div>
              <span onClick={backBtn} style={{ position: "relative" }}>
                뒤로가기
              </span>
            </div>

            <span className="servieSubTitle">주식 양도신청</span>
            <Table className="manager_table">
              <tbody>
                <tr>
                  <td className="serviceDetailTd">이름</td>
                  <td>{detailData.user_name}</td>
                </tr>
                <tr>
                  <td className="serviceDetailTd">전화번호</td>
                  <td>{detailData.phone}</td>

                </tr>
                <tr>
                  <td className="serviceDetailTd">이메일</td>
                  <td>{detailData.email}</td>

                </tr>
                <tr>
                  <td className="serviceDetailTd">예상매도가능 주식수</td>
                  <td>{detailData.stockCount}</td>
                </tr>
                <tr>
                  <td className="serviceDetailTd">연락 선호 시간</td>
                  <td>{contactTimes}시</td>
                </tr>
              
                <tr className="contentTd" style={{ padding: "0px" }}>
                  <td className="contentTd serviceDetailTd">문의내용</td>
                  <td className="contentTd">{detailData.contents}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};

export default StockDetail;
