import React, { useEffect } from "react";
import {
  Button,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
} from "reactstrap";
import { Link, useHistory, Route } from "react-router-dom";
import { press_get } from "../../../api";
import NewPress from "./NewPress";
import DeleteModal from "../../DeleteModal";

const PressTableContent = (props) => {
  const history = useHistory();

  return props.data.map((detail, index) => {

    let title = detail.title;
    let date_create = detail.date_create; //문의일
    let id = detail.id;
    return (
      <tr>
        <td
          key={index}
          onClick={() => history.push(`/manager/pressdetail/${id}`, props.data)}
        >
          {title}
        </td>
        <td>{date_create}</td>
        <td
          onClick={() =>
            history.push(`/manager/pressdetailedit/${id}`, props.data)
          }
        >
          수정
        </td>
        <td>
          <DeleteModal deletePath={"press?"} deleteId={detail.id} />
        </td>
      </tr>
    );
  });
};

const PressTable = (props) => {
  const [curPage, setCurPage] = React.useState(1);
  const [list, setList] = React.useState(props.data);

  useEffect(() => {
    setList(props.data);
  }, [props]);

  const callData = async (page) => {
    if (curPage !== page) {
      setCurPage(page);
      const response = await press_get(props.language, page, 15);
      if (response.result === "200") {
        setList(response.data);
      }
    }
  };

  const history = useHistory();
  return (
    <div style={{ backgroundColor: "#EEF1F3", width: "100%", height: "105vh" }}>
      <div
        style={{
          width: "80%",
          margin: "0 auto",
          position: "relative",
          top: "150px",
        }}
      >
        <Link
          to={{ pathname: "/manager/newpress", state: props.language }}
          style={{ color: "black", float: "right", marginBottom: "5px" }}
        >
          신규작성
        </Link>

        <Table className=" manager_table">
          <thead>
            <tr>
              <th>제목</th>
              <th>등록일자</th>
              <th>수정</th>
              <th>삭제</th>
            </tr>
          </thead>
          <tbody>
            {list && list.length > 0 ? (
              <PressTableContent data={list} cur_page={curPage} />
            ) : null}
          </tbody>
        </Table>

        <div style={{ textAlign: "center" }}>
          <Pagination>
            <PaginationItem>
              <PaginationLink
                aria-label="First"
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  callData(1);
                }}
              >
                <span aria-hidden={true}>
                  <i aria-hidden={true} className="fa fa-angle-double-left"></i>
                </span>
              </PaginationLink>
            </PaginationItem>
            <PaginationItem>
              <PaginationLink
                aria-label="Previous"
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  callData(curPage > 1 ? curPage - 1 : 1);
                }}
              >
                <span aria-hidden={true}>
                  <i aria-hidden={true} className="fa fa-angle-left"></i>
                </span>
              </PaginationLink>
            </PaginationItem>

            <span className="curPage">{curPage}</span>
            <span>/</span>
            <span className="lastPage">{props.total}</span>

            <PaginationItem>
              <PaginationLink
                aria-label="Next"
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  callData(curPage < props.total ? curPage + 1 : props.total);
                }}
              >
                <span aria-hidden={true}>
                  <i aria-hidden={true} className="fa fa-angle-right"></i>
                </span>
              </PaginationLink>
            </PaginationItem>

            <PaginationItem>
              <PaginationLink
                aria-label="Last"
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  callData(props.total);
                }}
              >
                <span aria-hidden={true}>
                  <i
                    aria-hidden={true}
                    className="fa fa-angle-double-right"
                  ></i>
                </span>
              </PaginationLink>
            </PaginationItem>
          </Pagination>
        </div>
      </div>
    </div>
  );
};

export default PressTable;
