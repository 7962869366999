import React, { useEffect, useState } from "react";
import OtherNavbar from "components/Navbars/OtherNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import Path from "components/Path.js";
import { useTranslation } from "react-i18next";
import { Row, Col, Button } from "reactstrap";
import NewsCard from "../components/NewsCard";
import "react-modal-video/scss/modal-video.scss";
import ModalVideo from "react-modal-video";
import "react-alice-carousel/lib/alice-carousel.css";
import AliceCarousel from "react-alice-carousel";
import { press_detail } from "../api";
import detectBrowserLanguage from "detect-browser-language";
import { useDispatch } from "react-redux";
import {advtsDetailAction} from '../actions/advtsAction'


function renderPress(data, count, position) {

  if (position > -1) window.scrollTo(0, position);
  if (data.length > 0)
    return data.map((detail, index) => {
      
      if (index < count) {
        return (
          <Col key={index} md="4">
            <NewsCard
              imgSrc={detail.image}
              title={detail.title}
              data={data}
              detail={detail}
              number={index + 1}
              langCode={detail.language}
              no={detail.id}
            />
          </Col>
        );
      }
    });
}

function Advts(props) {
  const [isOpen, setOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const [advtsCount, setAdvtsCount] = React.useState(9);
  const [curPosition, setCurPosition] = React.useState(0);
  const [videoId, setVideoId] = React.useState("");
  const [thumbItems, setThumbItems] = React.useState([]);
  const [pressENList, setPressENList] = React.useState([]);
  const [pressKOList, setPressKOList] = React.useState([]);
  const [pressJPList, setPressJPList] = React.useState([]);
  const [pressPTList, setPressPTList] = React.useState([]);
  const [curLang, setCurLang] = React.useState(
    localStorage.getItem("lang")
      ? localStorage.getItem("lang")
      : detectBrowserLanguage().substring(0, 2)
  );
 
  let openVideo = (videoId) => {
    setCurPosition(document.documentElement.scrollTop);
    setVideoId(videoId);
    setOpen(true);
  };
  const mainKO = "w90p98lkFUU";
  const mainEN = "bPbkIuMENGk";
  const mainJP = "9qEn4ElV1zw";
  const mainPT = "lhhcVeAdzJY";

  const itemsKO = [
    <img
    alt=""
    src="https://img.youtube.com/vi/B6nWhbrfD8A/0.jpg"
    onClick={() => {
      openVideo("B6nWhbrfD8A");
    }}
    />,
    <img
      alt=""
      src="https://img.youtube.com/vi/O8d-MKYxcjg/0.jpg"
      onClick={() => {
        openVideo("O8d-MKYxcjg");
      }}
    />,
    <img
      alt=""
      src="https://img.youtube.com/vi/JgU_q5SR0PE/0.jpg"
      onClick={() => {
        openVideo("JgU_q5SR0PE");
      }}
    />,
    <img
      alt=""
      src="https://img.youtube.com/vi/yH9awGWQLiU/0.jpg"
      onClick={() => {
        openVideo("yH9awGWQLiU");
      }}
    />,
    <img
      alt=""
      src="https://img.youtube.com/vi/BGXhjAfOJVs/0.jpg"
      onClick={() => {
        openVideo("BGXhjAfOJVs");
      }}
    />,
    <img
      alt=""
      src="https://img.youtube.com/vi/GRJ-PSagXlQ/0.jpg"
      onClick={() => {
        openVideo("GRJ-PSagXlQ");
      }}
    />,
    <img
      alt=""
      src="https://img.youtube.com/vi/REEn-pEoodQ/0.jpg"
      onClick={() => {
        openVideo("REEn-pEoodQ");
      }}
    />,
    <img
      alt=""
      src="https://img.youtube.com/vi/JEsAd_lY9IY/0.jpg"
      onClick={() => {
        openVideo("JEsAd_lY9IY");
      }}
    />,
    <img
      alt=""
      src="https://img.youtube.com/vi/7XH-_dv-X3g/0.jpg"
      onClick={() => {
        openVideo("7XH-_dv-X3g");
      }}
    />,
    <img
      alt=""
      src="https://img.youtube.com/vi/K7Zoi1WyCvQ/0.jpg"
      onClick={() => {
        openVideo("K7Zoi1WyCvQ");
      }}
    />,
    <img
      alt=""
      src="https://img.youtube.com/vi/e-11c1ev3GE/0.jpg"
      onClick={() => {
        openVideo("e-11c1ev3GE");
      }}
    />,
    <img
      alt=""
      src="https://img.youtube.com/vi/YBFOJcq48bU/0.jpg"
      onClick={() => {
        openVideo("YBFOJcq48bU");
      }}
    />,
    <img
      alt=""
      src="https://img.youtube.com/vi/jN3N46FVNqU/0.jpg"
      onClick={() => {
        openVideo("jN3N46FVNqU");
      }}
    />,
    
  ];

  const itemsEN = [
    <img
      alt=""
      src="https://img.youtube.com/vi/yH9awGWQLiU/0.jpg"
      onClick={() => {
        openVideo("yH9awGWQLiU");
      }}
    />,
    <img
      alt=""
      src="https://img.youtube.com/vi/LWwf6z7OkE0/0.jpg"
      onClick={() => {
        openVideo("LWwf6z7OkE0");
      }}
    />,
    <img
      alt=""
      src="https://img.youtube.com/vi/X8I8SaUFSZo/0.jpg"
      onClick={() => {
        openVideo("X8I8SaUFSZo");
      }}
    />,
    <img
      alt=""
      src="https://img.youtube.com/vi/T9Vc8ZftaZQ/0.jpg"
      onClick={() => {
        openVideo("T9Vc8ZftaZQ");
      }}
    />,
    <img
      alt=""
      src="https://img.youtube.com/vi/OnAiz414oAE/0.jpg"
      onClick={() => {
        openVideo("OnAiz414oAE");
      }}
    />,
    <img
      alt=""
      src="https://img.youtube.com/vi/VRqS-meyuYA/0.jpg"
      onClick={() => {
        openVideo("VRqS-meyuYA");
      }}
    />,
    <img
      alt=""
      src="https://img.youtube.com/vi/lxBt9i1reJk/0.jpg"
      onClick={() => {
        openVideo("lxBt9i1reJk");
      }}
    />,
    <img
      alt=""
      src="https://img.youtube.com/vi/oFRlXuKES8k/0.jpg"
      onClick={() => {
        openVideo("oFRlXuKES8k");
      }}
    />,
    <img
      alt=""
      src="https://img.youtube.com/vi/ANVUSsmA4uE/0.jpg"
      onClick={() => {
        openVideo("ANVUSsmA4uE");
      }}
    />,
  ];

  const itemsJP = [
    <img
      alt=""
      src="https://img.youtube.com/vi/yH9awGWQLiU/0.jpg"
      onClick={() => {
        openVideo("yH9awGWQLiU");
      }}
    />,
    <img
      alt=""
      src="https://img.youtube.com/vi/lz0-_i0p9Ck/0.jpg"
      onClick={() => {
        openVideo("lz0-_i0p9Ck");
      }}
    />,
    <img
      alt=""
      src="https://img.youtube.com/vi/vmfstTFwbSU/0.jpg"
      onClick={() => {
        openVideo("vmfstTFwbSU");
      }}
    />,
    <img
      alt=""
      src="https://img.youtube.com/vi/8MYTd5KFSuU/0.jpg"
      onClick={() => {
        openVideo("8MYTd5KFSuU");
      }}
    />,
    <img
      alt=""
      src="https://img.youtube.com/vi/3YnOetY9CoE/0.jpg"
      onClick={() => {
        openVideo("3YnOetY9CoE");
      }}
    />,
    <img
      alt=""
      src="https://img.youtube.com/vi/Hk12GukX9rs/0.jpg"
      onClick={() => {
        openVideo("Hk12GukX9rs");
      }}
    />,
    <img
      alt=""
      src="https://img.youtube.com/vi/J3evovlqqcg/0.jpg"
      onClick={() => {
        openVideo("J3evovlqqcg");
      }}
    />,
    <img
      alt=""
      src="https://img.youtube.com/vi/ft1gyszKUzk/0.jpg"
      onClick={() => {
        openVideo("ft1gyszKUzk");
      }}
    />,
    <img
      alt=""
      src="https://img.youtube.com/vi/iXZnyXhDn4k/0.jpg"
      onClick={() => {
        openVideo("iXZnyXhDn4k");
      }}
    />,
  ];

  const itemsPT = [
    <img
      alt=""
      src="https://img.youtube.com/vi/yH9awGWQLiU/0.jpg"
      onClick={() => {
        openVideo("yH9awGWQLiU");
      }}
    />,
    <img
      alt=""
      src="https://img.youtube.com/vi/BHxbodgBgz8/0.jpg"
      onClick={() => {
        openVideo("BHxbodgBgz8");
      }}
    />,
    <img
      alt=""
      src="https://img.youtube.com/vi/5EIwSBTVoYc/0.jpg"
      onClick={() => {
        openVideo("5EIwSBTVoYc");
      }}
    />,
    <img
      alt=""
      src="https://img.youtube.com/vi/BZCrxX3c9YA/0.jpg"
      onClick={() => {
        openVideo("BZCrxX3c9YA");
      }}
    />,
    <img
      alt=""
      src="https://img.youtube.com/vi/zoef_YZ8snM/0.jpg"
      onClick={() => {
        openVideo("zoef_YZ8snM");
      }}
    />,
    <img
      alt=""
      src="https://img.youtube.com/vi/K-dpZqh18PQ/0.jpg"
      onClick={() => {
        openVideo("K-dpZqh18PQ");
      }}
    />,
    <img
      alt=""
      src="https://img.youtube.com/vi/YwkxeW05Ldg/0.jpg"
      onClick={() => {
        openVideo("YwkxeW05Ldg");
      }}
    />,
  ];

  const [Carousel, setCarousel] = useState(null);
  const responsive = {
    0: { items: 2 },
    568: { items: 2 },
    1024: { items: 3 },
  };
  React.useEffect(() => {
    if (props.location.hash === "#pr_section" && curPosition === 0) {
      window.location.hash = "#pr_section";
      setCurPosition(1200);
    }
    setCurLang(
      i18n.language === "ko"
        ? "ko"
        : i18n.language === "ja"
        ? "ja"
        : i18n.language === "pt"
        ? "pt"
        : "en"
    );
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");

    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, [props.location.hash, curPosition, i18n.language]);
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    const response = await press_detail();
    if (
      response.result === "200" &&
      response.data &&
      response.data.length > 0
    ) {
      let tempE = [];
      let tempK = [];
      let tempJ = [];
      let tempP = [];
      response.data.map((item) => {

        if (item.language === "en") {
          tempE.push(item);
        } else if (item.language === "ko") {
          tempK.push(item);
        } else if (item.language === "ja") {
          tempJ.push(item);
        } else if (item.language === "pt") {
          tempP.push(item);
        }
      });
      setPressENList(tempE);
      setPressKOList(tempK);
      setPressJPList(tempJ);
      setPressPTList(tempP);
    }
  };
  const addCount = () => {
    let curPosition = document.documentElement.scrollTop;
    setCurPosition(curPosition);
    setAdvtsCount(advtsCount + 9);
  };

  return (
    <>
      <OtherNavbar />
      <div className="wrapper">
        <div className="main">
          <Path
            firstPathTitle={t("홈")}
            firstPath="/"
            secondPathTitle={t("홍보")}
          />
          <div className="culture_section culture_work help_section advts_title_section">
            <p className="subtitle" style={{ marginBottom: 0 }}>
              {t("홍보")}
            </p>
          </div>
          <div className="advts_section example_video">
            {curLang === "ko" ? (
              <div className="video_thumbnail">
                <img
                  alt=""
                  className="thumbnail_img"
                  src={"https://img.youtube.com/vi/" + mainKO + "/0.jpg"}
                />
                <div className="about-healthy play_btn_section">
                  <div className="play_btn">
                    <span></span>
                    <img
                      alt=""
                      src={require("assets/img/play.svg")}
                      onClick={(e) => {
                        openVideo(mainKO);
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : curLang === "en" ? (
              <div className="video_thumbnail">
                <img
                  alt=""
                  className="thumbnail_img"
                  src={"https://img.youtube.com/vi/" + mainEN + "/0.jpg"}
                />
                <div className="about-healthy play_btn_section">
                  <div className="play_btn">
                    <span></span>
                    <img
                      alt=""
                      src={require("assets/img/play.svg")}
                      onClick={(e) => {
                        openVideo(mainEN);
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : curLang === "ja" ? (
              <div className="video_thumbnail">
                <img
                  alt=""
                  className="thumbnail_img"
                  src={"https://img.youtube.com/vi/" + mainJP + "/0.jpg"}
                />
                <div className="about-healthy play_btn_section">
                  <div className="play_btn">
                    <span></span>
                    <img
                      alt=""
                      src={require("assets/img/play.svg")}
                      onClick={(e) => {
                        openVideo(mainJP);
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : curLang === "pt" ? (
              <div className="video_thumbnail">
                <img
                  alt=""
                  className="thumbnail_img"
                  src={"https://img.youtube.com/vi/" + mainPT + "/0.jpg"}
                />
                <div className="about-healthy play_btn_section">
                  <div className="play_btn">
                    <span></span>
                    <img
                      alt=""
                      src={require("assets/img/play.svg")}
                      onClick={(e) => {
                        openVideo(mainPT);
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </div>

          <div
            className="advts_section thumb_video"
            style={{ backgroundColor: "white" }}
          >
            <div
              className="arrow_thumb arrow_thumb_left"
              onClick={() => Carousel.slidePrev()}
            >
              <i className="now-ui-icons arrows-1_minimal-left"></i>
              &nbsp;&nbsp;&nbsp;<span>{t("이전")}</span>
            </div>
            {curLang === "en" ? (
              <AliceCarousel
                items={itemsEN}
                disableDotsControls={true}
                disableButtonsControls={true}
                infinite={true}
                responsive={responsive}
                mouseDragEnabled={true}
                ref={(el) => setCarousel(el)}
              />
            ) : curLang === "ko" ? (
              <AliceCarousel
                items={itemsKO}
                disableDotsControls={true}
                disableButtonsControls={true}
                infinite={true}
                responsive={responsive}
                mouseDragEnabled={true}
                ref={(el) => setCarousel(el)}
              />
            ) : curLang === "ja" ? (
              <AliceCarousel
                items={itemsJP}
                disableDotsControls={true}
                disableButtonsControls={true}
                infinite={true}
                responsive={responsive}
                mouseDragEnabled={true}
                ref={(el) => setCarousel(el)}
              />
            ) : curLang === "pt" ? (
              <AliceCarousel
                items={itemsPT}
                disableDotsControls={true}
                disableButtonsControls={true}
                infinite={true}
                responsive={responsive}
                mouseDragEnabled={true}
                ref={(el) => setCarousel(el)}
              />
            ) : null}

            <div
              className="arrow_thumb arrow_thumb_right"
              onClick={() => Carousel.slideNext()}
            >
              <span>{t("다음")}&nbsp;&nbsp;&nbsp;</span>
              <i className="now-ui-icons arrows-1_minimal-right"></i>
            </div>
          </div>

          <div
            className="section section-news pr_section"
            style={{ backgroundColor: "#f2f2f2" }}
            id="pr_section"
          >
            <p className="news_title">{t("주요 언론보도")}</p>
            <div className="culture_section advts_card_section">
              <Row>
                {curLang === "en"
                  ? renderPress(pressENList, advtsCount, curPosition) 
                  : curLang === "ko"
                  ? renderPress(pressKOList, advtsCount, curPosition)
                  : curLang === "ja"
                  ? renderPress(pressJPList, advtsCount, curPosition)
                  : curLang === "pt"
                  ? renderPress(pressPTList, advtsCount, curPosition)
                  : null}
              </Row>
              <Button className="more_btn" onClick={() => addCount()}>
                {t("더보기")}
              </Button>
            </div>
          </div>
          <ModalVideo
            channel="youtube"
            isOpen={isOpen}
            videoId={videoId}
            onClose={() => setOpen(false)}
          />
        </div>
        <DarkFooter />
      </div>
    </>
  );
}

export default Advts;
