import React, { useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import ManagerNavbar from "../../../components/Navbars/ManagerNavbar";
import Aside from "../../../components/Navbars/Aside";

const PressDetail = (props) => {
  const history = useHistory();
  const [pressDetailData, setPressDetailData] = React.useState([]);
  const [date, setDate] = React.useState("");

  var pressDetailpathname = props.location.pathname;
  var substrPathName = pressDetailpathname.substr(21);

  useEffect(() => {
    fetchData();
    setDate(moment(pressDetailData.date_create).format("YYYY.MM.DD"));
  }, [fetchData, pressDetailData.date_create]);

  const fetchData = () => {
    props.location.state.map((detail, index) => {
      if (detail.id === substrPathName) {
        setPressDetailData(detail);
      }
    });
  };

  const backBtn = () => {
    history.goBack(1);
  };

  return (
    <>
      <ManagerNavbar />
      <div style={{ display: "flex" }}>
        <Aside></Aside>

        <div className="main">
          <div>
            <span
              onClick={backBtn}
              style={{
                position: "relative",
                top: "120px",
                paddingLeft: "250px",
              }}
            >
              뒤로가기
            </span>
          </div>

          <div
            className="culture_section privacy_secion advts_detail_section"
            style={{ paddingBottom: 0 }}
          >
            <p className="privacy_title service_title">{"언론보도"}</p>
            <div
              className="advts_detail announce_detail_part press_detail"
              style={{ textAlign: "center" }}
            >
              <p className="help_title">{pressDetailData.title}</p>
              <p className="announce_detail_date">{date}</p>
            </div>
            <div
              className="advts_detail announce_detail_part parse"
              style={{ textAlign: "center", borderTop: 0 }}
            >
              {ReactHtmlParser(pressDetailData.contents)}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PressDetail;
