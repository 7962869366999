import React, { useEffect } from "react";
import { service_get, service_total } from "../../api";

import ServiceTable from "./Sections/ServiceTable";
import ManagerNavbar from "../../components/Navbars/ManagerNavbar";
import Aside from "../../components/Navbars/Aside";
import { useHistory } from "react-router-dom";

const AdminService = () => {
  const history = useHistory();
  const [serviceList, setServiceList] = React.useState([]);
  const [serviceTotal, setServiceTotal] = React.useState("1");
  useEffect(() => {
    let islogin = JSON.parse(localStorage.getItem("name"));
    if (islogin !== null) {
      console.log("Y");
    } else {
      history.push("/manage");
    }
    fetchData();
    fetchTotal();
  }, [history]);

  const fetchData = async () => {
    const response = await service_get(1, 15);
    if (response.result === "200") {
      setServiceList(response.data);
    }
  };

  const fetchTotal = async () => {
    const response = await service_total();
    if (response.result === "200") {
      if (response.data.length > 0) {
        setServiceTotal(Math.ceil(response.data.length / 15));
      }
    }
  };

  return (
    <>
      <ManagerNavbar />
      <div style={{ display: "flex" }}>
        <Aside></Aside>
        <ServiceTable data={serviceList} total={serviceTotal} />
      </div>
    </>
  );
};

export default AdminService;
