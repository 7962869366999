let _headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
};

function createCall(path, data = null, headers = {}, method = 'POST') {
    const merged = {
        ..._headers,
        ...headers
    };

    let body = {};
    if (data) {
        body = data;
    }
    // path = "http://127.0.0.1:5000/" + path;
    path = "https://livecare.io:441" + path;
    // path = "http://www.livecare.io" + path;

    let strData = JSON.stringify(body);
    if(method === 'POST')
        return fetch(
            path, {
                method : method,
                headers: merged,
                body: strData,
            },
        ).then((resp) => resp.json())
        
    else if(method === 'GET')
        return fetch(
            path
        ).then((resp) => resp.json())
    
}

export async function certificate_get(type, pageIndex, pageSize){
    return createCall(
        '/certificate?type='+type+'&pageIndex='+pageIndex+'&pageSize='+pageSize,
        null, null, 'GET'
    );
}

export async function certificate_total(type){
    return createCall(
        '/certificate/total?type='+type,
        null, null, 'GET'
    );
}



export async function stock(user_name, email, phone, stockCount, contactTime, contents){
    return createCall(
        '/stock',
        {user_name, email, phone, stockCount, contactTime, contents}, null
    );
}

export async function stock_get(pageIndex, pageSize){
    return createCall(
        '/stock?' + '&pageIndex='+pageIndex+'&pageSize='+pageSize,
        null, null, 'GET'
    );
}

export async function stock_total(){
    return createCall(
        '/stock/total',
        null,null,'GET'
    );
}

export async function service(type, service_name, user_name, country, email, phone, number_cow, kind_cow, addr, contents){
    return createCall(
        '/service',
        {type, service_name, user_name, country, email, phone, number_cow, kind_cow, addr, contents}, null
    );
}

export async function service_get(pageIndex, pageSize){
    return createCall(
        '/service?' + '&pageIndex='+pageIndex+'&pageSize='+pageSize,
        null, null, 'GET'
    );
}

export async function service_total(){
    return createCall(
        '/service/total',
        null,null,'GET'
    );
}

export async function contact(user_name, email, company_name, category, country, phone, addr, contents){
    return createCall(
        '/contact',
        {user_name, email, company_name, category, country, phone, addr, contents}, null
    );
}

export async function contact_get(pageIndex, pageSize){
    return createCall(
        '/contact?' + '&pageIndex='+pageIndex+'&pageSize='+pageSize,
        null, null, 'GET'
    );
}

export async function contact_total(){
    return createCall(
        '/contact/total',
        null,null,'GET'
    )
}

export async function announce_get(){
    return createCall(
        '/announce',
        null, null, 'GET'
    );
}

export async function press_get(language, pageIndex, pageSize){
    return createCall(
        '/press?language=' + language + '&pageIndex=' +pageIndex +'&pageSize=' +pageSize,
        null, null, 'GET'
    )
}

export async function press_detail(){
    return createCall(
        '/press/total',
        null, null, 'GET'
    );
}