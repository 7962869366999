import React, { useEffect } from "react";
import OtherNavbar from "components/Navbars/OtherNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import Path from "components/Path.js";
import { useTranslation } from 'react-i18next'
import { NavItem, NavLink, Nav, TabContent, TabPane} from 'reactstrap';
import PatentTable from "components/PatentTable.js";
import { certificate_get, certificate_total } from "../api";
function Patent() {
  const { t } = useTranslation()

  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  const [pills, setPills] = React.useState("1");
  const [patentPList, setPatentPList] = React.useState([]);
  const [patentCList, setPatentCList] = React.useState([]);
  const [patentIList, setPatentIList] = React.useState([]);
  const [patentLList, setPatentLList] = React.useState([]);
  const [patentPTotal, setPatentPTotal] = React.useState("1");
  const [patentCTotal, setPatentCTotal] = React.useState("1");
  const [patentITotal, setPatentITotal] = React.useState("1");
  const [patentLTotal, setPatentLTotal] = React.useState("1");
  useEffect(() => {
    
    fetchData('P');
    fetchTotal('P');
    fetchData('C');
    fetchTotal('C');
    fetchData('I');
    fetchTotal('I');
    fetchData('L');
    fetchTotal('L');

  },[])
  
  const fetchData = async (type) => {
    const response = await certificate_get(type, 1, 15);
    if(response.result === "200") {
      if(response.data.length > 0) {
        let temp = [];
        response.data.map((detail) => {
          if(detail.type.replace(/\s/g, '') === type) {
            temp.push(detail)
          }
        })
        if(type === 'P')
          setPatentPList(temp)
        else if(type === 'C')
          setPatentCList(temp)
        else if(type === 'I')
          setPatentIList(temp)
        else if(type === 'L')
          setPatentLList(temp)
      }
    }
  }

  const fetchTotal = async (type) => {
    const response = await certificate_total(type);
    if(response.result === "200") {
      if(response.data.length > 0) {
        if(type === 'P')
          setPatentPTotal(Math.ceil(response.data.length/15))
        else if(type === 'C')
          setPatentCTotal(Math.ceil(response.data.length/15))
        else if(type === 'I')
          setPatentITotal(Math.ceil(response.data.length/15))
        else if(type === 'L')
          setPatentLTotal(Math.ceil(response.data.length/15))
      }
    }
  }

  return (
    <>
      <OtherNavbar />
      <div className="wrapper">
        <div className="main">
          <Path firstPathTitle={t('홈')} firstPath="/" secondPathTitle={t('유라이크코리아')} secondPath="/about" thirdPathTitle={t('연구/특허')}/>
          <div className="culture_section culture_work" style={{paddingBottom: 0}}>
            <p className="news_title patent_title" style={{fontFamily: 'NotoSansCJKkr-Bold'}}>{t('연구/특허')}</p>
          </div>
          <div className="culture_section patent_table_section">
            <Nav
              className="nav-tabs-neutral justify-content-center patent_tab"
              data-background-color="blue"
              role="tablist"
              tabs
            >
              <NavItem>
                <NavLink className={pills === "1" ? "active" : ""} href="#pablo" onClick={(e) => { e.preventDefault(); setPills("1"); }} >
                  {t('특허')}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className={pills === "2" ? "active" : ""} href="#pablo" onClick={(e) => { e.preventDefault(); setPills("2"); }} >
                  {t('논문')}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className={pills === "3" ? "active" : ""} href="#pablo" onClick={(e) => { e.preventDefault(); setPills("3"); }} >
                  {t('인증')}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className={pills === "4" ? "active" : ""} href="#pablo" onClick={(e) => { e.preventDefault(); setPills("4"); }} >
                  {t('상표')}
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent
              className="text-center patent_table"
              activeTab={"pills" + pills}
            >
              <TabPane tabId="pills1">
                {
                  patentPList.length > 0 ?
                  <PatentTable cate1={t("특허명")} cate2={t("국가")} cate3={t("번호")} cate4={t("일자")} data={patentPList} total={patentPTotal} type="P" />
                  :
                  null
                }
                
              </TabPane>
              <TabPane tabId="pills2">
                {
                  patentCList.length > 0 ?
                  <PatentTable cate1={t("눈문명")} cate2={t("기고지")} cate3={t("연도")} data={patentCList} total={patentCTotal} type="C" />
                  :
                  null
                }
              </TabPane>
              <TabPane tabId="pills3">
                {
                  patentIList.length > 0 ?
                  <PatentTable cate1={t("인증명")} cate2={t("인증기관")} cate3={t("번호")} cate4={t("일자")} data={patentIList} total={patentITotal}  type="I"/>
                  :
                  null
                }
              </TabPane>
              <TabPane tabId="pills4">
                {
                  patentLList.length > 0 ?
                  <PatentTable cate1={t("상표명")} cate2={t("국가")} cate3={t("번호")} cate4={t("일자")} data={patentLList} total={patentLTotal} type="L" />
                  :
                  null
                }
                
              </TabPane>
            </TabContent>

            <TabContent
              className="text-center patent_table_mobile"
              activeTab={"pills" + pills}
            >
              <TabPane tabId="pills1">
                <PatentTable cate1={t("특허명")} cate2={t("국가")} cate3={t("번호")} cate4={t("일자")} data={patentPList} mobile={true} total={patentPTotal} type="P" />
              </TabPane>
              <TabPane tabId="pills2">
                <PatentTable cate1={t("눈문명")} cate2={t("기고지")} cate3={t("연도")} data={patentCList} mobile={true} total={patentCTotal} type="C" />
              </TabPane>
              <TabPane tabId="pills3">
                <PatentTable cate1={t("인증명")} cate2={t("인증기관")} cate3={t("번호")} cate4={t("일자")} data={patentIList} mobile={true} total={patentITotal} type="I" />
              </TabPane>
              <TabPane tabId="pills4">
                <PatentTable cate1={t("상표명")} cate2={t("국가")} cate3={t("번호")} cate4={t("일자")} data={patentLList} mobile={true} total={patentLTotal} type="L" />
              </TabPane>
            </TabContent>
            
          </div>
        </div>
        <DarkFooter />
      </div>
    </>
  );
}

export default Patent;