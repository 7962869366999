import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "reactstrap";
import Animal from "../components/Animal";
import MainPartner from "../components/MainPartner";
import NewsCard from "../components/NewsCard";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { press_detail } from "../api";
import detectBrowserLanguage from "detect-browser-language";
import ManagerNavbar from "../components/Navbars/ManagerNavbar";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import OtherNavbar from "components/Navbars/OtherNavbar";
import Aside from "../components/Navbars/Aside";
import Cow from "../assets/img/cow.jpg";



function Home() {
  const history = useHistory();

  useEffect(() => {
    let islogin = JSON.parse(localStorage.getItem("name"));
    if (islogin !== null) {
    } else {
      history.push("/manage");
    }
  }, [history]);

  return (
    <>
      <ManagerNavbar />
      <div style={{ display: "flex" }}>
        <Aside />
        <div style={{ width: "100%" }}>
          <img style={{ width: "100%", height: "100vh" }} src={Cow} />
        </div>

        <div></div>
      </div>
    </>
  );
}

export default Home;
