import React,{useEffect, useState} from 'react';
import OtherNavbar from "components/Navbars/OtherNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import lifeRobot from 'assets/img/lifeRobot.png';
import lifestyle from 'assets/img/lifestyle.png';
import zlife from 'assets/img/zlife.png';
import winkRobot from 'assets/img/winkRobot.png';
import miniRobot from 'assets/img/miniRobot.png';
import AstronRobot from 'assets/img/AstronRobot.png';
import jmrGroup from 'assets/img/jmrGroup.png'; 
import lifeRobot650 from 'assets/img/lifeRobot650.png'; 
import zlife650 from 'assets/img/zlife650.png';
import lifeStyle650 from 'assets/img/lifeStyle650.png'; 
import { useTranslation } from 'react-i18next'

function RobotPage() {
    const { t } = useTranslation()

    console.log('props', );
    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
          document.body.classList.remove("index-page");
          document.body.classList.remove("sidebar-collapse");
        };
      });
    return(
        <>
      <OtherNavbar />
      <div className="wrapper">
       
        <div>
            <div className="robotBackgroundImgDiv">
                <div className="robotbackgRoundTextBox">
                    <div className="robotText">
                        <div className="AIRobotcsText">{t('AI Robotics')}</div>
                        <div className="whiteTextDiv">
                            <div className="whiteText">{t('4차 산업혁명의 핵심')}</div>
                            <div className="whiteText">{t('코로나 19로 앞당겨진 비대면 시대의 첨병')}</div>
                        </div>
                     
                    </div>
                </div>
            </div>
        </div>
      
        <div className="robotSlideBackground">
   
        </div>



        <div className='robotExplainBox'>
            <div className='robotExplainTextBox'>
                <img src={lifeRobot} className='webLiftRobot' alt='life'/>
                <img src={lifeRobot650} className='mobileLifeRobot' alt='life'/>
            </div>
            <div className='robotExplainTextBox'>
                <div>
                    <img src={winkRobot} alt='' className='ExplainRobotImg' style={{width:'526px',marginTop:'66px'}}/>
                </div>
                <div className='ExplainText'>
                    <div className='robotName'>Cruzr</div>
                    <div className='companyCategory'>{t('Machine Learning')}</div>
                    <div className='grayText'>{t('스스로 학습하고')}</div>
                    <div className='grayText'>{t('생산성 향상에 대응하는')}</div>
                    <div className='blackText'>{t('AI 로보틱스 기술')}</div>
                </div>
            </div>
        </div>
        
        <div className='robotExplainBox reverserobotExplainBox'>
            <div className='robotExplainTextBox'>
                <div>
                    <img src={miniRobot} alt='' className='ExplainRobotImg' style={{width:'470px', marginTop:'114px'}}/>
                </div>
                <div className='ExplainText'>
                    <div className='robotName'>Alpha mini</div>
                        <div className='companyCategory'>{t('Tech of Harmony')}</div>
                        <div className='grayText humanRobot'>{t('인간과 로봇의 공존과 협동작업,')}</div>
                        <div className='blackText'>{t('안전과 생산성의 조화')}</div>
                       
                    </div>
                </div>
            <div className='robotExplainTextBox'>
                <img src={lifestyle} className='webLiftRobot' alt='life'/>
                <img src={zlife650} className='mobileLifeRobot' alt='life'/>

            </div>
        </div>
        
       
        <div className='robotExplainBox'>
            <div className='robotExplainTextBox'>
                <img src={zlife}  className='webLiftRobot' alt='life' />
                <img src={lifeStyle650} className='mobileLifeRobot' alt='life'/>

            </div>
            <div className='robotExplainTextBox'>
                <div>
                    <img src={AstronRobot} alt='' className='ExplainRobotImg' style={{width:'502px',marginTop:'93px'}}/>
                </div>
                <div className='ExplainText'>
                <div className='robotName'>Astron</div>
                    <div className='companyCategory'>{t('User Oriented Robotics')}</div>
                    <span className='grayText robotBoldLine'>{t('사용자 중심 로보틱스 서비스와')}<strong className='boldService'>{t('어플리케이션 디자인 연구')} </strong></span>
                    <div className='blackText resBlackText'>{t('어플리케이션 디자인 연구')}</div>
                </div>
            </div>
      
        </div>

        <div>
            <div className='ulkJmrLogo'>
                <div className='ulkJmrText'>{t('uLIkeKorea의 AI 기술과')}</div>
                <div className='ulkJmrText'>{t('JM Robotics의 노하우가 융합되어 만들어가는')}</div>
                <div className='ulkJmrText'>{t('AI Robotics 세상')}</div>
                <img src={jmrGroup} alt='' style={{width:'398px', marginTop:'32px'}}/>
            </div>
        </div>

      
      
        <DarkFooter />
        </div>
    

        </>
    )
}

export default RobotPage