
import React from "react";
import moment from 'moment';
import detectBrowserLanguage from "detect-browser-language";
function openLink(url) {
  if (!url.match(/^https?:\/\//i)) {
      url = 'http://' + url;
  }
  return url;
}
function Process(props) {
  let link = props.data.link;
  if(props.thesis) {
      link = openLink(props.data.link)
  }
  let name = props.data.name_en
  let category = props.data.category_en
  let date_certificate = props.data.date_certificate_en
  if(props.curLang === 'ko') {
      name = props.data.name_kr
      category = props.data.category_kr
      date_certificate = props.data.date_certificate_kr
  } else if(props.curLang === 'pt') {
      name = props.data.name_pt
      category = props.data.category_pt
      date_certificate = props.data.date_certificate_pt
  } else if(props.curLang === 'ja') {
      name = props.data.name_jp
      category = props.data.category_jp
      date_certificate = props.data.date_certificate_jp
  }

  return (
    <div className="patent_table_mobile_section" onClick={(e) => {
      props.thesis ? window.open(link, '_blank') : e.preventDefault();  
    }}>
      <div>
          <p className="patent_table_country">{category}</p>
      </div>
      <div className="patent_table_content">
          <p className="patent_table_desc">{name}</p>
          <p className="patent_table_date">{moment(props.data.date_create.slice(0, -3)).format("YYYY.MM.DD")}</p>
      </div>
    </div>
  );
}

export default Process;
