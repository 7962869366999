import React from "react";
import OtherNavbar from "components/Navbars/OtherNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import Path from "components/Path.js";
import { useTranslation } from 'react-i18next'

import { Row, Col } from "reactstrap";
import GoogleMapReact from 'google-map-react';
import SubcompanyMap from '../components/SubcompanyMap.js';
import { GoogleMapKey } from '../Global.js';
function Directions() {
    const { t } = useTranslation()
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <OtherNavbar />
      <div className="wrapper">
        <div className="main">
          <Path firstPathTitle={t('홈')} firstPath="/" secondPathTitle={t('유라이크코리아')} secondPath="/about" thirdPathTitle={t('오시는 길')}/>
          <div className="culture_section culture_work direction_title">
              <p className="subtitle">{t('오시는 길')}</p>
          </div>
          
          <div className="culture_section culture_work direction_section">
            <p className="subtitle">{t('국내 법인')}</p>
            <div className="map_section">
                <div className="main_map">
                    <GoogleMapReact bootstrapURLKeys={{ key: GoogleMapKey}}
                        defaultCenter={{lat: 37.483676,lng: 127.117394}}
                        defaultZoom={13}
                    >
                    </GoogleMapReact>
                </div>
                <div className="main_map_detail">
                    <div className="map_detail_section">
                        <img alt="" src={require("assets/img/solid--blue.svg")} />
                    </div>
                    <p className="map_detail_section main_company_title">{t('주식회사 유라이크코리아')}</p>
                    <div className="map_detail_section">
                        <i className="fa fa-map-marker-alt"></i>
                        {t('주소 서울 송파구 법원로 9길 26 H비즈니스파크 D동 12층')}
                    </div>
                    <div className="map_detail_section">
                        <i className="fa fa-phone"></i>
                        (02) 2043-5300
                    </div>
                    <div className="map_detail_section">
                        <i className="fa fa-envelope"></i>
                        min@ulikekorea.com
                    </div>
                </div>
            </div>
            <div>
                <Row className="submap_row">
                    <Col md="6">
                        <SubcompanyMap lat={37.631713} lng={127.116452} title={t('경기 총판')} subtitle={t('(주)라이브엠')} addr={t('주소 경기도 구리시 산마루로 18, 하나로프라자 2층')} phone={'(031) 571-9304'} email={'hjkim@livem.kr'}/>
                    </Col>
                    <Col md="6">
                        <SubcompanyMap lat={36.681831} lng={126.830579} title={t('충청 총판')} subtitle={t('(주)하이팜케어')} addr={t('주소 충청남도 예산군 예산읍 예산로 42')} phone={'(041) 331-2873'} email={'hwa7027@hanmail.net'}/>
                    </Col>
                </Row>
                <Row className="submap_row">
                    <Col md="6">
                        <SubcompanyMap lat={35.891326} lng={128.563217} title={t('영남 총판')} subtitle={t('(주)라이브텍')} addr={t('주소 대구광역시 북구 팔달북로3길 6')} phone={'(031) 571-9304'} email={'cjo@livetec.co.kr'}/>
                    </Col>
                    <Col md="6">
                        <SubcompanyMap lat={35.139329} lng={126.799249} title={t('호남 총판')} subtitle={t('(주)마크젠')} addr={t('주소 광주광역시 광산구 송정1동 567-35 송정농협 2층')} phone={'(070) 4617-3962'} email={'yannbeom@naver.com'}/>
                    </Col>
                </Row>
            </div>
            <div>
                <p className="subtitle foreign_title">{t('해외 법인')}</p>
                <div>
                    <Row className="submap_row">
                        <Col md="6">
                            <SubcompanyMap lat={37.792235} lng={-122.398363} title={t('미국 법인')} subtitle={t('Livestock Technology Services, Inc.')} addr={t('주소 Livestock Technology Services, Inc. 388 Market Street #1300, San Francisco, CA, 94111')} email={'steve@ulikekorea.com'}/>
                        </Col>
                        <Col md="6">
                            <SubcompanyMap lat={35.549170} lng={139.783718} title={t('일본 총판')} subtitle={t('株式会社The Better Co., Ltd.')} addr={t('321-544 underground 1st Terminal 3-3-2 Haneda Airport Ota-ku, Tokyo 144-0041, Japan')} email={'n.yabuuchi@livecare.co.jp'}/>
                        </Col>
                    </Row>
                    <Row className="submap_row">
                        <Col md="6">
                            <SubcompanyMap lat={54.772435} lng={11.865028} title={t('유럽 법인')} subtitle={t('LIVECARE EUROPE, LLC.')} addr={t('Slotsbryggen 14, Nykøbing Falster, 4800, Denmark')} email={'steve@ulikekorea.com'}/>
                        </Col>
                        <Col md="6">
                            <SubcompanyMap lat={1.275706} lng={103.845761} title={t('싱가폴 법인')} subtitle={t('LIVECARE HOLDINGS PTE. LTD.')} addr={t('10 Anson road #34-10 International Plaza, Singapore, 079903')} email={'steve@ulikekorea.com'}/>
                        </Col>
                    </Row>
                </div>
            </div>
          </div>
        </div>
        <DarkFooter />
      </div>
    </>
  );
}

export default Directions;