import React, { useEffect } from "react";
import ManagerNavbar from "../../../components/Navbars/ManagerNavbar";
import Aside from "../../../components/Navbars/Aside";
import { Pagination, PaginationItem, PaginationLink, Table } from "reactstrap";
import { useHistory } from "react-router-dom";

const ServiceDetail = (props) => {
  const [detailData, setDetailData] = React.useState([]);

  var a = props.location.pathname;
  var b = a.substr(23);

  const history = useHistory();
  useEffect(() => {
    let islogin = JSON.parse(localStorage.getItem("name"));
    if (islogin !== null) {
      console.log("Y");
    } else {
      history.push("/manage");
    }
    fetchData();
  }, [fetchData, history]);

  const backBtn = () => {
    history.goBack(1);
  };
  const fetchData = () => {
    props.location.state.map((detail, index) => {
      if (detail.id === b) {
        setDetailData(detail);
      }
    });
  };

  if (detailData.type === "E") {
    var type = "기존 사용자";
  } else if (detailData.type === "O") {
    var type = "잠재 사용자";
  } else {
    var type = "기타";
  }

  // 서비스명
  if (detailData.service_name === "L") {
    var service_name = "라이브케어";
  } else if (detailData.service_name === "P") {
    var service_name = "캡슐 구매";
  } 
  else {
    var service_name = "기타";
  }

  if (detailData.kind_cow === "MK") {
    var kind_cow = "젖소";
  } else if (detailData.kind_cow === "BR") {
    var kind_cow = "번식우";
  } else {
    var kind_cow = "비육우";
  }

  return (
    <>
      <ManagerNavbar />
      <div style={{ display: "flex" }}>
        <Aside></Aside>
        <div
          style={{
            backgroundColor: "#EEF1F3",
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            minWidth: "600px",
          }}
        >
          <div
            style={{
              width: "80%",
              margin: "0 auto",
              position: "absolute",
              top: "150px",
              overflowY: "scroll",
              height: "80vh",
            }}
          >
            <div>
              <span onClick={backBtn} style={{ position: "relative" }}>
                뒤로가기
              </span>
            </div>

            <span className="servieSubTitle">등록된 서비스문의</span>
            <Table className="manager_table">
              <tbody>
                <tr>
                  <td className="serviceDetailTd">문의일</td>
                  <td>{detailData.date_create}</td>
                </tr>
                <tr>
                  <td className="serviceDetailTd">문의종류</td>
                  <td>{type}</td>
                </tr>
                <tr>
                  <td className="serviceDetailTd">서비스명</td>
                  <td>{service_name}</td>
                </tr>
                <tr>
                  <td className="serviceDetailTd">이름</td>
                  <td>{detailData.user_name}</td>
                </tr>
                <tr>
                  <td className="serviceDetailTd">국가</td>
                  <td>{detailData.country}</td>
                </tr>
                <tr>
                  <td className="serviceDetailTd">이메일 주소</td>
                  <td>{detailData.email}</td>
                </tr>
                <tr>
                  <td className="serviceDetailTd">전화번호</td>
                  <td>{detailData.phone}</td>
                </tr>
                <tr>
                  <td className="serviceDetailTd">보유 두수</td>
                  <td>{detailData.phone}</td>
                </tr>
                <tr>
                  <td className="serviceDetailTd">품종</td>
                  <td>{kind_cow}</td>
                </tr>
                <tr>
                  <td className="serviceDetailTd">주소</td>
                  <td>{detailData.addr}</td>
                </tr>
                <tr className="contentTd" style={{ padding: "0px" }}>
                  <td className="contentTd serviceDetailTd">문의내용</td>
                  <td className="contentTd">{detailData.contents}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceDetail;
