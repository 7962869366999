import React, { useEffect } from "react";
import { Table, Pagination, PaginationItem, PaginationLink,} from 'reactstrap';
import PatentTableMobileContent from "./PatentTableMobileContent.js";
import moment from 'moment';
import { certificate_get } from "../api";
import detectBrowserLanguage from "detect-browser-language";
import { useTranslation } from 'react-i18next'
function openLink(url) {
    if (!url.match(/^https?:\/\//i)) {
        url = 'http://' + url;
    }
    return url;
}
function TableContent(props) {
    return props.data.map((detail, index) => {
        let link = detail.link;
        if(props.thesis) {
            link = openLink(detail.link)
        }
        let name = detail.name_en
        let category = detail.category_en
        let date_certificate = detail.date_certificate_en

        if(props.curLang === 'ko') {
            name = detail.name_kr
            category = detail.category_kr
            date_certificate = detail.date_certificate_kr
        } else if(props.curLang === 'pt') {
            name = detail.name_pt
            category = detail.category_pt
            date_certificate = detail.date_certificate_pt
        } else if(props.curLang === 'ja') {
            name = detail.name_jp
            category = detail.category_jp
            date_certificate = detail.date_certificate_jp
        }
        return <tr key={index} onClick={(e) => {
            props.thesis ? window.open(link, '_blank') : e.preventDefault();
            
        }}>
            <td>{name}</td>
            <td>{category}</td>
            <td>{date_certificate}</td>
            {
                props.cate4 ?
                <td>{moment(detail.date_create.slice(0, -3)).format("YYYY.MM.DD")}</td>
                :
                null
            }
        </tr>
    })
    
}
function PatentTable(props) {
    const [curPage, setCurPage] = React.useState(1)
    const [list, setList] = React.useState(props.data)
    const { t, i18n } = useTranslation()
    const [curLang , setCurLang] = React.useState(i18n.language.substring(0,2) === 'ko' || !i18n.language.substring(0,2) && detectBrowserLanguage().substring(0,2) === 'ko' ? "ko" : i18n.language.substring(0,2) === 'ja' || !i18n.language && detectBrowserLanguage().substring(0,2) === 'ja' ? 'ja' : i18n.language.substring(0,2) === 'pt' || !i18n.language && detectBrowserLanguage().substring(0,2) === 'pt' ? 'pt' : 'en');

    useEffect(() => {
        setCurLang(i18n.language === 'ko' ? 'ko' : i18n.language === 'ja' ? 'ja' : i18n.language === 'pt' ? 'pt' : 'en')
        setList(props.data)
    },[props]);
    const callData = async (page) => {
        if(curPage !== page) {
            setCurPage(page)
            const response = await certificate_get(props.type, page, 15);
            if(response.result === "200" && response.data.length > 0) {
                setList(response.data)
            }
        }
    }
    
  return (
      
      <div>
          {
              props.mobile ?
                <div>
                    {
                        list && list.length > 0 ?
                        list.map((detail, index) => {
                            return <PatentTableMobileContent key={index} data={detail} cur_page={curPage} thesis={props.cate4 ? false : true} curLang={curLang} />
                        })
                        :
                        null
                    }
                </div>
              :
                <Table className="annoucement_table">
                    <thead>
                        <tr>
                            <th>{props.cate1}</th>
                            <th>{props.cate2}</th>
                            <th>{props.cate3}</th>
                            {
                                props.cate4 ?
                                <th>{props.cate4}</th>
                                :
                                null
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            list && list.length > 0 ?
                            <TableContent data={list} cate4={props.cate4 ? true : false} cur_page={curPage} thesis={props.cate4 ? false : true} curLang={curLang} />
                            :
                            null       
                        }
                    </tbody>
                </Table>
          }
            
            <div style={{textAlign: 'center'}}>
            <Pagination>
                <PaginationItem>
                    <PaginationLink
                        aria-label="First"
                        href="#pablo"
                        onClick={(e) =>  { e.preventDefault(); callData(1) }}
                    >
                        <span aria-hidden={true}>
                        <i
                            aria-hidden={true}
                            className="fa fa-angle-double-left"
                        ></i>
                        </span>
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink
                        aria-label="Previous"
                        href="#pablo"
                        onClick={(e) => { e.preventDefault(); callData(curPage > 1 ? curPage - 1 : 1) }}
                    >
                        <span aria-hidden={true}>
                        <i
                            aria-hidden={true}
                            className="fa fa-angle-left"
                        ></i>
                        </span>
                    </PaginationLink>
                </PaginationItem>
                <span className="curPage">{curPage}</span>
                <span>/</span>
                <span className="lastPage">{props.total}</span>
                <PaginationItem>
                    <PaginationLink
                        aria-label="Next"
                        href="#pablo"
                        onClick={(e) => { e.preventDefault(); callData(curPage < props.total ? curPage + 1 : props.total) }}
                    >
                        <span aria-hidden={true}>
                        <i
                            aria-hidden={true}
                            className="fa fa-angle-right"
                        ></i>
                        </span>
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink
                        aria-label="Last"
                        href="#pablo"
                        onClick={(e) => { e.preventDefault(); callData(props.total) }}
                    >
                        <span aria-hidden={true}>
                        <i
                            aria-hidden={true}
                            className="fa fa-angle-double-right"
                        ></i>
                        </span>
                    </PaginationLink>
                </PaginationItem>
            </Pagination>
            </div>
    </div>
  );
}

export default PatentTable;
